import React, { Component } from 'react'
import { isAndroid } from 'react-device-detect'
import DocumentMeta from 'react-document-meta'
import LocalDevIcon from './LocalDevIcon'
import Socialmedia from './SocialMedia'
import Header from './Header'

import cover_p from './png/cda1024e2.png'
import cover_r from './png/cda1024r.png'
import cover_g from './png/cda1024g.png'
import cover_s from './png/cda1024s2.png'
import cover_gg from './png/cda1024gg.png'

import ReactHtmlParser from 'react-html-parser'

var QRCode = require('qrcode.react')

//import cover_red from './png/cda1024r.png'
//import cover_post_pr from './png/cda1024r.png' // nach einlösen der vorregistrierung

//zustand 1 : normal grün mit weißen mahken
// 2. : erfolgreiche prereg (slebe wie zuvor)
// 3. : erfolgreiche einlösen des prereg (schalter für diesen mode suchen im code)
// 4. : if (wenn id frage01-frage10 existiert) für iconr, nur ausgeführt bei code mit geusndheitsfragen


class SetupSuccess extends Component {

    getSetupSuccessButtons(type) {
        let { app } = this.props
        let user = encodeURI(window.getURLParameter('user'))
        
        let { CDA } = type

        let setupSuccess = {
            nextBtnFunc: () => console.log('getSetupSuccessScreen unset'),
            nextBtnText: "unset",
            setupFotoFunc: () => console.log('getSetupSuccessScreen unset'),
            setupFotoText: "unset"
        }

        if (isAndroid)
            setupSuccess = {
                nextBtnFunc: () => { if (typeof window.HTMLOUT !== 'undefined') window.HTMLOUT.callAndroidFunction("objektliste"); else window.location.href = 'https://my-qr.io/' + app.pid },
                nextBtnText: "Überspringen",
                setupFotoFunc: () => { if (typeof window.HTMLOUT !== 'undefined') window.HTMLOUT.openCamera(app.permid.CHILD) },
                setupFotoText: "Jetzt Foto aufnehmen"
            }

        if (window.mag_webkit)
            setupSuccess = {
                nextBtnFunc: CDA ? () => window.WebViewCloseCDA() : () => window.WebViewClose(),
                nextBtnText: CDA ? "Weiter":"Überspringen",
                setupFotoFunc: () => { app.getSetupImage(app.permid.CHILD, 'wdimage') },
                setupFotoText: "Jetzt Foto aufnehmen"
            }


        if (!isAndroid && !window.mag_webkit)
            setupSuccess = {
                nextBtnFunc: () => window.location.href = 'https://web.whatsdown.com/?user=' + user + "&idtoken=" + app.idtoken,
                nextBtnText: "Weiter"

                /* 
                //!isAndroid: klicked Foto aufnehmen ->  this.getSetupImage( this.permid.CHILD , "wdimage" ) '
                this.getSetupImage(this.permid.CHILD, 'wdimage')   //TODO <--> this.permid.CHILD
                 */
            }

        return setupSuccess /* (
            <div>
                <div className='ueber_container'><div id='ueberspringen' service={app.type.SERVICE} className='button'  onClick={setupSuccess.nextBtnFunc} >{setupSuccess.nextBtnText}</div></div>
                {
                    CDA ? 
                    ''
                    :
                    <div id='setupFoto' onClick={setupSuccess.setupFotoFunc} >Jetzt Foto aufnehmen</div>
                }
                <br />
                <br />
            </div>
        ) */
    }


    create_rufuse_text_html(){
        let refuse = window.getURLParameter('refuse')
        if( refuse === 'false' ){
            return null
        }

        let { app } = this.props

        let refuse_text = app.replace_percent( app.refuse_text )
        let refuse_html = [<span>Es gibt ein Problem  - bitte wenden Sie sich unbedingt an das Personal. (<b >{app.displayTitle}</b>)</span>]

        if( refuse_text ){  
            refuse_html = [<div key='0' dangerouslySetInnerHTML={{ __html: refuse_text }}></div>]
        }
        
        let reasons = window.localStorage.getItem('reasons')
        if( reasons && reasons !== 'undefined' ){
            reasons = JSON.parse( reasons )

            let count = 0
            for (const key in reasons) {
                if (Object.hasOwnProperty.call(reasons, key)) {
                    const reason = reasons[key]

                    if( count++ === 2 ){
                        refuse_html.push(<div key={1}><b>Desweiteren:</b><br/><br/></div>)

                    }
                    let text = app.replace_percent( reason.text  )
                    refuse_html.push(<div key={key} dangerouslySetInnerHTML={{ __html: text }}></div>)
                }
            }
        }

        if( app.refuse_align ){
            this.align = 'left'
        }else{
            this.align = 'center'
        }

        return refuse_html
    }


    create_accept_text_html(){
        let refuse = window.getURLParameter('refuse')
        if( refuse !== 'false' ){
            return null
        }

        let { app } = this.props

        let accept_html = null

        let passed = window.localStorage.getItem('passed')
        if( passed && passed !== 'undefined' ){
            passed = JSON.parse( passed )
            accept_html = []

            let count = 0
            for (const key in passed) {
                if (Object.hasOwnProperty.call(passed, key)) {
                    const pass = passed[key]

                    if( count++ === 2 ){
                        accept_html.push(<div key={1}><b>Desweiteren:</b><br/><br/></div>)

                    }
                    let text = app.replace_percent( pass.text )
                    accept_html.push(<div key={key} dangerouslySetInnerHTML={{ __html: text }}></div>)
                }
            }
        }

        if( app.refuse_align ){
            this.align = app.refuse_align 
        }else{
            this.align = 'center'
        }

        return accept_html
    }


    success_text_options( type ){
        let { app } = this.props
        let urls = this.urls

        let refuse_html = this.create_rufuse_text_html()
        let accept_html = this.create_accept_text_html()

        if( type === 'cdap'){
            let text_g = accept_html || <p>Vielen Dank! - Ihr Eintrag wurde in der Corona-Anwesenheitsliste <b >{app.displayTitle}</b> gespeichert.<br/><br/>Um sich abzumelden drücken Sie einfach auf Weiter oder scannen den QR-Code erneut.<br/><br/>Ihnen gefällt corona-presence?<br/>Sie möchten es gerne für Ihren Betrieb/Ihre Veranstaltung einsetzen?<br/>Ganz einfach: <a href="https://www.corona-presence.de?pk_campaign=uqcdadirect" target="_blank" rel="noopener noreferrer">Hier</a> können Sie sofort Ihre eigene Liste anlegen. <a href="https://www.corona-presence.de?pk_campaign=uqcdadirect" target="_blank" rel="noopener noreferrer">corona-presence</a> ist und bleibt dauerhaft kostenlos, das ist unser digitaler Beitrag gegen das Virus</p>
            let text_gg = <p>Sie haben Ihre Vorregistrierung für <b >{app.displayTitle}</b> eingelöst und wurden in die Anwesenheitsliste eingetragen. <br/><br/></p>
            let text_s = <p>Vielen Dank! - Sie sind jetzt für<br/> <b >{app.displayTitle}</b><br/>vorregistriert. Scannen Sie den QR-Code am Eingang der Veranstaltung um sich vor Ort anzumelden. <br /><br /><a href={'https://web.corona-presence.de/' + app.pid} style={{ textDecoration: 'underline' }}>Oder verwenden Sie diesen Link</a> <br />Hinweis: Bitte erst vor Ort verwenden!</p>
            let text_r = <div>{ refuse_html }<br/><br/></div>
            let text_p = <p>Die Person wurde in der Corona-Anwesenheitsliste <b >{app.displayTitle}</b> vorregistriert.<br/><br/><a href={urls.CDAP+"&show=preregistration"}>Weitere Person vorregistrieren</a></p>
            let text_d = <p>Die Person wurde in der Corona-Anwesenheitsliste <b >{app.displayTitle}</b> eingetragen.<br/><br/></p>

            return { text_g, text_gg, text_s, text_r, text_p, text_d }
        }

        if( type === 'dhc'){
            let text_g = accept_html || <p>Ihr Eintrag wurde in <b >{app.displayTitle}</b> gespeichert.<br/></p>
            let text_gg = <p>Sie haben Ihre Vorregistrierung für <b >{app.displayTitle}</b> eingelöst und wurden in die Anwesenheitsliste eingetragen. <br/><br/></p>
            let text_s = <p>Vielen Dank! - Sie sind jetzt für<br/> <b >{app.displayTitle}</b><br/>vorregistriert. Scannen Sie den QR-Code am Eingang der Veranstaltung um sich vor Ort anzumelden. <br /><br /><a href={'https://web.corona-presence.de/' + app.pid} style={{ textDecoration: 'underline' }}>Oder verwenden Sie diesen Link</a> <br />Hinweis: Bitte erst vor Ort verwenden!</p>
            let text_r = <div>{ refuse_html }<br/><br/></div>
            let text_p = <p>Die Person wurde in der RAPIDOK-Liste <b >{app.displayTitle}</b> vorregistriert.<br/><br/><a href={urls.DHC+"&show=preregistration"}>Weitere Person vorregistrieren</a></p>
            let text_d = <p>Die Person wurde in der RAPIDOK-Liste <b >{app.displayTitle}</b> eingetragen.<br/><br/></p>

            return { text_g, text_gg, text_s, text_r, text_p, text_d }
        }
    }


    get_success_mode(){
        let { app } = this.props

        let refuse = window.getURLParameter('refuse') === 'true'
        let usecheckliste = window.getURLParameter('usecheckliste')
        let parentid =  window.getURLParameter('parentid')
        let after_reg = app.getCookie('message_parent_id_' + app.pid).includes('IN:') //
        let fromMail = usecheckliste ? usecheckliste.match(/^_/) : false

        const success_mode = {
            SELF_REGISTRATION: app.mode.PREREG && !app.fromCookieIgnore,
            AGENT_REGISTRATION: app.mode.PREREG && app.fromCookieIgnore,
            AGENT_DIRECT_ADD: !app.mode.PREREG && app.fromCookieIgnore,
            /* absperren des vorregistrierungs-schirms bei DHC, da parentid in der url immer drin ist: !app.type.DHC */
            /* 151121, NN: absperrung der vorregistrierungs-anzeige für vinzent kh  */
            CONFIRM_REGIST_URL: !app.mode.PREREG && !refuse && ((parentid && !app.type.DHC && !app.type.CDAP ) || after_reg), //fromMail,
            NORMAL: !app.mode.PREREG && !refuse && !fromMail && !(parentid || after_reg),
            REFUSE: refuse // hatte noch ein !app.mode.PREREG warum? AH 2809
        }

        return success_mode
    }


    get_success_icon_and_text( type ){
        let { app } = this.props
        let { text_g, text_gg, text_s, text_r, text_p, text_d } = this.success_text_options( type )

        let icon = cover_g
        let text = text_g
        
        const success_mode = this.get_success_mode()
        const { 
            AGENT_REGISTRATION, 
            SELF_REGISTRATION, 
            CONFIRM_REGIST_URL,  
            AGENT_DIRECT_ADD, 
            NORMAL,
            REFUSE 
        } = success_mode

        if( AGENT_REGISTRATION ) icon = cover_p // person mit email
        if( SELF_REGISTRATION ) icon = cover_s // qr-code handy
        if( CONFIRM_REGIST_URL ) icon = cover_gg // doppelter haken
        if( NORMAL || AGENT_DIRECT_ADD ) icon = cover_g // einfacher haken
        if( REFUSE ) icon = cover_r // rot mit kreuz
        // if ( window.getURLParameter('icon') === 'preregconfirmed' ) icon = cover_gg // doppelter haken
        
        let jss = app.getRenderDataElement('setup_success') || {}
        if( AGENT_REGISTRATION ) text = this.pick_json_value_or_local_variable( jss.text_p, text_p )  // person mit email
        if( SELF_REGISTRATION ) text = this.pick_json_value_or_local_variable( jss.text_s, text_s )  // qr-code handy
        if( CONFIRM_REGIST_URL ) text = this.pick_json_value_or_local_variable( jss.text_gg, text_gg )  // doppelter haken
        if( AGENT_DIRECT_ADD ) text = this.pick_json_value_or_local_variable( jss.text_d, text_d )  // einfacher haken
        if( NORMAL ) text = this.pick_json_value_or_local_variable( jss.text_g, text_g )  // einfacher haken
        if( REFUSE ) text = this.pick_json_value_or_local_variable( jss.text_r, text_r )  // rot mit kreuz

        return { icon, text, success_mode }
    }


    get_redirect_urls(){
        let { app, url } = this.props
        let parent_id = window.getURLParameter('parentid') // app.permid.PARENT
        let multi_checkin = window.getURLParameter('checkin') === 'multi' // app.permid.PARENT
        let { hostname, protocol, port } = window.location
        return {
            CDA: url.DIGSIE + '/vb/'  + app.pid,
            CDAP: protocol + '//' + hostname + ':' + port + '/cdap/'  + app.pid + (app.fromCookieIgnore ? '?' + window.encodeQuery({fromcookie:'ignore',mode:'checklist'}) : ''),
            DHC: protocol + '//' + hostname + ':' + port +  '/dhc/'  + app.pid + (app.fromCookieIgnore ? '?' + window.encodeQuery({fromcookie:'ignore',mode:'checklist'}) : ''),
            CAAC: url.DF_WEB_VIEWER_CAAC + '?service=caac&qrid=' + app.pid + '&idtoken=' + app.idtoken,
            OMA_WEITER: url.OMA + '?service=oma&qrid=' + app.pid + '&idtoken=' + app.idtoken,
            // Bei Prereg darf fromcookie nur mitgegeben werden wenn nicht checkin=multi
            PREREG: protocol + '//' + hostname + ':' + port + '/cdap/'  + app.pid + (app.fromCookieIgnore ? '?' + window.encodeQuery({fromcookie: multi_checkin ? 'remember':'ignore',mode:'checklist', show: 'preregistration'}) : ''),
            VB: url.DIGSIE + '/vb/'  + app.pid,
            AI: url.DIGSIE + '/ai/' + app.pid,
            AD: url.DF_WEB_VIEWER_AD + '?service=a-dok&qrid=' + app.pid /* + '&user=' + app.getRenderDataElement('text', 'value') */ + '&idtoken=' + app.idtoken,
            EAG: url.DF_WEB_VIEWER_EAG + '?service=wdeag&qrid=' + app.pid /* + '&user=' + app.getRenderDataElement('text', 'value') */ + '&idtoken=' + app.idtoken,
            WD: url.DF_WEB_VIEWER_WD + '?service=wdwd&qrid=' + app.pid /* + '&user=' + app.getRenderDataElement('text', 'value') */ + '&idtoken=' + app.idtoken + '&parent=' + parent_id
        }
    }


    pick_json_value_or_local_variable( jstr, local ){
        let { app } = this.props
        return jstr ? ReactHtmlParser ( app.replace_percent( jstr.trim() )) : local
    }


    render() {
        let { app, meta } = this.props
        let { WD, AI, BI, AD, VB, CDA, EAG, CDAP, CAAC, OMA, DHC, SERVICE} = app.type
        let urls = this.urls = this.get_redirect_urls()
        // let header_name = app.getRenderDataElement('header', 'name')

        if( window.getTemplateName() !== SERVICE && !AI){
            // window.showError( `Type Conflict: Url Type '${window.getTemplateName()}' does not match Json Type '${SERVICE}' (Possible Server Reload after Create and/or deleted Setup Success Cache in Helper.)` )
            console.log( `UQRC type '${window.getTemplateName()}' in url does not match json type '${SERVICE}'.` )
        }

        let { fontSizeBig } = window.customCss
        let setupSuccessContent = null //<div>no setup success content for this mode</div>
        let adok_weiter_css = { background: 'var(--ad_color_gradient)'}
        let oma_weiter_css = { background: 'var(--oma_color_gradient)'}



        if (AI){
            setupSuccessContent = (
                <DocumentMeta {...meta} >
                    <LocalDevIcon />
                    <Header name={/* header_name */'Immer dabei, mit A-Ident.'} id={'aident'} key={0} pid={app.pid}  app={app}/>
                    <div id='postSaveText' onLoad={window.hideLoader} >
                        <div>
                            <b>Herzlichen Glückwunsch! Dein A-Ident-Etikett ist jetzt fertig konfiguriert</b>
                            <p>Jetzt kann im Ernstfall jeder ganz einfach auf Deine bei A-Ident gespeicherten Daten zugreifen: A-Ident QR-Code scannen und von Dir hinterlegte A-Ident-Informationen werden sofort angezeigt.</p>
                        </div>
                    </div>
                    <div className='ueber_container'><div id='ueberspringen' service={app.type.SERVICE} className='button'  onClick={() => window.location.href = urls.AI} >Weiter zur Vorschau auf Deine A-Ident Daten</div></div>
                </DocumentMeta>
            )

        }else if (EAG){
            setupSuccessContent = (
                <DocumentMeta {...meta} >
                    <LocalDevIcon />
                    <Header name={/* header_name */ 'WDEAG Setup.'} id={'wdeag'} key={0} pid={app.pid}  app={app}/>
                    <div id='postSaveText' onLoad={window.hideLoader} >
                        <div>
                            <b>Herzlichen Glückwunsch! Das WDEAG-Etikett ist jetzt fertig konfiguriert</b>
                            <p>Im nächsten Schritt können Dokumente in die vordefinierten Ordner von <b>{app.displayTitle}</b> hinzugefügt werden.</p>
                        </div>
                    </div>
                    <div className='ueber_container'><div id='ueberspringen' service={app.type.SERVICE} className='button'  onClick={() => window.location.href = urls.EAG} >Weiter</div></div>
                </DocumentMeta>
            )

        }else if (AD){
            setupSuccessContent = (
                <DocumentMeta {...meta} >
                    <LocalDevIcon />
                    <Header id={'adok'} key={0} app={app} name={'Herzlichen Glückwunsch zur Registrierung!'}/>
                    <div id='postSaveText' onLoad={window.hideLoader} >
                        <div>
                            <b>Ihr A-Dok ist jetzt fertig konfiguriert!</b>
                            <p style={{padding: '0'}} >Die Registrierung ist abgeschlossen. Auch Ihr persönlicher QR-Code wurde erstellt und wird Ihnen in den nächsten Tagen zugesendet. Diesen können Sie dann beispielsweise auf Ihrem Versicherungsordner anbringen. <br/><br/>Im nächsten Schritt können Sie Ihre wichtigen Dokumente unter <b>{app.displayTitle}</b>  hinzufügen und später jederzeit über das (optionale) QR-Etikett wieder darauf zugreifen.</p>
                        </div>
                    </div>
                    <div className='ueber_container'><div id='ueberspringen' service={app.type.SERVICE} className='button' style={adok_weiter_css} onClick={() => window.location.href = urls.AD} >Weiter</div></div>
                   
                </DocumentMeta>
            )
        }else if (OMA){
            setupSuccessContent = (
                <DocumentMeta {...meta} >
                    <LocalDevIcon />
                    <Header id={'oma'} key={0} app={app} name={'Registrierung erfolgreich!'}/>
                    <div id='postSaveText' onLoad={window.hideLoader} >
                        <div>
                            <b>Ihre Registrierung wurder erfolgreich gespeichert. </b>
                            <p style={{padding: '0'}} >Weitere Informationen zum Ablauf der Veranstaltung erhalten Sie direkt vom Veranstalter.  </p>
                        </div>
                    </div>
                    <div className='ueber_container'><div id='ueberspringen' service={app.type.SERVICE} url={urls.OMA_WEITER} className='button' style={oma_weiter_css} onClick={() => window.location.href = urls.OMA_WEITER} >Weiter</div></div>
                   
                </DocumentMeta>
            )
        }else if (CDA){
            setupSuccessContent = (
                <DocumentMeta {...meta} >
                    <LocalDevIcon />
                    <Header name={/* header_name */'Objekt anlegen/bearbeiten'} id={'cda'} key={0} pid={app.pid}  app={app}/>
                    <div id='postSaveText' onLoad={window.hideLoader} >
                        <div>
                            <b>Herzlichen Glückwunsch! Dein Objekt wurde erstellt bzw bearbeitet.</b>
                            <p>Im nächsten Schritt kannst Du jetzt einen Zeitplan für die Desinfektion festlegen. </p>
                        </div>
                    </div>
                    <div>
                        <div className='ueber_container'>
                            <div id='ueberspringen' service={app.type.SERVICE} className='button'  onClick={this.getSetupSuccessButtons(app.type).nextBtnFunc} >Zeitplan anlegen</div>
                        </div>
                        <br />
                        <br />
                    </div>
                </DocumentMeta>
            )
        }else if (CAAC){
            setupSuccessContent = (
                <DocumentMeta {...meta} >
                    <LocalDevIcon />
                    <Header name={/* header_name */'Objekt anlegen/bearbeiten'} id={'cda'} key={0} pid={app.pid}  app={app}/>
                    <div id='postSaveText' onLoad={window.hideLoader} >
                        <div>
                            <b>Herzlichen Glückwunsch! Dein Objekt ist jetzt konfiguriert.</b>
                            <p>Die ersten Messwerte sollten nach ca. 15 Minuten empfangen werden.</p>
                        </div>
                    </div>

                    <div className='ueber_container'><div id='ueberspringen' service={app.type.SERVICE} className='button' style={{ background: 'var(--caac_color_gradient)'}} onClick={
                        () => {
                            if (window.webkit) {
                                window.webkit.messageHandlers.close.postMessage({ mode: 'objektliste' })
                            } else if (window.HTMLOUT){
                                window.HTMLOUT.callAndroidFunction("objektliste")
                            } else {
                                window.location.href = urls.CAAC
                            }
                        }} >Weiter</div></div>
                </DocumentMeta>
            )

        }else if ( CDAP ){
                let { icon, text, success_mode } = this.get_success_icon_and_text( 'cdap' )
                const { CONFIRM_REGIST_URL, REFUSE } = success_mode

                if( CONFIRM_REGIST_URL ){ 
                    app.setCookie('message_parent_id_' + app.pid, '', 0 )// doppelter haken
                    urls.CDAP = urls.CDAP.append({icon: 'preregconfirmed'}) //"&icon=preregconfirmed"
                }

                let button_css = {background: 'linear-gradient(to right, #01affe 0%, #1a7cfa 100%)'}

                // id:cdap in json type:header, aber dhc in url:
                let is_dhc = DHC || window.location.pathname.split('/').includes('dhc')
                if( is_dhc )
                {
                    let bc = app.getRenderDataElement('header', 'button_color')
                    button_css = { background: bc || '#e00083'  }
                }

                //bis 07.10. vor qr-code für fussball                let show_qrcode =  cdapmode === "einlasskontrolle" && !this.props.app.fromCookieIgnore && !app.mode.PREREG  && !refuse /// success_mode.CONFIRM_REGIST_URL // icon === cover_gg
                // let message_checkoutparent_id = app.getCookie('message_checkoutparent_id_' + app.pid)
                let cdapmode = this.props.app.getRenderDataElement('header', 'cdapmode')
                let show_qrcode =  cdapmode === "einlasskontrolle" && !this.props.app.fromCookieIgnore && !REFUSE /// success_mode.CONFIRM_REGIST_URL // icon === cover_gg
                let teilnehmerqrid = app.getRenderDataElement('text', 'value', 'teilnehmerqrid')

                if( !this.target_url )
                    this.target_url = 'https://web.corona-presence.de/auth/' + teilnehmerqrid + "/" + app.pid + "/" + (new Date()).getTime()

                let fromSetup = window.getURLParameter('from') === 'setup'
                setupSuccessContent = !fromSetup ? //MENU ? //MENU ist eigentlich falsch, da die ui nach mwsCreate() neu geladen wird und eine checkliste möglicherweise bereits existiert, MENU wäre höchstens im vorherigen schritt passend
                    (
                        <DocumentMeta {...meta} >
                            <LocalDevIcon />
                            <Header name={'Corona Anwesenheitsliste'} id={'cdap'} key={0} pid={app.pid}  app={app}/>

                            <div id='postSaveTextCDAP' onLoad={window.hideLoader} >
                                {/* 
                                    hard-coded alle values im renderData durchsuchen, ob die mit ja/nein bearbeitet wurden
                                    vor reload zu setupsuccess auswerten und dann als url parameter mitgeben &icon=red
                                    rotes icon bereitstellen (rot mit kreuz)

                                    aus preregistration kommend, sichbar machen, ob icon aus vorregistrierung kommt
                                    - green/red
                                    - iconp (icon-prereg)
                                    - siehe oben

                                    fremd-preregistrierung:
                                    - fromcookie=ignore ist auslöser für fremdreg -> email an betrachter senden mit neuem feld mit zB type:preregemail, dann beim speichern 
                                      eine email an eine andresse senden (über direct message)
                                    - empfänger der email klickt auf link (enthält perm_id des vorhergehenden jsonToEdp(() aufrufs ) 
                                    - wie usecheckliste, id mit unterstrich

                                */}

                                {

                                    show_qrcode ? 
                                    <div className='qrcode' style={{maxHeight: '100vh'}}>
                                        <div className='einlasscodetext'>
                                            Einlasscode zur Anwesenheitsliste "{app.getRenderDataElement('text', 'value', 'deviceName')}" <br/> für {window.appjs.getRenderDataElement('text', 'value', 'cdap00')}:
                                        </div>
                                        <div className='codeTextContainer'>
                                            <QRCode
                                                value={this.target_url}
                                                renderAs='svg'
                                                size={512}
                                                bgColor='#00000000'
                                                fgColor='#464c69'
                                            />
                                            <div className='persid'>Pers-ID: { teilnehmerqrid || 'Keine Teilnehmer-QRID gefunden.' }</div>
                                        </div>
                                        { app.mode.PREREG ?
                                            <div className='codeTextContainer'>
                                                <div className='einlasscodetext'>
                                                    <div>Sie sind jetzt vorregistriert - Bitte speichern Sie diesen QR-Code (z.B. als Screenshot) auf Ihrem Smartphone und zeigen ihn am Eingang des Veranstaltungsorts vor.</div>
                                                </div>
                                            </div>
                                            :
                                            <div className='codeTextContainer'>
                                                <div className='einlasscodetext'>
                                                    <div>Bitte zeigen Sie diesen QR-Code (oder den QR-Code auf Ihrem Namensschild) dem Mitarbeiter am Einlass.</div>
                                                </div>
                                                <p>Beim Verlassen des Produktionsbereichs lassen Sie Ihr Namensschild erneut scannen oder scannen den am Ausgang ausgehängten QR-Code</p>
                                            </div>
                                        }
                                        
                                    </div>
                                    :
                                    <div>
                                        <img src={ icon } id='cover' style={{width: '70%', maxWidth: '33rem' }} alt='cover'></img>
                                        <div style={{fontWeight: '400', fontSize: fontSizeBig, textAlign: this.align, marginTop: '4rem' }}>
                                            { // app.fromCookieIgnore kennzeichnet den "Fremd"-Modus, d.h. die person macht es nciht sselbst sondern es macht jemanders für die person
                                            // wahlweise als soforteintrag (dann ohne preregistration parameter) oder als vorregisteriung dann mit PREERG-Parameter
                                                text
                                            }
                                        </div>
                                    </div>
                                }
                            

                                {/* <div id='ueberspringen' service={app.type.SERVICE} className='button' style={{backgroundImage: 'linear-gradient(to right, #01affe 0%, #1a7cfa 100%)'}} onClick={() => window.location.href = urls.PREREG } >
                                        {
                                            app.fromCookieIgnore ? 
                                            'Nächsten Teilnehmer eintragen'
                                            :
                                            'Weiter'
                                        }
                                    </div> */}
                                {
                                    //<a href="website.com/imageurl.png" download="ImageNameHere">click here</a>
                                    app.mode.PREREG || show_qrcode ? 
                                    ''
                                    :
                                        REFUSE || app.refuse_next_button === 'hide' ? 
                                        ''
                                        :
                                        <div className='ueber_container' style={show_qrcode ? {background: '#e8e8e8'} : {} } ><div id='ueberspringen' service={app.type.SERVICE} className='button' style={ button_css } onClick={() => window.location.href = urls.CDAP} >
                                            {
                                                app.fromCookieIgnore ? 
                                                'Nächsten Teilnehmer eintragen'
                                                :
                                                'Weiter'
                                            }
                                        </div></div>
                                }
                           
                                {
                                    app.fromCookieIgnore || show_qrcode ? 
                                    '' : <Socialmedia />
                                }

                        </div>
                            
                        </DocumentMeta>
                    ) : (
                        <DocumentMeta {...meta} >
                            <LocalDevIcon />
                            <div id='postSaveTextCDAP' onLoad={window.hideLoader} >
                                <div>
                                    <Header name={'Corona Anwesenheitsliste Location'} id={'cdap'} key={0} pid={app.pid} app={app}/>
                                    <b>Herzlichen Glückwunsch! Ihre Corona Anwesenheitsliste zu "{app.displayTitle}" wurde erstellt.</b><br/>
                                </div>
                            </div>
                            <div id='aufsteller' className='drucken button'  onClick={() =>window.open( 'https://web.corona-presence.de/webuicdap/qrcode/index.php?data='+app.pid+'&title='+app.displayTitle)} >Tischaufsteller/Aushang drucken</div>
                            <div className='ueber_container'>
                                <div id='ueberspringen' service={app.type.SERVICE} className='button'  onClick={() => window.location.href = urls.CDAP + ( urls.CDAP.includes('?') ? '&' : '? ') + 'print=possible' } >Weiter</div>
                            </div>
                            <Socialmedia />
                        </DocumentMeta>
                    )
        }else if ( DHC ){
                // let is_dhc = DHC || window.location.pathname.split('/').includes('dhc')
                let { icon, text, success_mode } = this.get_success_icon_and_text( 'dhc' )
                const { CONFIRM_REGIST_URL, REFUSE } = success_mode

                if( CONFIRM_REGIST_URL ){ 
                    app.setCookie('message_parent_id_' + app.pid, '', 0 )// doppelter haken
                    urls.CDAP = urls.CDAP.append({icon: 'preregconfirmed'}) //"&icon=preregconfirmed"
                }

                let bc = app.getRenderDataElement('header', 'button_color')
                let button_css = { background: bc || '#e00083'  }

                let jss = app.getRenderDataElement('setup_success') || {}
                jss.Weiter_href= jss.Weiter_href.replace("%idtoken%",app.idtoken)
                jss.Weiter_href= jss.Weiter_href.replace("%qrid%",app.pid)
                jss.Weiter_href= jss.Weiter_href.replace("%trayid%",app.trayid)
                jss.Weiter_href= jss.Weiter_href.replace("%permid%",app.permid.CHILD)
            

                let cdapmode = this.props.app.getRenderDataElement('header', 'cdapmode')
                let show_qrcode =  cdapmode === "einlasskontrolle" /* && !this.props.app.fromCookieIgnore */ && !REFUSE
                let teilnehmerqrid = app.getRenderDataElement('text', 'value', 'teilnehmerqrid')

                if( !this.target_url )
                    this.target_url = 'https://web.corona-presence.de/auth/' + teilnehmerqrid + "/" + app.pid + "/" + (new Date()).getTime()

                let fromSetup = window.getURLParameter('from') === 'setup'
  
                if( !fromSetup ){
                    setupSuccessContent = 
                        <DocumentMeta {...meta} >
                            <LocalDevIcon />
                            <Header name={ jss.header_type || 'CoronaAssist:RAPIDOK'} id={ jss.header_type || 'dhc'} key={0} pid={app.pid}  app={app}/>

                            <div id='postSaveTextCDAP' onLoad={window.hideLoader} >
                                {
                                    show_qrcode ? 
                                    <div className='qrcode' style={{maxHeight: '100vh'}}>
                                        <div className='einlasscodetext'>
                                            Identifikations-Code {app.getRenderDataElement('text', 'value', 'dhc00')}.<br/>Bitte zeigen Sie diesen Identifikationscode der verantwortlichen Person für die RAPIDOC-Testdurchführung.
                                        </div>
                                        <div className='codeTextContainer'>
                                            <QRCode
                                                value={this.target_url}
                                                renderAs='svg'
                                                size={512}
                                                bgColor='#00000000'
                                                fgColor='#464c69'
                                            />
                                        </div>
                                        { app.mode.PREREG ?
                                            <div>
                                                <div className='einlasscodetext'>
                                                    Pers-ID: { teilnehmerqrid } <br/>
                                                    Für die dauerhafte Nutzung speichern Sie diesen QR-Code (z.B. als Screenshot) auf Ihrem Smartphone.
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <div className='einlasscodetext'>
                                                    Pers-ID: { teilnehmerqrid } <br/>
                                                    Bitte zeigen Sie diesen QR-Code (oder den QR-Code auf Ihrem Namensschild) dem Mitarbeiter am Einlass.
                                                </div>
                                            </div>
                                        }
                                        
                                    </div>
                                    :
                                    <div>
                                        <img src={ icon } id='cover' style={{width: '70%', maxWidth: '33rem' }} alt='cover'></img>
                                        <div style={{fontWeight: '400', fontSize: fontSizeBig, textAlign: this.align, marginTop: '4rem'  }}>
                                            { 
                                                text
                                            }
                                        </div>
                                    </div>
                                }
                                {
                                    app.mode.PREREG || show_qrcode ? 
                                    ''
                                    :
                                        REFUSE ? 
                                        ''
                                        :
                                        <div className='ueber_container'>
                                            <div id='ueberspringen' service={app.type.SERVICE} className='button' style={button_css} onClick={() => window.location.href = jss.Weiter_href || jss.weiter_href || urls.DHC} >
                                            {
                                                app.fromCookieIgnore ? 
                                                jss.button_text || 'Nächsten Teilnehmer eintragen'
                                                :
                                                jss.button_text || 'Weiter'
                                            }
                                            </div>
                                        </div>
                                }
                           
                                {
                                    app.fromCookieIgnore || show_qrcode ? 
                                    '' : <Socialmedia />
                                }
                        </div>
                        </DocumentMeta>
                }

                if( fromSetup ){
                    let html_default = <b>Herzlichen Glückwunsch! Ihr RAPIDOK-Objekt zu "{app.displayTitle}" wurde erstellt.</b>
                    let html = this.pick_json_value_or_local_variable( jss.text_html, html_default )

                    setupSuccessContent = 
                        <DocumentMeta {...meta} >
                            <LocalDevIcon />
                            <div id='postSaveTextCDAP' onLoad={window.hideLoader} >
                                <div>
                                    <Header name={ jss.header_type || 'CoronaAssist:RAPIDOK'} id={ jss.header_type || 'dhc'} key={0} pid={app.pid} app={app}/>
                                    {
                                        html
                                    }
                                    <br/>
                                </div>
                            </div>
                            {/* <div id='aufsteller' className='drucken button'  onClick={() =>window.open( 'https://web.corona-presence.de/webuicdap/qrcode/index.php?data='+app.pid+'&title='+app.displayTitle)} >Tischaufsteller/Aushang drucken</div> */}
                            <div className='ueber_container'>
                                <div id='ueberspringen' service={app.type.SERVICE} className='button' style={button_css} onClick={() => window.location.href = jss.Weiter_href || jss.weiter_href || (urls.DHC + ( urls.DHC.includes('?') ? '&' : '? ') + 'print=possible') } >{ jss.button_text || 'Weiter'}</div>
                            </div>
                            <Socialmedia />
                        </DocumentMeta>
                }
        }else if ( WD ){
                let fromSetup = window.getURLParameter('from') === 'setup'
	    	    let url = "./" + app.pid + "?mode=checklist";
                setupSuccessContent = !fromSetup ? //MENU ? //MENU ist eigentlich falsch, da die ui nach mwsCreate() neu geladen wird und eine checkliste möglicherweise bereits existiert, MENU wäre höchstens im vorherigen schritt passend
                    (
                        <DocumentMeta {...meta} >
                            <LocalDevIcon />
                            <Header name={'WhatsDown'} id={'whatsdown'} key={0} pid={app.pid}  app={app}/>

                            <div id='postSaveText' onLoad={window.hideLoader} >
                                <div style={{fontWeight: '400', fontSize: fontSizeBig}}>
                                        <p>Vielen Dank! - Ihre Meldung wurde an den Betreiber von <b >{app.displayTitle}</b> gesendet.</p>
			                            <p>Noch eine Meldung <a href={url}>senden</a></p>
                                </div>
                            </div>                            
                        </DocumentMeta>
                    ) : ( <DocumentMeta {...meta} >
                            <LocalDevIcon />
                            <Header name={'WD Setup.'} id={'whatsdown'} key={0} pid={app.pid}  app={app}/>
                            <div id='postSaveText' onLoad={window.hideLoader} >
                                <div>
                                    <b>Herzlichen Glückwunsch! Der WhatsDown-Code ist jetzt fertig konfiguriert</b>
                                </div>
                            </div>
                            <div className='ueber_container'><div id='ueberspringen' service={app.type.SERVICE} className='button'  onClick={() => window.location.href = urls.WD } >Weiter</div></div>
                        </DocumentMeta>
                    )
        }else  if ( VB ){
            let html_default = <div><b> Herzlichen Glückwunsch! Ihr 1stad-Etikett "{app.displayTitle}" ist jetzt fertig konfiguriert</b><p>Im nächsten Schritt können Sie nun zum Beispiel probeweise einen Eintrag vornehmen.</p></div>

            setupSuccessContent = (
                    <DocumentMeta {...meta} >
                        <LocalDevIcon />
                        <Header name={ '1stad'} id={ '1stad'} key={0} pid={app.pid} app={app}/>
                        <div id='postSaveText' onLoad={window.hideLoader} >
                            {
                                html_default
                            }
                        </div>
                        <div className='ueber_container'>
                            <div id='ueberspringen' service={app.type.SERVICE} className='button' onClick={() => window.location.href = urls.VB} >{ 'Weiter'}</div>
                        </div>
                    </DocumentMeta>
                )
        }else  if ( BI ){

            let redirect_to = window.location.href
            // zuerst nach home-url des selectors im local storage suchen
            let lstor = localStorage.getItem(app.pid)
            if( lstor ){
                try {
                    lstor = JSON.parse( lstor )
                } catch (error) {
                    console.log('lstor parse error')
                }
            }
            
            if( lstor && lstor.home ){
                redirect_to = lstor.home
            }else{ // wenn keine selector-home-url, dann usecheckliste etc aus url entfernen
                redirect_to = app.url_reduce({usecheckliste:'', display:'', refuse:'', parentid:''}, {url: redirect_to})

                // lokal das bi selector-json aufrufen, um deploytes verhalten zu simulieren 
                if( app.url_has('localtemplate')){
                    redirect_to = app.url_append({localtemplate:'boe_feb2022'}, {url: redirect_to})
                }
            }

            let header_name = app.getRenderDataElement('header', 'name')
            setupSuccessContent = ( <DocumentMeta {...meta} >
                <LocalDevIcon />
                <Header name={'WD Setup.'} id={'whatsdown'} key={0} app={app} subtext={ header_name || 'Checkliste'}/>
                <div id='postSaveText' onLoad={window.hideLoader} >
                    <div>
                        <b>Der Rundgang wurde erfolgreich gespeichert.</b>
                    </div>
                </div>
                <div className='ueber_container'>
                    <div id='ueberspringen' 
                        className='button'
                        style={{
                            fontWeight: 500
                        }}
                        service={app.type.SERVICE} 
                        branch={'else'} 
                        onClick={() => window.location.href = redirect_to } 
                        >Zurück zur Übersicht
                    </div>
                </div>
            </DocumentMeta>
        )
    }else{
            let redirect_to = urls.WD

            setupSuccessContent = ( <DocumentMeta {...meta} >
                <LocalDevIcon />
                <Header name={'WD Setup.'} id={'whatsdown'} key={0} pid={app.pid}  app={app}/>
                <div id='postSaveText' onLoad={window.hideLoader} >
                    <div>
                        <b>Herzlichen Glückwunsch! Das WhatsDown-Object ist jetzt fertig konfiguriert.</b>
                    </div>
                </div>
                <div className='ueber_container'><div id='ueberspringen' service={app.type.SERVICE} branch={'else'} className='button'  onClick={() => window.location.href = redirect_to } >Weiter</div></div>
            </DocumentMeta>
        )
        }
        return setupSuccessContent
    }
}

export default SetupSuccess


/* {
    “type”:“setup_success”,
    “text_html”:“<div><b> Herzlichen Glückwunsch! Ihre Test-Location %deviceName% ist jetzt fertig konfiguriert.</b><p>Drücken Sie auf Weiter und beginnen Sie mit der Erfasung von Tests.</p></div>“,
    “header_type”: “dhc”,
    “button_text”:“Weiter”,
    “Weiter_href”:“https://xyz.de/abc”
} */
