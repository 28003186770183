import React, { Component } from 'react'

class ShowTime extends Component {
  constructor(props, context) {
    super(props, context)

    this.name = this.props.e.name

  }

  render() {
/* 
    if( !window.refresh_count )
      window.refresh_count = 0
    else
      window.refresh_count++

    window.showError( window.refresh_count, true )
 */

    if( !this.time )
      this.time = new Date().toLocaleString()
    /* throw new Error('ERROR THROWN') */
    let after_prereg = this.props.app.getCookie('message_parent_id_' + this.props.app.pid, 'ShowTime' ).includes('PRE:')
    let textcss = { color: '#ff0069', fontWeight: 'bold' }

    let cdapmode = this.props.app.getRenderDataElement('header', 'cdapmode')
    if( cdapmode === "einlasskontrolle" && !this.props.app.fromCookieIgnore ){
      return (
        <div>
            {
              after_prereg ?
              <div className='ShowTime' style={{fontWeight: 'bold', color: '#ff0052'}}>Bitte überprüfen Sie Ihre Angaben</div>
              : ''
            }
            <div className='ShowTime' style={this.props.app.mode.PREREG ? textcss : {}}>
                {this.props.app.mode.PREREG ? 'Einlassberechtigung für: ' : ( this.props.e.name || 'Ankunft') + ': ' + this.time  } 
            </div>
            <div className='ShowTimeTitle'>
                {this.props.app.displayTitle}
            </div>
            
            
        </div>
        
      )
    }

    return (
        <div className='ShowTimeContainer' >
            {
              after_prereg ?
              <div className='ShowTime' style={{fontWeight: 'bold', color: '#ff0052'}}>Bitte überprüfen Sie Ihre Angaben aus der Vorregistrierung</div>
              : ''
            }
            <div className='ShowTime' style={this.props.app.mode.PREREG ? textcss : {}}>
                {this.props.app.mode.PREREG ? 'Vorregistrierung für: ' : ( this.props.e.name || 'Ankunft') + ': ' + this.time } 
            </div>
            <div className='ShowTimeTitle'>
                {this.props.app.displayTitle}
            </div>
            
            
        </div>
        
      )
  }
}  

export default ShowTime


