import React, { Component } from 'react'
import Card from './Card'
import Note from './Note'

import cross from './svg/attentionMark.svg'
import unavail from './svg/notavailable.svg'
import checked from './svg/checked.svg'
import details from './svg/fwarrow.svg'
import info from './svg/info.svg'

import aiCross from './svg/cross.svg'
import aiCrossT from './svg/aiCrossT.svg'
import crossT from './svg/crossText.svg'
import unavailT from './svg/notavailableText.svg'
import checkedT from './svg/checkedText.svg'
import nein from './svg/nein.svg'
import ja from './svg/ja.svg'

import aiCrossTW from './svg/aiCrossTW.svg'
import crossTW from './svg/crossTextW.svg'
import unavailTW from './svg/notavailableTextW.svg'
import checkedTW from './svg/checkedTextW.svg'
import neinW from './svg/neinW.svg'
import jaW from './svg/jaW.svg'
import neinWbig from './svg/neinWbig.svg'
import jaWbig from './svg/jaWbig.svg'

import empty from './svg/empty.svg'

const SCROLL = false
const HEADER_HEIGHT = 300 /* ungefähr */
var server
/* 
const MODAL_SIGNATURE_HEIGHT = 260 // TODO alt, rausnehmen
const MODAL_STANDARD_HEIGHT = 8 // TODO alt, rausnehmen
const MODAL_EXTENDED_HEIGHT = 260 + 90 // 90px -> datum Feld // TODO alt, rausnehmen
// const MODAL_DATUM_HEIGHT = 80 // TODO alt, rausnehmen
 */
class CheckListCard extends Component {
    constructor(props, context) {
        super(props, context)


        this.iX = null
        this.vals = this.props.e.vals
        this.cat_ok = this.props.e.value_ok
        this.modal = null
        // this.modalHeight = MODAL_STANDARD_HEIGHT // TODO alt, rausnehmen
        this.app = this.props.parent



        /* 
            icons
        */
        this.icon = []
        this.icon['unacceptable'] = cross
        this.icon['nichtvorhanden'] = aiCross
        this.icon['notavailable'] = unavail
        this.icon['excellent'] = checked
        this.icon['nein'] = neinWbig// aiCross
        this.icon['ja'] = jaWbig//checked
        this.icon['details'] = details
        this.icon['info'] = info

        this.iconBig = []
        this.iconBig['unacceptable'] = crossT
        this.iconBig['nichtvorhanden'] = aiCrossT
        this.iconBig['notavailable'] = unavailT
        this.iconBig['excellent'] = checkedT
        this.iconBig['nein'] = nein
        this.iconBig['ja'] = ja

        this.iconBigW = []
        this.iconBigW['unacceptable'] = crossTW
        this.iconBigW['nichtvorhanden'] = aiCrossTW
        this.iconBigW['notavailable'] = unavailTW
        this.iconBigW['excellent'] = checkedTW
        this.iconBigW['nein'] = neinW
        this.iconBigW['ja'] = jaW

        /* 
            colors
        */
        this.color = []
        this.color['nichtvorhanden'] = 'red'
        this.color['unacceptable'] = 'red'
        this.color['notavailable'] = 'orange'
        this.color['excellent'] = 'green'
        if( this.app.type.AI ){
            this.color['nein'] = 'red'
            this.color['ja'] = 'green'
        }else{
            /*  cdap ? */
            this.color['nein'] = 'green'
            this.color['ja'] = 'red'
        }

        /* 
            colors aus json berücksichtigen
        */
        for ( var val in this.vals ) {
            if ( this.vals.hasOwnProperty( val )){
                let _v = this.vals[ val ]
                if( _v.cat && _v.css ){
                    this.color[ _v.cat ] = _v.css
                }
            }
        }

        window.checklistcard = this


        /* 
            default edp-value-mappings:

            0 : ok
            1 : not ok
            2 : not available
        */
        this.select = []
        if( this.app.type.AI ){

            this.select['ja'] = 1 // 0 ..130322
            this.select['nein'] = 0 //1 ..130322

        }else{
            if( this.cat_ok === 'ja' ){

                this.select['ja'] = 0
                this.select['nein'] = 1

            }else if( this.cat_ok === 'nein' ){

                this.select['ja'] = 1
                this.select['nein'] = 0

            }else{
                /* default */
                this.select['ja'] = 1 
                this.select['nein'] = 0 
            }
        }
        this.select['excellent'] = 0
        this.select['unacceptable'] = 1
        this.select['notavailable'] = 2
        this.select['nichtvorhanden'] = 1
        this.select['0'] = 1 //edp mapping
        this.select['1'] = 0
        this.select['2'] = 2

        this.mapping = []
        // console.log('checklist constructor mapping[]: this.props.ai %o', this.props.ai)
        this.mapping[0] = this.props.ai ? 'ja' : 'excellent'
        this.mapping[1] = this.props.ai ? 'nein' : 'nichtvorhanden'
        this.mapping[2] = this.props.ai ? 'nein' : 'notavailable'

        this.state = {
            choice:      this.select[ this.props.e.value ],
            showDetails: this.props.e.is_open === 'true',
            isOpen:      this.props.e.is_open === 'true',
            redBorder:   false
        }


        this.defaultCSS = 'defaultColor'
        this.defaultCSSBig = 'defaultColorBig'

        this.setNoteRef = (i, t) => {
            this.qrCodeNoteRef = t
        }



        this.qrCodeNoteElem = <Note content='Bitte QR-Code scannen um Rundgang zu starten!' setNoteRef={this.setNoteRef} />



        //this.itemRef = React.createRef()

        this.startTouch = this.startTouch.bind(this)
        this.moveTouch = this.moveTouch.bind(this)
        this.toggleOpen = this.toggleOpen.bind(this)
        // this.scrollToMyRef= this.scrollToMyRef.bind(this)  
        this.closeOthers = this.closeOthers.bind(this)
        this.getSignature = this.getSignature.bind(this)
        this.handleIconClick = this.handleIconClick.bind(this)

        this.setModalRef = (m) => {
            this.modal = m
        }

        this.toggleRedBorder = () => {
            let self = this

            setTimeout(function () {
                self.setState({ redBorder: self.state.choice === null && self.app.inspectedRequiredFields })
            }, 30)
        }

        /*         this.getModalHeight = (t) => { // TODO alt, entfernen
                    let h = 100
        
                    if (t === 'standard')
                        h = this.signed ? MODAL_STANDARD_HEIGHT + MODAL_SIGNATURE_HEIGHT : MODAL_STANDARD_HEIGHT
        
                    if (t === 'extended')
                        h = this.signed ? MODAL_EXTENDED_HEIGHT + MODAL_SIGNATURE_HEIGHT : MODAL_EXTENDED_HEIGHT
        
                    this.modal.setState({
                        mHeight: h + this.modal.state.descriptionHeight
                    })
        
                    return h
                } */
    }

    componentDidMount() {
        server = window.server

        window.onresize = this.forceUpdate


        this.offset = document.getElementById(this.props.e.id).offsetTop // TODO alt, entfernen
        this.section = this.props.section

        
        let self = this
        let el = this.refs.thisElement
        
        try {
            el.dataset.clientHeight = el.offsetHeight
            el.dataset.display = self.props.collapse ? 'hide' : 'show'

            el.dataset.required = self.required = self.props.e.required || false
            el.dataset.value = self.state.choice || ''
        } catch (error) {
            let trySetData = setInterval(function(){

                if(self && self.refs && self.refs.thisElement ){
                    el.dataset.clientHeight = el.offsetHeight
                    el.dataset.display = self.props.collapse ? 'hide' : 'show'

                    el.dataset.required = self.required = self.props.e.required || false
                    el.dataset.value = self.state.choice || ''
                    clearInterval(trySetData)
                }
            }, 250)
        }
    }

    componentDidUpdate() {
        this.refs.thisElement.dataset.value = this.state.choice === 0 ? 0 : this.state.choice || ''
    }



    startTouch(e) {
        this.iX = e.touches[0].clientX
    }

    moveTouch(e) {

        if (this.iX === null) {
            return
        }

        var cX = e.touches[0].clientX
        var diffX = this.iX - cX
        this.iX = null

        document.getElementById((e.target || e.srcElement).id).innerHTML = diffX > 0 ? "swiped left" : "swiped right"

        e.preventDefault()
    }

    closeOthers() {
        this.app.closeOthers(this.props.index)
    }



    async getSignature(id) {
        if (this.signed)
            return

        let imageData

        if (window.mag_webkit) {
            /* document.getElementsByClassName('header')[0].innerHTML = 'await' */
            this.signProcess = 'await'
            imageData = await window.OpenCamera(id, this.props.e.mode)
            this.signProcess = 'received'

            imageData += '&srts=' + new Date().getTime()
        }
        else
            imageData = "https://t4.ftcdn.net/jpg/01/94/23/99/240_F_194239972_4bsovcUCbOY32G03JcEKeALGlO51TFHN.jpg"



        this.app.setImagePath(imageData, this.props.index)

        // this.modalHeight = this.modalHeight + MODAL_SIGNATURE_HEIGHT

        this.modal.setSignatureField(imageData)

        this.signed = true

        document.getElementById(this.props.e.id + 'ImgPreview').src = imageData
    }

    handleIconClick(i, curSel, e) {

        // nur für bi: text für mangelhaft-button nötig
        if( this.app.type.BI ){
            // nur wenn textarea vorhanden
            if( this.modal ){
                // nur bei problem/mangelhaft/rotem button
                let problem = e.cat === 'mangelhaft' || e.css === 'red'
                if( problem ){
                    let has_msg = Boolean(this.modal.state.msg)
                    if( has_msg ){
                        // no-op bzw einfach weiter
                    }else{ // wenn keine message

                        let self = this
                        if( !self.modal.trotzdem_weiter ){
                            window.show_popup( 
                                'Beschreibung fehlt', 
                                '', 
                                {
                                    okay: {
                                        name: 'Beschreibung verfassen', 
                                        callback: function(){
                                            console.log('verfassen')
                                            self.modal.refs.anmerkung.focus()
                                        }
                                    },
                                    cancle: {
                                        name: 'Ohne Beschreibung weiter', 
                                        callback: function(){
                                            console.log('trotzdem')
                                            self.modal.trotzdem_weiter = true
                                            self.handleIconClick(i, curSel, e)
                                        }
                                    }
                                }
                            )

                            return false
                        }
                    }
                }
            }
        } 

        /* hide popup */
        window.close_popup()

        if (this.signProcess === 'await')
            return


        /* let ai = this.props.ai
        let u = this.app.getOpenModal()
        let m = this.modal
        if (u && u !== m && !ai)
            return u.notes['noText'].show() */


        this.app.checklistFinished()

        let v = curSel === i ? null : i

        if (!this.state.isOpen) /* is closed / not active (modal?) */
        {
            /* this.app.closeOthers(this.props.index) */
            this.setState({ choice: v, showDetails: !this.state.showDetails, isOpen: true })
            this.toggleRedBorder()

        /* is open / active (modal?) */   
        } else 
        { 
            
            let next_element = this.app.getNext( this.props.index )
            if( next_element ){

                if( next_element.state.choice === undefined ){
                    let self = this
                    setTimeout(() => {
                        
                        self.app.openNext(self.props.index, false, true )
                    }, 600 );
                }
            }

            this.setState({ choice: v })
            this.toggleRedBorder()

            if (v === null)
                return this.showAdditionalInputFields = false

            // bei BI vorerst immer zum nächsten checklisten-punkt springen
            // eventuell abhängig davon machen, ob textfeld für fehlerbeschreibung vorhanden ist
            let checkAndMoveOn = e.cat.match(/^(excellent|notavailable|ja|nein)$/) || this.app.type.BI 
            if (checkAndMoveOn) {
                this.toggleOpen()

/*                 this.app.closeCurrent(this.props.index)

                if (this.props.e.mode !== 'signature')
                    this.app.openNext(this.props.index) */
            } else {
                this.showAdditionalInputFields = true
            }
        }


        /* 
            if selected (v != null),
            set element's value
            with icon-category e.cat

            example:
            cat 'ja' maps to 0
            cat 'nein' maps to 1

            0, 1: edp values
        */
        let val 
        if( v === null )
            val = ''
        else
            val = e.cat // this.select[ e.cat ] // 'ja/nein' -> 0, 1

            /* TODO:
                die ganzen verrückten mappings (this.select[...]) ausbauen, da wir jetzt ein value_ok haben.
            */

        let { id } = this.props.e
        document.getElementById( id ).value = val
    }


    focus_scroll( element ){

        let class_list = Object.values( element.classList )
        let is_card_title = class_list.includes('cardTitle')
        if( !is_card_title ){
            let nodes = element.getElementsByClassName('cardTitle')
            let array = Object.values( nodes )

            if( array.length ){
                element = array[0]
            }
        }
        console.log( element )

        let h_height = HEADER_HEIGHT

        let headers = document.getElementsByClassName('header')
        if( headers.length ){

            let header = document.getElementsByClassName('header')[0]
            if( header ){

                h_height = header.offsetHeight + 80 /* kleine offset-korrektur */
            }
        }

        if( SCROLL )
            window.scrollTo({
                left: 0,
                top: element.getBoundingClientRect().top + window.scrollY - h_height,
                behavior: 'smooth'
            })
        
        
    }


    row_onclick( buttons, self ){

        /* hide popup */
        window.close_popup()


        
        // console.log('checklist onClick start')
        // console.log('this.app.mode.VIEW /*renderSavedInstance*/ %o', this.app.mode.VIEW /*renderSavedInstance*/)
        if (this.app.mode.VIEW /*renderSavedInstance*/)
            return

        if (this.props.disabled) {
            this.qrCodeNoteRef.show()
            return
        }

        /* if (this.app.unfinishedModal()) {
            this.app.getOpenModal().notes['noText'].show()
            return
        }
 */
        if (this.modal)
            this.modal.setDismiss(false)

        if (this.props.e.mode === 'signature')
            this.getSignature(this.props.e.id)

        /* if (!buttons.length)
            return */

        /* this.closeOthers() */
        this.setState({ showDetails: !this.state.showDetails, isOpen: !this.state.isOpen })

        if( !this.state.isOpen )
            setTimeout(() => {
                
                this.focus_scroll( self )
            }, 200);

        // console.log('checklist onClick this.app.checklistFinished()')
        this.app.checklistFinished()
    }


    toggleOpen = ( stay_opened, keep_position ) => {

        if( !keep_position /* !this.state.showDetails */ )
            this.focus_scroll( this.refs.thisElement )

        if( !stay_opened )
            this.setState({ 
                showDetails: !this.state.showDetails, 
                isOpen: !this.state.isOpen 
            })
    }


    render() {

        /* 
            e ist jeweils ein button element
            i dessen index
        */
        let { VIEW } = this.app.mode
        let buttons = !this.vals ? [] : this.vals.map((e, i) => {

            if ( VIEW ) {

                /* 
                    statisches system:

                    falls e.value vorhanden:
                    e.value 0/1 zu ja/nein mappen
                    um passendes ja/nein icon zu rendern
                    und die passende farbe zu zeigen
                */
                let map_value
                let int_value = parseInt( this.props.e.value )
                if( int_value ){
                    map_value = this.mapping[ int_value ] // reverse mapping e.g. 0->nein, 1->ja
                }else{
                    map_value = this.props.e.value
                }
                let cat_match = map_value === e.cat // kategorie stimmt überein
                let css = this.color[e.cat]

                return this.props.e.value !== 'null' && cat_match ?
                    
                    <td key={i} style={{ textAlign: 'right', verticalAlign: 'middle', width: '0.001%' }}>
                    <span
                        id={i}
                        key={i}
                        className={'smallPoint cardButton ' + css }
                        style={ {}/* { display: display, opacity: this.state.showDetails ? 0 : 1 , transitionDelay: this.state.showDetails ? '0ms' : '550ms' } */}
                        onClick={() => {
                            /* this.handleIconClick(i, curSel, e) */
                        }}>
                        { e.cat }
                    </span>
                </td>
                    :
                    null


            /* EDIT / CHECKLIST */
            } else {

                let curSel = this.state.choice
                let css = curSel === i ? this.color[e.cat] : 'smallInactive'//this.defaultCSS
                // let display = curSel === i || this.state.showDetails ? 'inline-block' : 'none'

                /* 
                    opacity und transition-verzögerung 
                    beim aus-/einblenden
                */
                let style = {
                    display: 'inline-block' , 
                    opacity: this.state.showDetails ? 0 : 1 , 
                    transitionDelay: this.state.showDetails ? '0ms' : '550ms'
                }

                /* 
                    ausblende-animation von ja/nein,
                    wenn inaktiv
                */
                if( curSel !== i ){
                        
                    style.width = '0'
                    style.padding = '0'
                    style.margin = '0'
                }

                /* 
                    dynamisches/editierbares ja/nein auswahl-system:
                    in <td> element rendern, da table-kapselung
                    zeigt icon zum ausgewählten wert an (e.cat)
                */
                return (
                    <td key={i} style={{ textAlign: 'right', verticalAlign: 'middle', width: '0.001%' }}>
                        <span
                            id={i}
                            key={i}
                            className={'smallPoint cardButton ' + css}
                            style={ style/* { display: display, opacity: this.state.showDetails ? 0 : 1 , transitionDelay: this.state.showDetails ? '0ms' : '550ms' } */}
                            onClick={() => {
                                /* this.handleIconClick(i, curSel, e) */
                            }}>
                            { e.cat }
                        </span>
                    </td>
                )
            }
        })



        let buttonsBIG = !this.vals ? [] : this.vals.map((e, i) => {

            let css = this.props.e.mode === 'signature' && this.vals && this.vals.length === 1 ? 'green' : (this.state.choice === i ? this.color[e.cat] : this.defaultCSSBig)
            let curSel = this.state.choice
            // let display = this.state.showDetails || curSel === i ? 'inline-table' : 'none'
            // let float = i === 1 ? 'right' : 'none'
            let width = '-webkit-fill-available'


            /* let width = '100%'
            let width = '-moz-available;'
            let width = '-webkit-fill-available'
            let width = 'fill-available' */

            // TODO heir sollte es keinen "render mode" geben *?*
            if (this.app.mode.VIEW /*renderSavedInstance*/)
                return this.props.e.value !== 'null' && this.props.e.value === i ? (
                    <span id={i} key={i} className={'cardButton ' + this.color[e.cat]}>
                        { e.cat }
                    </span>
                )
                    :
                    null
            else
                return (
                    <td key={i} style={{ width: '50%'}}>
                        <span
                            id={i}
                            key={i}
                            className={'cardButton ' + css}
                            style={{ display: 'block', width: width }}
                            onClick={() => this.handleIconClick(i, curSel, e)}>
                            { e.cat }
                        </span>
                    </td>
                )
        })



        let detailsBtn = this.props.e.detail === 'true' ? (
            <span key={999} className='detailsButton' >
                <img src={this.icon['details']} className="CheckButton" alt={'details'} />
            </span>
        ) : null



        let borderColor = this.state.showDetails ? 'hsla(210, 97%, 39%, 1)' : '#6d6d6d17'
        let p = this.props.e.priority
        if (p && p !== '') {
            switch (p) {
                case '0':
                    borderColor = '#02cc6c'
                    break;
                case '1':
                    borderColor = '#ffc000'
                    break;
                case '2':
                    borderColor = 'red'
                    break;

                default:

            }
        }



        let showDetailsCss = { height: '0' } // this.state.showDetails ? '76px' : '0' }
        let selectedRowCSS = this.state.showDetails ? {
            /* backgroundColor: 'rgb(19, 135, 251)', */
            /* borderLeft: '6px solid ' + borderColor, */
            /* color: 'hsla(0, 0%, 96%, 1)' */

        } : { 
            opacity: this.props.disabled ? '0.251' : '1.0'/* , 
            borderLeft: '5px solid ' + borderColor  */
        }

        if( VIEW ){
            selectedRowCSS.borderBottom = 'none'
        }

        let buttonContainerCSS = this.state.showDetails ? {} : {
            backgroundColor: 'transparent'
        }

        let arrowRotate = this.state.showDetails ? 90 : 0

        let openRowClass = this.state.isOpen ? ' open' : ''
        let placeholder = this.props.e.descr ? this.props.e.descr : 'Bemerkung'
        let subitem = this.props.e.sub === 'true' ? { marginLeft: '24px' } : {}


        // config row width
        let c = 0
        if (this.state.isOpen)
            c = this.vals ? this.vals.length : 0
        else
            if (this.state.choice !== null)
                c = 1

        let landscape = window.matchMedia("(min-aspect-ratio: 4/3)").matches || false
        let maxwidth  = landscape ? '800px' : '100vw'
        let fullwidth = { width: 'calc(' + maxwidth + ' - ' + (140 + c * 80) + 'px)' } //800px


        let redBorder = this.state.redBorder ? '.8rem solid red' : '0rem solid red'

        let { SETUP, EDIT, CHECKLIST } = this.app.mode
        let hasValue = this.props.e.value || EDIT || SETUP || CHECKLIST? 'block' : 'none'

        var re = new RegExp('_', 'g');

        return ( 
            <div className={this.props.section + ' ' + this.props.e.type /* checkit */ + ' card'} style={{ /* transition: 'all 200ms ease 0s', */ borderRight: redBorder, display: hasValue, fontSize: 'var(--normal)' }} ref='thisElement' data-parent-section={this.props.section.replace(re, ' ')} data-index={this.props.index}>
                <div id={this.props.e.id} className={'checkListCardRow' + openRowClass} style={ selectedRowCSS }>
                    <table>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: 'left', verticalAlign: 'inherit', paddingRight: '1rem' }} onClick={ ( self ) => this.row_onclick( buttons, self.target )}>
                                    <span className='CardName' style={{/* fullwidth */}} ref={this.props.e.id}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td className='cardNameEnum'>
                                                        <span>
                                                            { this.props.enum }
                                                        </span>
                                                    </td>
                                                    <td className='cardTitle'>
                                                        { this.props.e.name }
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <img id={this.props.e.id + 'ImgPreview'} className='ImgPreview' src={empty} alt='prev' />
                                    </span>
                                </td>
                                {buttons}
                                {
                                    this.app.mode.VIEW ? 
                                    null
                                    :
                                    <td style={{ verticalAlign: 'middle', width: '1%' }} onClick={ ( self ) => this.row_onclick( buttons, self.target )}>                                     
                                        <div className='icomoon_general icomoon_arrow' style={{ transition: 'var(--global_transition_time)', transform: 'rotate( ' + arrowRotate + 'deg )' }}>{/* &#9701; */}</div>
                                    </td>
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div style={showDetailsCss} className='detailTextContainer' >
                    <input type="text" className='detailText' placeholder={placeholder}></input>
                </div>

                {
                    this.app.mode.VIEW /*renderSavedInstance*/ ?
                        null
                        :
                        <Card
                            checkit={this}
                            app={this.app}
                            show={this.state.isOpen}
                            showDatum={this.props.e.showDatum}
                            setModalRef={this.setModalRef}
                        closeThis={this.toggleOpen}
                            itemData={this.props.e}
                            buttons={buttonsBIG}
                            ai={this.props.ai}
                        showAdditionalInputFields={this.showAdditionalInputFields}
                            scrollOffset={this.offset}
                            closeOthers={this.app.closeOthers}
                            openNext={this.app.openNext}
                        saveIntermediate={this.app.save_intermediate}
                            index={this.props.index}
                            parentID={this.props.e.id}
                        setImagePath={this.app.setImagePath}
                        imagePath={this.props.e.userImage}
                            msg={this.props.e.msg}
                            datum={this.props.e.datum}
                            mode={this.props.e.mode}
                            signed={this.signed}
                            checkFinished={this.app.checklistFinished}
                        />
                }
                {this.qrCodeNoteElem}
            </div>
        )
    }
}

export default CheckListCard