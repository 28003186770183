import React, { Component } from 'react'
import './css/AGB.css'
import attention from './svg/attention.svg'


class AGB extends Component {
  render(){
     
    return (
      <p className="agbContainer" >
        <img id='img' src={attention} alt='!'/>
        <span id='txt'>
        Mit <b>{'Speichern'}</b> bestätigen Sie, dass Sie unsere <a href='https://www.dig-sie.de/IMPRESSUM-AGB/Datenschutz/' id='datenschutz' target='_blank' rel='noopener noreferrer'>Datenschutzerklärung</a> und <a href='https://www.schatzengel.de/l/tac' id='agb' target='_blank' rel='noopener noreferrer'>Allgemeinen Geschäftsbedingungen</a> zur Kenntnis genommen haben.
        </span>
      </p>
    )
  }
}

export default AGB
