/*eslint no-extend-native: ["error", { "exceptions": ["String","Array"] }]*/

import React, { Component } from 'react'
import { isAndroid, isMobile, deviceDetect } from 'react-device-detect'
import DocumentMeta from 'react-document-meta'
import Introduction from './Introduction'
import WDIntroduction from './WDIntroduction'
import CheckListItem from './CheckListItem'
import CheckListCard from './CheckListCard'
import StartButton from './StartButton'
import PhotoAdd from './PhotoAdd'
import DocumentAdd from './DocumentAdd'
import TextBox from './TextBox'
import Socialmedia from './SocialMedia'
import Image from './Image'
import Signature from './Signature'
import FileList from './FileList'
import Back from './Back'
import Home from './Home'

import Section from './Section'
import Header from './Header'
import Submit from './Submit'
import Input from './OldSchoolInput'
import Warning from './Warning'
import PhotoDisplay from './PhotoDisplay'
import DocumentDisplay from './DocumentDisplay'
import UnknownElement from './UnknownElement'
import LocalDevIcon from './LocalDevIcon'
import SetupSuccess from './SetupSuccess'
import SetupSuccessEN from './SetupSuccess_en'
import NewProtocol from './NewProtocol'
import IconSelector from './IconSelector'
import TemplateSelector from './TemplateSelector'
import ShowTime from './ShowTime'
import Error from './Error'
import Start from './Start'
import Edit from './Edit'
import Note from './Note'
import AGB from './common/AGB'
import AGBAdok from './variants/a-dok/AGB'
import InsertElement from './InsertElement'
import CheckBox from './CheckBox.js'
import LastMessages from './LastMessages.js'
import BlueErrorPage from './variants/empty/Startpage'
import ServiceSelector from './ServiceSelector/ServiceSelector'

import IconButton from './IconButton'
import HeaderServiceSelector from './HeaderServiceSelector'
import NewReport from './ServiceSelector/NewReport'
import ModifierContainer from './ModifierContainer'

import CdapLogout from './variants/cdap/Logout'
import CdapLogoutEN from './variants/cdap/Logout_en'


import { log } from '../helpers/PrintHTMLDebug.js'
import Exists from './Exists'
import ServerLog from './Server'

import mws_conf from '../mws.js'

var server = new ServerLog()
Exists.setLoggingServer( server )
window.server = server /* global */

const uuidv4 = require('uuid/v4');

const FILE_NOT_FOUND = 'no such file'
const SEMICOLON = '_semic0lon_'
const SEMICOLON_RX = window.SEMICOLON_RX
const COOKIE_MAX_AGE = window.COOKIE_MAX_AGE
const COOKIE_CREATE_TIME = window.COOKIE_CREATE_TIME
const HTML_SCALE_DESKTOP = '75%'
const HTML_SCALE_MOBILE = '150%'
const HTML_SCALE_IPAD = '75%'
const HTML_SCALE_ANDROID = '200%'
const GCCAPTCHA_SITEKEY = '6LdqOusZAAAAAKCh8XdmCswAARHxnUWK41K1iK7O'


/* eval() positions */
const EVAL_AHEAD_OF_COOKIES = 'EVAL_AHEAD_OF_COOKIES' 
const EVAL_AHEAD_OF_POSTMESSAGE = 'EVAL_AHEAD_OF_POSTMESSAGE' 
const EVAL_AHEAD_OF_REDIRECT = 'EVAL_AHEAD_OF_REDIRECT' 

const HOST = window.location.hostname
const LOCALHOST = HOST.includes('localhost')
const NGROKHOST = HOST.includes('ngrok')
const LOCALDEV = LOCALHOST || NGROKHOST

const NGROK_SERVER = '222f-217-87-179-183.ngrok.io' //'c74cea476f4f.ngrok.io'// null //'58d42b2bf26c.ngrok.io' //'e3d7b64e0583.ngrok.io' 


// globale mode variablen
// ermittelt durch Exists.match_mode()
// initialisiert durch copyModeToGlobalVars()
var LOADING, // lade-animation anzeigen
INTRO, // intro-seite anzeigen
SETUP, // setup-seite anzeigen
VIEW, // normale view anzeigen
EDIT, // editieren-view anzeigen
CHECKLIST, // checklisten modus anzeigen (boehringer etc.)
PREREG, // pre-registarion-seite anzeigen
SETUP_SUCCESS, // "setup (/set) war erfolgreich" anzeigen
RENDER_DATA_IN_EXISTS, // es sind valide json daten zum rendern der view im exists enthalten
LOGGED_IN, // user ist mit gültigem jwt eingeloggt
SHOW_DOCUMENTS, // die documente aus dem exists response anzeigen
NO_RIGHTS, // telnr/benutzer hat nicht genügend rechte, um objekt zu sehen
EXISTS, // exists wurde (erfolgreich?) durchgeführt
MENU, // menü anzeigen (zB bei ad: auswahl unterschiedlicher optionen per button)
PROTOCOL, // im ggs zu checkliste wird ein protocol ausgefüllt,
EINLASSKONTROLLE

// globale type variablen
// ermittelt durch App komponente in matchUqrcType(...)
// initialisiert durch copyTypeToGlobalVars()
var BI, // boehringer
AI, // a-ident
AD, // a-dok
VB, // verbandsbuch
WD, // whatsdown
WDF, // wachendorff
DHC, // swissonemed
CDA, // corona assist
OMA, // corona assist
CDAP, // corrona presence
CAAC, // corona air
EAG, // eckelmann ag
SERVICE, // kürzel des service-type
USER // username bzw tel nr

const _URL = {
    AI_FBAUTH: 'https://www.digsie.de/fb-auth-ai/',
    AD_FBAUTH: 'https://www.digsie.de/fb-auth-ad/',
    VB_FBAUTH: 'https://www.digsie.de/fb-auth-vb/',
    BI_FBAUTH: 'https://a-dok.digsie.de/fb-auth',
    CDAP_FBAUTH: 'https://web.corona-presence.de/webuicdap/',
    WD_FBAUTH: 'https://web.whatsdown.com/login_fbauth/?service=webwhatsdown',
    DIGSIE: 'https://www.digsie.de/',
    CDAP: 'https://uqcda.corona-presence.de/',
    CAAC: 'https://uqcda.corona-presence.de/',
    OMA: 'https://web.mws-aws.de/webui/view-oma-id/',
    DF_WEB_VIEWER_AD: 'https://www.digsie.de/web_ad/view-adok/',
    DF_WEB_VIEWER_CAAC: 'https://web.corona-presence.de/webuicaac/view-caac',
    DF_WEB_VIEWER_VB: 'https://www.digsie.de/web_vb/',
    DF_WEB_VIEWER_CDAP: 'https://web.corona-presence.de/webuicdap/',
    DF_WEB_VIEWER_EAG: 'https://web.whatsdown.com/webuiwdeag/',
    DF_WEB_VIEWER_WD: 'https://web.whatsdown.com/webuiwdwd/',
    WEB_WHATSDOWN: 'https://web.whatsdown.com/webuiwd/'
}

var post_global_options = {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
        "Content-Type": "application/json"
    },
    redirect: "follow",
    referrer: "no-referrer"
}

// var headerIconUrl = 'unset'
const META = isAndroid ? {
    title: 'WhatsDown',
    description: 'WhatsDown Makrolog AG',
    meta: {
        charset: 'utf-8',
        name: {
            viewport: 'width=device-width, target-densitydpi=device-dpi, initial-scale=0, maximum-scale=1, user-scalable=yes'
        }/* ,
        property: {
            'og:image': headerIconUrl
          } */
    }
} : {}

const FILTER_TYPES = [
    'AUTH',
    'auth',
    'redirect',
    'addfolders',
    'addmachine',
    'addreferences',
    'breaksection',
    'urlparams',
    'mws-parameter',
    'copy_from_setup',
    'insert_documents',
    'setup_success',
    'image_menu',
    'authcode_error',
	'resolver',
    '___',
    'cookie_agreement',
	'pdf-section',
    '_home',
    '_image'
]
//TODO 0804 NN alle types, die mit "_" anfangen filtern


// Hilfsklasse zur Vermeidung von mehreren .append()'s
class FormDataFrom extends FormData {
    constructor(json) {
        super()
        for ( var key in json ) {
            var value = json[key]
            if( value instanceof Array )
                this.append(key, value[0], value[1])
            else 
                this.append(key, value)
        }
    }
}
// Hilfsklasse für JWT
class JWT {
    constructor(data) {
        for ( var key in data ) {
            this[key]= data[key]
        }
    }
}

JWT.prototype.alive = function () {
    let i = this.invalid()
    let d = new Date().getTime()
    return !i && d < (this.exp * 1000)
}

JWT.prototype.expired = function () {
    let i = this.invalid()
    let d = new Date().getTime()
    return !i && d > (this.exp * 1000)
}

JWT.prototype.invalid = function () {
    return this.parse_error && Boolean(this.string)
}

Array.prototype.insert = function (index, element) {

    if(Array.isArray(element)){
        for(let i=0; i < element.length; i++){
            this.splice(index+i,0,element[i])
        }
        return this
    }else
        this.splice(index,0,element)

    return this
}


String.prototype.getDestination = function (braces){
    if( braces && braces.length !== 2)
        return ''

    let b = braces ? braces.split('') : ['','']
    b[0] = b[0] ? '\\'+b[0] : ''
    b[1] = b[1] ? '\\'+b[1] : ''

    let regexp = new RegExp(`${b[0]}[\\+]?[\\d\\s-]{11,22}${b[1]}|${b[0]}[\\w.\\-_]+@[\\w.\\-_]+${b[1]}`)
    let found = this.match(regexp)

    if( found ){
        return braces ? found[0].substring(1, found[0].length-1 ) : found[0]
    }else{
        return ''
    }
}

String.prototype.getAllDestinations = function (braces){
    if( braces && braces.length !== 2)
        return ''

    let b = braces ? braces.split('') : ['','']
    b[0] = b[0] ? '\\'+b[0] : ''
    b[1] = b[1] ? '\\'+b[1] : ''

    let regexp = new RegExp(`${b[0]}[\\+]?[\\d\\s-]{11,22}${b[1]}|${b[0]}[\\w.\\-_]+@[\\w.\\-_]+${b[1]}`, 'g')
    // let regexp = new RegExp(`${b[0]}.*[\\+]?[\\d\\s-]{11,22}.*?${b[1]}|${b[0]}.*?[\\w.\\-_]+@[\\w.\\-_]+.*?${b[1]}`, 'g')
    let found = this.match(regexp)

    if( found ){
        return found.join(',').replace(b[0],'').replace(b[1],'').replace(/\s?\/?/g, '')
    }else{
        return ''
    }
}

String.prototype.maskStringBetween = function (braces){
    if( !braces || braces.length !== 2)
        return this

    let b = braces.split('')
    b[0] = b[0] ? '\\'+b[0] : ''
    b[1] = b[1] ? '\\'+b[1] : ''

    let regexp = new RegExp(`${b[0]}.*?${b[1]}`, 'g')
    let found = this.match(regexp)

    if( found ){
        let self = this
        found.map((e, i) => {
            self = self.replace(found[i], '')
            return self
        })
        return self.replace(/\s+/g,' ') // vielfache leerzeichen durch eines ersetzen
    }else{
        return this
    }
}


String.prototype.refresh = function (dest) {
    window.location.href = dest ? dest : this
}

String.prototype.append = function (params, rc) {
    // for each arg ... ? 
    // if string starts with & ... ?
    let p = Object.keys(params)
    if(!p.length)
        return this

    let sign = '?'
    if(this.includes('?')) sign = '&'
    if(this.substr(this.length-1) === '?') sign = ''

    let query = params
    if(typeof params == 'object'){
        let arr = []  
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                let param_str = key + '=' + params[key] 
                if( !this.includes(param_str) )
                    arr.push( param_str )
            }
            query = arr.join('&')
        }
    }

    return this + sign + query + (rc? rc : '')
}

const url_append = function( params, options = {} ){
    let { url }    = options
    let { origin } = options
    let { path }   = options
    let { search } = options

    origin = origin || window.location.origin
    path   = path   || window.location.pathname
    search = search || window.location.search

    if( url ){
        if( url.includes('?')){
            search = url.split('?')[1]
        }
    }

    let usp = new URLSearchParams( search )
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            let value = params[key]
            usp.set(key, value)
        }
    }

    return `${origin}${path}?${usp.toString()}`
}

const url_reduce = function( params, options = {} ){
    let { url }    = options
    let { origin } = options
    let { path }   = options
    let { search } = options

    origin = origin || window.location.origin
    path   = path   || window.location.pathname
    search = search || window.location.search

    if( url ){
        if( url.includes('?')){
            search = url.split('?')[1]
        }
    }

    let usp = new URLSearchParams( search )
    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            usp.delete(key)
        }
    }

    return `${origin}${path}?${usp.toString()}`
}

const url_has = function( param, options = {} ){
    let { url }    = options
    let { origin } = options
    let { path }   = options
    let { search } = options

    origin = origin || window.location.origin
    path   = path   || window.location.pathname
    search = search || window.location.search

    if( url ){
        if( url.includes('?')){
            search = url.split('?')[1]
        }
    }

    let usp = new URLSearchParams( search )
    return usp.has(param)
}

// sollte NICHT ausgebaut werden, da man vor dem exists nicht weiß, welches flavor vorliegt
const getTemplateName = function () {
    return window.getTemplateName()
}


const getQrid = function () {
    let qrid = window.getURLParameter('qrid')
    let path = window.location.pathname.split('/')
    let qrpath = path.length > 2 ? path[2] : path[1]

    qrid = decodeURI(qrid ? qrid : qrpath)
    if(!qrid){  
        window.showError('Missing QR Code ID')
        if( this )
            this.error = true
    }
    return qrid
}


const setup = function () {
    return getTemplateName().includes('setup')
}

const intro = function () {
    return getTemplateName().substr(2).includes('intro')
}

const getUIScale = function () {
    // let ua_mobile = navigator.userAgent.match(/mobile/i)
    let size = HTML_SCALE_DESKTOP
    
    if( isMobile /* || ua_mobile */ ){
        if( isAndroid ){
            size = HTML_SCALE_ANDROID
        }else{
            size = HTML_SCALE_MOBILE
        }
    }

    let ua_iPad = navigator.userAgent.match(/iPad/i)
    if( ua_iPad ) size = HTML_SCALE_IPAD

    return size
}

const setUIScale = function () {
    setTimeout(() => {

        document.getElementById('html').style.fontSize = getUIScale()
    }, 1000/30)
}

const setCSSValues = function () {
    let device = isMobile ? 'mobile' : 'desktop'
    let orientation = (window.innerWidth / window.innerHeight) > 4/3 && !isMobile? '_landscape' : ''
    let style = device + orientation

        window.customCss = {
            rootFontSize: `var(--${style}_root_font_size)`,
            fontSize: `var(--${style}_font_size)`,
            fontSizeBig: `var(--${style}_font_size_big)`,
            fontSizeInput: `var(--${style}_font_size_input)`,
            fontSizeHeader: `var(--${style}_font_size_header)`,
            lineHeight: `var(--${style}_line_height)`,
            padding: `var(--${style}_padding)`,
            paddingInput: `var(--${style}_padding_input)`,
            rootOffsetTop: `var(--${style}_root_offset_top)`,
            fontSizeAgb: `var(--${style}_font_size_agb)`,
            arrowMargin: `var(--${style}_arrow_margin)`
        }
}


class App extends Component {
    constructor(props, context) {
        super(props, context) // not deprecated, es lint error(?)

        window.appjs = this
        this.error = false
        this.blueErrorPage = {}
        setCSSValues()
        setUIScale()
        window.addEventListener("resize", function(e) {
            setCSSValues()
            setUIScale()
            window.appjs.forceUpdate()
        }, false);

        

        this._componentDidMount = false
        this.renderData = null
        this.firstElement = null
        this.renderSavedInstance = false
        this.rundgangStartClass = 'greenBtn' // TODO: noch in Benutzung?
        this.skipFirstRender = true
        this.mode = { INTRO: intro(), SETUP: setup(), CHECKLIST: window.getURLParameter('mode') === 'checklist'  }
        this.type = this.matchUqrcType( window.getTemplateName().substr(0, 2) ) // sollte NICHT ausgebaut werden, da man vor dem exists nicht weiß, welches flavor vorliegt
        console.log('window.getTemplateName().substr(0, 2) %o', window.getTemplateName().substr(0, 2))
        this.lang = window.getURLParameter('lang') || ''
        this.permid = {}

        this.jwt = this.getJwtFromUrlParameter('idtoken')
        this.idtoken = this.jwt.string 
        this.authorized = this.jwt.alive()

	    this.trayid =  window.getURLParameter('trayid')
        
        this.uqrcServer = NGROK_SERVER && NGROKHOST ? NGROK_SERVER : HOST + (LOCALDEV ? ':5000' : '/helper')
        
        this.http = window.location.protocol 
        this.helper = this.http + '//' + this.uqrcServer // + flavor, wenn subdomäne === "uq" exakt ist
        
        /* 
            server-side error logging
        */
        server.init( this )
        this.server = window.server

        /* 
            url patams
        */
        this.editSetup              = window.getURLParameter('edit')        === 'setup'
        this.modeChecklist          = window.getURLParameter('mode')        === 'checklist'
        this.fromCookieIgnore       = window.getURLParameter('fromcookie')  === 'ignore'
        this.showPreregistration    = window.getURLParameter('show')        === 'preregistration' 
        this.displayPreview         = window.getURLParameter('display')     === 'preview'
        this.forceParentID          = window.getURLParameter('parentid')
        this.headerid               = window.getURLParameter('headerid')
        this.useCheckliste          = window.getURLParameter('usecheckliste')
        this.localtemplate          = window.getURLParameter('localtemplate')
        this.edpPrefix = null

        this.template = window.getTemplateName()
        this.pid = getQrid()
        this.setFunctionalCookies()

        // let QRloc      = decodeURI(window.getURLParameter('location'))
        this.validated = false // TODO: ?: QRloc.includes(this.pid) && QRloc.length === this.pid.length
        this.validatedAccess = false
        this.overrideValidatedAccess = false // null | false | true
        this.headerElements = []
        this.cookie = { name : null, value: {}, complete: {} }
        this.delete_after = 0
        this.table = parseInt( window.getURLParameter('t') )
        this.confirmbuttons = [/* {
            type:"confirmbutton",
            id:"confirmbutotn1",
            name:"Rechnung bitte",
            subject:"#CALL:Rechnung <von Gast %cdap00%> < an (Tisch Nr. %cdap02%)>",
            body:""
        },{
            type:"confirmbutton",
            id:"confirmbutotn2",
            name:"Nachtisch bitte",
            subject:"#CALL:Nachtisch <von Gast %cdap00%> < an (Tisch Nr. %cdap02%)>",
            body:""
        },{
            type:"confirmbutton",
            id:"confirmbutotn3",
            name:"Wunsch bitte",
            subject:"#CALL:Wunsch <von Gast %cdap00%> < an (Tisch Nr. %cdap02%)>",
            body:""
        }*/]
        this.confirmbuttons[ window.LOGOUT ] = [
            /* {
                type:"confirmbutton",
                id:"confirmbutotn1nn",
                name:"Rechnung bitte NN",
                subject:"#CALL:NN-Rechnung <von Gast %cdap00%> < an (Tisch Nr. %cdap02%)>",
                body:"",
                request: "/messagereply",
            } */
        ]
        this.confirmbuttons[ window.STARTPAGE ] = [
            /* {
                type:"confirmbutton",
                id:"confirmbutotnLuca1",
                name:"Mit der Luca-App einchecken",
                subject:"#CALL: Checkin mi Luca-App Tisch: %urlparam:t%", // das selbe wie bei postmessae2edp
                body:"",

                href: "https://app.luca-app.de/scanner/cam/37c0fd92-62b6-4a94-b80a-977883ccb17a", 
                target: "_blank",
                /// request: "/message", // wird nicht gebraucht
                render: "startpage"
            } */
        ]

        this.send = this.send.bind(this)
        this.JSONtoHTML = this.JSONtoHTML.bind(this)
        this.HTMLtoJSON = this.HTMLtoJSON.bind(this)
        this.setImagePath = this.setImagePath.bind(this)
        // this.setImageRef = this.setImageRef.bind(this)
        this.checklistFinished = this.checklistFinished.bind(this)
        this.inputKeyUpHandler = this.inputKeyUpHandler.bind(this)
        this.mwsCreateEDPObject = this.mwsCreateEDPObject.bind(this)
        this.activateVerifiedBtn = this.activateVerifiedBtn.bind(this)
        /* this.toggledCollapse = this.toggledCollapse.bind(this) */
        this.validate = this.validate.bind(this)
        this.setValidated = this.setValidated.bind(this)
        this.save_intermediate = this.save_intermediate.bind(this)
        this.uploadPhotos = this.uploadPhotos.bind(this)
        this.documentPermid = null
        this.createMode = null

        // speichern button on|off
        this.initialSubmitState = true
        this.state = {
            showSubmit: this.initialSubmitState
        }

        this.rows = []
        this.images = []
        this.inputs = []
        this.sections = []
        this.signatures = []
        this.submits = []
        this.imageRefs = []
        this.fileTypes = []
        this.allModifiers = []
        this.postmessage2edp = null

        this.cookiesAllowed = this.getCookie('cookiesAllowed') !== 'false'
        let msgid_cookie = this.getCookie('message_parent_id_' + this.pid, 'App.js constructor' )
        this.message_parent_id = msgid_cookie ? msgid_cookie.split(':')[1] : null
        this.createnotification = null
        this.logoutmessage = {}
        this.showCookieCheckbox = true

        this.setDocNoteRef = (i, t) => {
            this.DocNoteRef = t
        }

        this.setLocationNoteRef = (i, t) => {
            this.LocationNoteRef = t
        }

        this.seNoChangeRightsRef = (t) => {
            this.noChangeRightsRef = t
        }

        this.DocumentNoteElem = <Note content='Dokumenten-Funktion bald verfügbar!' setNoteRef={this.setDocNoteRef} />
        this.LocationNoteElem = <Warning content='Falscher Standort!' setNoteRef={this.setLocationNoteRef} />
        this.noChangeRights = <Warning content='Änderung nicht möglich. Telefonnummer passt nicht zum Code.' setNoteRef={(t) => this.seNoChangeRightsRef(t)} />

        Exists.match_mode( this )

        /* this.setRowRef = (key, e) => {
          this.rows[key] = e
        } */


        /* 
            -------- constructor hilfs-funktionen: ---------
        */
        this.setImageRef = (key, e, fileType) => {
            this.imageRefs[key] = e

            if (fileType)
                this.fileTypes[key] = fileType
        }

        this.resetImageRef = (key) => {
            delete this.imageRefs[key]
        }

        this.setStartButtonRef = (e) => {
            this.startButton = e
        }

        this.addCookieCheckboxRef = (e) => {
            if(!this.cookieCheckboxRefs)
                this.cookieCheckboxRefs = []
            
            this.cookieCheckboxRefs.push(e)
        }

        this.closeCurrent = (id) => {
            let self = this
            setTimeout(function () {
                if (self.rows[id].state.isOpen)
                    self.rows[id].toggleOpen()
            }, 30)
        }


        this.closeOthers = (id) => {
            setTimeout(function () {
                for (var key in this.rows) {
                    if (this.rows.hasOwnProperty(key)) {
                        let k = key * 1 // str2int
                        if (k !== id && this.rows[k] && this.rows[k].state.isOpen) {
                            this.rows[k].toggleOpen()
                        }
                    }
                }
            }.bind(this), 40)
        }


        this.openNext = (id, stay_opened, keep_position) => {
            let i = id + 1

            /* 
                nicht zu nächster option wechseln,
                wenn selbst zuvor schon ausgewöhlt gewesen
            */
            /* let { choice } = this.rows[id].state
            if ( choice )
                return */

            if (!this.rows[i])
                i++

            if (!this.rows[i])
                return

            setTimeout(function () {
                if (!this.rows[i].state.isOpen) // !null
                    this.rows[i].toggleOpen( stay_opened, keep_position )
            }.bind(this), 50)
        }


        this.getNext = ( id ) => {
            let i = id + 1

            if (!this.rows[i])
                i++

            if (!this.rows[i])
                return

            return this.rows[i]
        }


        this.deleteElement = ( index ) => {
            this.renderData.splice( index, 1 )
            this.allModifiers.splice( index, 1 )
        }
    }

    setHeaderIconUrl(icon){
        //neverused// var headerIconUrl = icon
    }

    url_append(...args){
        return url_append(...args)
    }

    url_reduce(...args){
        return url_reduce(...args)
    }

    url_has(...args){
        return url_has(...args)
    }

    setFunctionalCookies(){
        // url-param usecheckliste
        if( this.useCheckliste )
            this.setCookie('usecheckliste_dvid_' + this.pid, this.useCheckliste, 180)
    }

    setDocTitleAndFavicon() {
        let link = document.querySelector("link[rel*='icon']") || document.createElement('link')
        link.type = 'image/x-icon'
        link.rel = 'shortcut icon'

        let meta = [document.title, link.href]
        if (AI) meta = ['A-Ident', '/aifavicon.ico']
        if (AD) meta = ['A-Dok', '/aifavicon.ico']
        if (BI) meta = ['Boehringer', '/bi_fav.png']
        if (WD) meta = ['WhatsDown', '/favicon.ico']
        if (VB) meta = ['1stad', '/vbfavicon.ico']
        if (CDA) meta = ['cda', '/favicon.ico']
        if (CDAP) meta = ['cdap', '/cdap/favicon.ico']
        if (CAAC) meta = ['cdap', '/cdap/favicon.ico']
        if (OMA) meta = ['cdap', '/favicon.ico']

        let is_dhc = window.location.pathname.split('/').includes('dhc')
        if (DHC || is_dhc ) meta = ['SwissOneMed', '/swiss_favicon.ico']

        if( this.favicon ){
            meta[1] = this.favicon
        }

        //todo vbicon (1stad) - weisse kreuz auf grünem grund 00855a
        document.title = meta[0] + ' (' + this.pid + ')'
        link.href = meta[1]

        document.getElementsByTagName('head')[0].appendChild(link)
        return true
    }



    /* async initFromAppOrServer() {
        //instanz noch nicht vorhanden - instanz gemäss template initialisieren
        if (!window.mag_webkit ) {
            return await this.initfromserver()
        } else { // TODO Android
            //Setup initialisiert sich nie von der APP, daher hier im Moment (050719) keine Android-Variante erforderlich
            //am besten in der config eine kommagetrennte liste aufnehmen, welche Templates lokale Templates sind
            //noch besser: Die App weiss, ob sie ein template hat und gibt ansonsten ein leeres json z.b.
            //{"template":"local"} zurück
            //return await this.initfromapp() //TODO: Irgedwann mal den doch noch möglichen fehlerfall, dass von der app 0 zurückkommt abfangen
            return await this.initfromserver()
            // try catch, error page
        }
        // Idee evt. die maksierte Telefonnummer anzeigen, so dass man eine idee bekommt, welche es sein könnte die ersten 3-4 (0172.../die letzen 3 stellen)
        //this.forceUpdate()

    } */

    copyModeToGlobalVars(){
        LOADING = this.mode.LOADING
        INTRO = this.mode.INTRO
        SETUP = this.mode.SETUP
        VIEW = this.mode.VIEW
        EDIT = this.mode.EDIT
        CHECKLIST = this.mode.CHECKLIST
        PREREG = this.mode.PREREG
        SETUP_SUCCESS = this.mode.SETUP_SUCCESS
        RENDER_DATA_IN_EXISTS = this.mode.RENDER_DATA_IN_EXISTS
        LOGGED_IN = this.mode.LOGGED_IN
        SHOW_DOCUMENTS = this.mode.SHOW_DOCUMENTS
        NO_RIGHTS = this.mode.NO_RIGHTS
        EXISTS = this.mode.EXISTS
        MENU = this.mode.MENU
        PROTOCOL = this.mode.PROTOCOL
        EINLASSKONTROLLE = this.mode.EINLASSKONTROLLE
    }

    copyTypeToGlobalVars(){
        BI = this.type.BI
        AI = this.type.AI
        AD = this.type.AD
        VB = this.type.VB
        WD = this.type.WD
        WDF = this.type.WDF
        CDA = this.type.CDA
        DHC = this.type.DHC
        CDAP = this.type.CDAP
        CAAC = this.type.CAAC
        OMA = this.type.OMA
        EAG = this.type.EAG
        SERVICE = this.type.SERVICE
        USER = this.type.USER
    }


    getJwtFromUrlParameter = function (pname) {
        let token = window.getURLParameter(pname || 'idtoken')
        let json = new JWT({ exp: 0, string: '', parse_error: false }) // init
        
        if (!token){
            return json
        }

        try {
            var base64Url = token.split('.')[1]
            var base64 = base64Url.replace('-', '+').replace('_', '/')
            json = new JWT(JSON.parse(window.atob(base64)))
        } catch (error) {
            console.error('Invalid JWT Token. ' + error)
            json.parse_error = true
            json.string = token
            return json
        }
        
        json.string = token
        // if (json.expired()) window.showError('Token timed out')
        if (json.invalid()){
            window.showError('Invalid token')
        }
        
        return json
    }


    login_block(){
        let showLoginAgain = this.getCookie('showLoginAgain_' + this.pid) === 'true'
        if( showLoginAgain )
            return ( Date.now() - this.getCookie('showLoginAgain_' + this.pid, 'timestamp' ) < window.LOGIN_AGAIN_TIMEOUT )
        else
            return false
    }


    login_refuse(){
        let stop = this.getCookie('message_checkoutparent_id_' + this.pid)
        if( stop ){
            let timestamp = this.getCookie('message_checkoutparent_id_' + this.pid, 'timestamp' )
            let timeout = Date.now() - timestamp
            return stop.includes('STOP') && ( timeout < window.LOGIN_AGAIN_TIMEOUT )
        }else{
            return false
        }    
    }


    hide_login(){
        return this.login_block() || this.login_refuse()
    }


    return_to_start(){
        return this.modeChecklist && this.login_refuse() 
    }


    componentDidUpdate() {
        this.setDocTitleAndFavicon()
    }


    async componentDidMount() {
        if( this.error )
            return null

        if( this.return_to_start() )
            window.location.href = window.location.href.replace('mode=checklist','')

        try {
            
            await Exists.execute(this) 
        } catch (error) {
            console.log(error)
        }

        this.redirectAD2login()
        
        if( NO_RIGHTS )
            this.noRigthsPage(Exists.parsed.extension)

        window.hideLoader()
        this.forceUpdate()
    }


    setBrowserPageTitle(t) {
        document.title = t ? t : document.title + ' (' + this.pid + ')'
    }

    // sucht daten-feld 'field' aus erstem elment von typ 'type'
    getRenderDataElement(type, field, id) {
        if(!this.renderData)
             return ''

        if( SETUP_SUCCESS || !CHECKLIST )
        {
            let filled_rD = this.localStorage_getItem('filled_renderData_' + this.pid)
            if( filled_rD )
                try {
                    this.renderData = JSON.parse( filled_rD )
                } catch (error) {
                    window.showError('Failed to obtain values from saved renderData.')
                }
        }

        let ret = ''

        let json = JSON.parse(JSON.stringify(this.renderData)) 
        if (json){
            try {
                json.some(function (el) {
                    if(id){
                        // console.log('getRenderDataElement id ', id)
                        if (el.id === id) {
                            ret = el[field]
                            return true // stop iteration
                        } else
                            return false
                    } else {
                        if (el.type === type) {
                            if(field)
                                ret = el[field]
                            else
                                ret = el

                            return true // stop iteration
                        } else
                            return false
                    }
                })
            } catch (error) {}
        }
        return ret
    }

    // cdap fragebogen durchsuchen und auswerten
    questionnaireAccepted() {
        if(!this.renderData) //das verstehe ich nicht, wie kann das generelle renderdata hier abgefragt werden? es muss doch renderdata auf das frage-element abgefragt werden s.u. nächstes if was ich AH 3009 reingemacht habe jo hat gepasst
            return true

        let app = this
        // hilfsfunktionen
        // abweisungs-gründe für setup success page
        let setReason = ( r ) => {
            if( !app.reasons ){
                app.reasons = {}
            }

            if( !app.reasons[ r.group ] ){
                app.reasons[ r.group ] = r
            }else{
                let new_prio = parseInt(r.priority)
                let old_prio = parseInt(app.reasons[ r.group ].priority)
                if( new_prio > old_prio ){
                    app.reasons[ r.group ] = r
                }
            }
        }

        // anweisungen für setup success page
        let setPassed = ( p ) => {
            if( !app.passed ){
                app.passed = {}
            }

            if( !app.passed[ p.group ] ){
                app.passed[ p.group ] = p
            }else{
                let new_prio = parseInt(p.priority)
                let old_prio = parseInt(app.passed[ p.group ].priority)
                if( new_prio > old_prio ){
                    app.passed[ p.group ] = p
                }
            }
        }

        // alt (höchstens bei 8qqpsm aktiv)
        let gesundheitsfragen = () => {
            return (
                document.getElementById('frage01') ||
                document.getElementById('frage_3g1')
            )
        }

        // neu
        let gesundheitsfragenV2 = () => {
            let falsche_antworten = 0

            for (let i = 0; i < this.renderData.length; i++) {
                const e = this.renderData[i]

                let falsche_antwort = e.value_ok && !e.value_ok.includes( e.value )
                let falsches_datum  = e.date_validity === false
                let is_required     = e.required && e.required.match(/1|true/)
                let is_date         = e.pattern === 'date'
                let is_select       = e.type === 'select' && e.value_ok !== undefined
                let is_frage        = e.type === 'checkit' && e.id && e.id.match(/^frage/)

                if( is_required && (is_frage || is_select || is_date) ){

                    if( falsche_antwort || falsches_datum ){
                        falsche_antworten++

                        if( e.reason ){
                            setReason( e.reason )
                        }
                    }else{
                        if( e.passed ){
                            if( Array.isArray( e.passed ) ){
                                for (let p = 0; p < e.passed.length; p++) {
                                    const pass = e.passed[p]
                                    
                                    if( pass.value === e.value ){
                                        setPassed( pass )
                                    }
                                }
                            }else{
                                setPassed( e.passed )
                            }
                        }
                    }                
                }
            }

            if( app.reasons ){
                window.localStorage.setItem('reasons', JSON.stringify(app.reasons) )
            }

            if( app.passed ){
                window.localStorage.setItem('passed', JSON.stringify(app.passed) )
            }

            if( falsche_antworten > 0 ){
                return false
            }else{
                return true
            }
        }

        if( this.pid !== '8qqpsm' ){
            return gesundheitsfragenV2()
        }else{

            // kompatibilität alte jsons: nur 8qqpsm
            if ( !gesundheitsfragen() ) 
                return true
    
            let ret = true // accepted
    
            let json = global.clone(this.renderData)
            if (json){
                json.some(function (el) {
                    let { 
                        id, /* element id */
                        value, /* gesetzer wert */
                        value_ok, /* zu akzeptierender wert */
                        vals /* ja/nein-button-werte */
                    } = el
    
                    if ( id && id.match(/^frage[_]*[0-9]+$/) ){
                        // value_ok="nein"
                        // if value_ok im json gesetzt dann value_ok= wert aus json
                        if( value_ok ){
                            
    
                            if( value && value === value_ok/* accepted_value */ ){
                                // solange value_ok, bleibt ret=true (accepted)
                                return false // next element
                            }else{
                                ret = false
                                return true // stop iteration
                            }
                        }else{
                            /* legacy: vor 21.06.2021 */
                            if( value && value.match(/^nein$|^no$|0/) ){
                                // cdap-fragen: 'nein' war "gut", ret wäre true geblieben, antworten wären akzeptiert worden
                                return false // next element
                            }else{
                                ret = false
                                return true // stop iteration
                            }
                        }
                    }
                    return false
                })
    
                
                let ret_3g = false // accepted
                json.some(function (el) {
                    let { 
                        id, /* element id */
                        value, /* gesetzer wert */
                        value_ok, /* zu akzeptierender wert */
                        vals /* ja/nein-button-werte */
                    } = el
    
                    if( id && id.match(/^frage_3g[0-9]$/) ){
                        if( value === value_ok ){
                            ret_3g = true
                        }
                    }
                })
    
                if( !ret_3g ){
                    ret = false
                }
            }
    
            return ret
        }
    }



    getElementPosition(id, type) {
        if(!id && !type)
            if(this.renderData)
                return this.renderData.length
            else
                return 0
        
        let position = -1
        let json = this.renderData

        if (json){
            try {
                json.some(function (el) {
                    position ++

                    if (!id && el.type === type) return true
                    if (el.id === id && el.type === type) return true
                    if (el.id === id && !type) return true  

                    return false
                })
            } catch (error) {}
        }
        return position
    }



    getLastElementPosition(type, eclass) {
        if(!type)
            if(this.renderData)
                return this.renderData.length
            else
                return 0
        
        let last = -1
        let position = -1
        let json = this.renderData

        if (json){
            try {
                json.some(function (el) {
                    position++
                    
                        if (el.type === type && el.elementClass === eclass )
                            last = position
                   
                })
            } catch (error) {}
        }
        return last
    }


    joinObjectParams(json, params) {
        if (params) {
            let { title, dvid } = typeof params === 'string' ? JSON.parse(params) : params
            if (title && dvid)
                json.some(function (el) {
                    if (el.type === 'introduction') {
                        el.name = title
                        el.dvid = dvid
                        return true // stop execution
                    }
                    return false
                })
        }
        return json
    }


/*     async initfromapp() {
        let result = await window.readStringFromFile('')
        let json = JSON.parse(result)
        let params = await window.getobjectparameters()

        return this.joinObjectParams(json, params)
    } */


    // 260322: initfromserver und /tmpl wird nicht mehr benutzt
    /* async initfromserver() {
        if( this.template ) {
            const tmpl_str = await fetch(
                this.helper + 
                '/tmpl?tmpl=' + this.template + 
                '&parentid=' + this.permid.PARENT + // für objinfov2
                '&service=' + SERVICE + 
                '&qrid=' + this.pid + 
                '&lang=' + window.getURLParameter('lang') + 
                '&setupType=' + window.getURLParameter('setupType'))

            const tmpl_json = await tmpl_str.json() 
            return tmpl_json
        } else {
            return {}
        }

    }
 */

    async get_last_update( qrid ) {
        const str = await fetch( this.helper + '/last_update?qrid=' + qrid )
        const json = await str.json()
        return json
    }



    store_last_update(lu){
        let { last_update, timestamp } = lu
        this.localStorage_setItem('last_update_' + this.pid, JSON.stringify({ last_update, timestamp }))
    }

    get_stored_last_update(){
        return JSON.parse( this.localStorage_getItem('last_update_' + this.pid ))
    }


    showLoader( el ) {
        if( el )
        {
            let bb = el.getBoundingClientRect()
            document.querySelector('#loader').style.top = (bb.top + bb.height/2) + 'px'
        }

        document.querySelector('#loader').style.display = 'block'
        return true
    }


    urlParamsAsJSON(){
        let wlss = window.location.search.substring(1)
        if (!wlss)
            return {}

        let str = decodeURIComponent( wlss )
        str = str.split('&').map((p)=>{
            return p.includes('=') ? p : (p + '=')  //&rc hat kein "="
        })
        str = str.join('&').replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"')
        str = '{"' + str  + '"}'
        
        try {
            return JSON.parse(str)
        } catch (error) {
            return { failedToParse: str }
        }
    }


    /* async */ getToken() {
        /* if (window.mag_webkit) return await window.requestAK()
        else  */
        if (isAndroid && typeof window.HTMLOUT !== 'undefined'){
            return window.HTMLOUT.getIdToken()
        }
        return this.idtoken
    }

    async post(path, data = {}) {


	    let edp_prefix=this.edpPrefix; 
	    if (this.edpPrefix  == '' || this.edpPrefix == null)
	    {
			    if (AI) edp_prefix="aident"; 
	}
    
            data.qrid = this.pid
            data.token = /* await */ this.getToken()
            data.clientURL = window.location.href
            data.clientQueryParams = this.urlParamsAsJSON()
            data.privacy = window.getURLParameter('privacy')
            data.localtemplate = this.localtemplate
            data.template = this.template
            data.checklist = CHECKLIST
            data.type = edp_prefix
            data.mode = this.mode
            data.lang = this.lana
	    

            if( path === '/create' )
                data.renderData = this.renderData

            if( !data.clientQueryParams.usecheckliste )
            {
                let chf = window.getURLParameter('cache') === 'false'
                let ucc = this.getCookie('usecheckliste_dvid_' + this.pid)
                if( ucc && !chf )
                {
                    data.clientQueryParams.usecheckliste = ucc
                }
            }

            let self = this
            return await new Promise(async (resolve) => {
                if( mws_conf.GRECAPTCHA === 'off'){
                    post_global_options.body = JSON.stringify(data)
                    let post_repsonse = await fetch(self.helper + path, post_global_options)
                    return resolve(post_repsonse)
                }

                let interval_count = 0
                let p_interval = setInterval( async () => {
                    if( window.grecaptcha && window.grecaptcha.execute ){
                        clearInterval(p_interval)

                        window.grecaptcha.execute( GCCAPTCHA_SITEKEY, {action: 'homepage'})
                        .then( async function(token)
                        {
                            data.gcaptchatoken = token
                            self.gcaptchatoken = token
                            post_global_options.body = JSON.stringify(data)

                            let post_repsonse = await fetch(self.helper + path, post_global_options)
                            resolve(post_repsonse)
                        }, function(reason){
                            console.log(reason)
                            window.showError('Es tut uns leid. Es ist ein Verbindungsproblem aufgetreten: Google Recaptcha ist fehlgeschlagen. Bitte versuchen Sie es erneut, oder laden Sie diese Seite neu.')
                            self.post_error({ ursprung: 'App.js (Zeile 1295)', grund: 'grecaptcha.execute error', error: reason })
                        })
                    }else{
                        interval_count++
                        if( interval_count > 400 ){
                            // stop trying
                            clearInterval(p_interval)
                        }
                        console.log('no window.recaptcha')
                    }
                }, 30)
            })
    }


    async post_error( err ){
        let self = this
        return await new Promise(async (resolve) => {

            let data = {
                qrid: this.pid,
                url: window.location.href,
                cookieEnabled: navigator.cookieEnabled,
                error: err,
                cookies: document.cookie,
                deviceDetect: deviceDetect()
            }

            post_global_options.body = JSON.stringify( data )
            let r = await fetch( self.helper + '/error', post_global_options)
            console.log( 'post_error', r )
            resolve( r )
        })
    }


    async requestGCaptcha(self, opts){
            return await new Promise(async (resolve) => {
                if( mws_conf.GRECAPTCHA === 'off'){
                    return resolve('off')
                }

                let p_interval = setInterval( async () => {
                    if( window.grecaptcha && window.grecaptcha.execute ){
                        clearInterval(p_interval)

                        window.grecaptcha.execute( GCCAPTCHA_SITEKEY, {action: 'homepage'}).then( async function(token) {
                            self.gcaptchatoken = token
                            resolve( token )
                        })
                    }
                }, 10)
            })
    }


    readUqrcType( header_id ){
        let urltype = window.getURLParameter('type')
        if( urltype )
            return urltype
            
        if( header_id )
            this.headerid = header_id
        
        return this.headerid || ''
    }


    updateUqrcType( header_id ) {
        return this.matchUqrcType( header_id )
    }


    matchUqrcType( header_id ) {
        
        let t = this.readUqrcType( header_id )
        if( !t )
            return this.types
        
        let edp_prefix = (this.getRenderDataElement('header', 'edpPrefix') || '').toLocaleLowerCase()

        let types = {
            BI: t.match(/^boe$|boehringer|^bi$|header/) !== null,
            AI: t.match(/aident|a-ident|^ai$/) !== null,  
            AD: t.match(/adok|a-dok|^ad$/) !== null, 
            VB: t.match(/^vb|digsievb|1stad/) !== null,
            DHC: t.match(/^dhc$/) !== null,
            WD: t.match(/^whatsdown$|^wd$|^uqwd$|wdwachendorff/) !== null,
            WDF: t.match(/^uqwd$|^wdwachendorff/) !== null,
            CDA: t.match(/^cda$/) !== null && edp_prefix !== "caac",   
            CDAP: t.match(/^cdap$/) !== null,  
            OMA: t.match(/^oma|oma$/) !== null,
            CAAC: ( t.match(/^cda$/) !== null  && edp_prefix === "caac" ) || t.match(/^caac$/)  !== null ,  
            EAG: t.match(/wdeag|eagwd/) !== null,  
            SERVICE: 'default',
            USER: 'default'
        }

        if (types.BI) types.SERVICE = 'bi'
        if (types.AD) types.SERVICE = 'a-dok'
        if (types.AI) types.SERVICE = 'a-ident'
        if (types.WD) types.SERVICE = 'whatsdown'
        if (types.VB) types.SERVICE = '1stad'
        if (types.EAG) types.SERVICE = 'wdeag'
        if (types.CDA) types.SERVICE = 'cda'
        if (types.CDAP) types.SERVICE = 'cdap'
        if (types.CAAC) types.SERVICE = 'caac'
        if (types.DHC) types.SERVICE = 'dhc'
        if (types.OMA) types.SERVICE = 'oma'

	    if (edp_prefix == '')
	    {
		    if (types.AI) edp_prefix="aident"; 
	    }

        this.type = types
        this.copyTypeToGlobalVars()
        return types
    }


    // nur ad & vb
    redirectAD2login() {
        if ( AD && (VIEW || EDIT)){
            // view-blitz-workaround
            this.dont_render = true 
            let redirect = _URL.AD_FBAUTH + '?service=a-dok&qrid=' + this.pid + '&user=' + this.getRenderDataElement('text', 'value') + '&idtoken=' + this.idtoken
            window.location.href = redirect
        }else{
            this._componentDidMount = true
        }

        // todo 1602: wenn in json ein element AUTH VORHANDEN IST, DANN WIRD ZUM CHILID  "AUTHURL" dann wird hier zur authurl weitergeleitet
        // alternativ steht im JSON ein zu vewrendetnder defaultname (z.b. system_wdwebui) drin, der an den server weitergereicht wird
        // der server weiss dann zu diesem user das passsowrt (oder er kennt den user nicht, dann weist er den request ab)
        // und noch mehr in der zukunft müssen wir das webhook-konzept implementieren, wo der server aufgrund der id den passenden webhook-api-key auswählt, wie genau müssen wir überlegen
    }



    noRigthsPage(extension) {
        // besser in json/template auslagern und per /get abholen ? 
        // if ((!this.createMode && !this.permid.DOCUMENT) || exists.code === 500) {
        this.noEditRights = true

        let header = {
            id: extension,
            name: 'unset'
        }

        if (AI) header = { id: 'ai', name: 'Wichtig! Immer dabei, mit A-Ident.' }
        if (AD) header = { id: 'ad', name: 'Immer dabei, mit A-Dok.' }
        if (BI) header = { id: 'bi', name: 'Boehringer' }
        if (WD) header = { id: 'wd', name: 'WhatsDown' }
        if (VB) header = { id: 'vb', name: '1stad' }
        if (CDA) header = { id: 'cda', name: 'Desinfektions-Assistent' }
        if (CDAP) header = { id: 'cdap', name: 'Corona-Anwesenheitsliste' }
        if (CAAC) header = { id: 'cdap', name: 'Corona-Anwesenheitsliste' }
        if (OMA) header = { id: 'oma', name: 'Online Management of Attendance' }
        if (DHC) header = { id: 'dhc', name: 'SwissOneMed' }

        this.renderData = [ // TODO REFACTOR FÜR ALLE FLAVORS
            {
                "id": header.id,
                "name": header.name,
                "type": "header"
            }, {
                "id": "intro",
                "name": "",
                "text": "",
                "aident": "Keine Änderungsrechte zur ID ",
                "type": "introduction"
            }, {
                "id": "noaccess",
                "name": "",
                "text": "Die verwendete Telefonnummer gehört nicht zu diesem Etikett. ",
                "label": "Hinweis: ",
                "css": { /* "border": "1px solid gray", "backgroundColor": "white", "color": "#3f3f3f" */ },
                "type": "textbox"
            },
            {
                "type": "html",
                "html": "<a href='https://my-qr.io/" + header.id + "/" + this.pid + "' style='color:#4c4c4c; margin-top: 1vh;display: block;font-weight: normal;text-align: center; padding: 30px; border-radius: 100vh; background-color: hsla(0, 0%, 82%, 1);'>Mit zugehöriger Telefonnummer einloggen</a>"
            }/* ,{ //debugging
                "type":"postmessage2edp",
                "subject":"Betreff %deviceName%",
                "body":"Benutzer %cdap00% hat sich in die Liste mit der Telefonnummer %cdap01% eingetragen"
            } */
        ]
        // }
    }

    
    async jsonToEdp(xymode, permid, phonenumber, type, renderData ) {

            let teilnehmerid = this.getRenderDataElement('text', 'value', 'teilnehmerqrid') || ''
            let post2edp = !(this.getRenderDataElement('header', 'post2edp') === 'false') // default wegen backwards compatibility true

            // wenn pot2edppermid=xxxx gesetzt ist, dann den post2edp an die overridpremid machen 
            let override_permid = window.getURLParameter('post2edppermid')
            if( override_permid )
                permid = override_permid.replace('_','')


            if( post2edp ){
	    
		   let edp_prefix = this.edpPrefix; 
		    if (edp_prefix == '' || edp_prefix == null)
		    {
			    if (AI) edp_prefix="aident"; 
		    }
console.log("1623",type,"::",edp_prefix); 


                let opts = {
                    permid: permid,
                    data: JSON.stringify(renderData || this.renderData),
                    phone: phonenumber,
                    xymode: xymode, 
                    type: type || edp_prefix,
                    qrid: this.pid, 
                    pid: this.pid,
                    versionstring: teilnehmerid,
                    description: this.object_description(),
                    token: this.getToken()
                }
                
                let self = this
                await this.post('/set', opts) // hier kommt eine id zurück, die in die preregemail anstatt der parent id rein muss
                .then(async function (response) {
                    await response.text().then(
                        async function (response) {
    
                            let _json = {}
                            try {
                                _json = JSON.parse(response)
                            } catch (e) {
                                window.showError('jsonToEdp /set: ' + e)
                                return false
                            }

                            if( _json.response &&
                                _json.response.success &&
                                _json.response.success['dvid'] ){
                                
                                self.protocol_permid = _json.response.success['dvid'][0]
                            }

                            return //3009 AH
                        }
                    )
                })
            }
            return
    }


    sleep(milliseconds) {
        const date = Date.now();
        let currentDate = null;
        do {
          currentDate = Date.now();
        } while (currentDate - date < milliseconds);
      }


    try_to_parse( jstr ){
        try {
            return JSON.parse( jstr )
        } catch (parse_error) {
            return { parse_error, jstr }
        }
    }


    errors( j ){
        let e = false
        if( j.parse_error ){
            window.showError( j.parse_error )
            e = true
        }
        
        if ( !e && j.code === 500 ){
            window.showError( j.code )
            e = true
        }
        
        if ( !e && !j.response ){
            window.showError( '!response' )
            e = true
        }

        if ( !e && j.response.result !== 'success' ){
            window.showError( j.response )
            e = true
        }
        
        if ( !e && j.response.failure ){
            window.showError( j.response )
            e = true
        }

        if( e ){
            this.blueErrorPage.display = true
            this.blueErrorPage.text = JSON.stringify( j.response || '!response' )
            window.hideLoader()
        }

        return false
    }

    get_obj_permid( j ){
        let opid = ''
        if( j.response.entries )
                j.response.entries.forEach( e => {
                    if( e.key === 'obj-permid')
                        opid = e.value 
                    }
                )
        return opid
    }

    object_description(){
        // let desc = this.postdescription2edp
        let desc = global.clone(this.postdescription2edp)

        if( !desc || !desc.body )
            return ''

        if( typeof desc === 'string' )      
            desc = this.try_to_parse( desc )

        if( desc.parse_error )      
            return desc
            
        if( desc.body )
            for (const k in desc.body) {
                if (Object.hasOwnProperty.call(desc.body, k)) {
                    desc.body[k] = this.replace_percent( desc.body[k] )
                }
            }

        return JSON.stringify( desc.body )
    }

    
    async mwsCreateEDPObject() {
        const self = this
        let deviceName = document.querySelector('#deviceName')

        await this.post('/create', {
            permid: this.permid.PARENT,
            title: (deviceName ? deviceName.value : this.pid),
            description: this.object_description(),
            flavor: SERVICE
        })
            .then(function (response) {
                response.text().then(
                    async function (response) {

                        let _json = self.try_to_parse( response )

                        if( self.errors(_json))
                            return self.forceUpdate()
 
                        if ( _json.response.result === 'success' ){
                            
                            self.permid.CHILD = self.get_obj_permid( _json )
                            self.displayPreview = true
                            
                            // WDWD (nur message, kein protocol, photos), BOE (alles), AI, CDAP (message ohne fotos, also auch ohne /set)
                            if (self.edpPrefix) {
                                await self.uploadPhotos() 

                                let phone = self.getJwtFromUrlParameter().phone_number

                                await self.jsonToEdp('new', self.permid.CHILD, phone) // früher: if( !CDAP) 
                                    
                                if (CDA) {
                                    await self.post('/welcome', {
                                        permid: self.permid.PARENT,
                                        flavor: SERVICE
                                    })
                                }
                            } else {
                                // WD zweig, message soll ins json
                                await self.post('/welcome', {
                                    permid: self.permid.PARENT,
                                    flavor: SERVICE
                                })
                            }
                            
                            if(self.postmessage2edp)
                                await self.postJsonMessages2EDP(self.postmessage2edp)
                            
                            if(self.directmessage)
                                await self.postJsonMessages2EDP(self.directmessage)

                            if ( CAAC || AD || AI || VB || CDAP || CDA || EAG || WD  || DHC || OMA ){
                                self.display_preview({ from: 'setup' })
                            }else{
                                // BI SETUP
                                SETUP_SUCCESS = true
                                window.hideLoader()
                                self.forceUpdate()
                            }
                        }
                    }
                )
            });
        return true
    }


    get_redirect_url( q_accepted ){

        if( q_accepted && this._redirect.accept )
            return this._redirect.accept

        if( !q_accepted && this._redirect.refuse )
            return this._redirect.refuse

        if( this._redirect.default )
            return this._redirect.default

        return window.location.href
    }


    display_preview( params = {} ){
        
        let q_accepted  = this.questionnaireAccepted()
        let href        = this.get_redirect_url( q_accepted )
        let url         = new URL( href )
        let parentid    = url.searchParams.get('parentid') || this.permid.PARENT

        /* default url params */
        url.searchParams.set( 'display' , 'preview'     )
        url.searchParams.set( 'qrid'    , this.pid      )
        url.searchParams.set( 'parentid', parentid      )
        url.searchParams.set( 'refuse'  , !q_accepted   )

        /* delete or set additional url params */
        for(let key in params){
            if(params.hasOwnProperty(key)){
                let value = params[key]

                if( value ){
                    if( value === 'delete' )
                        url.searchParams.delete( key )
                    else
                        url.searchParams.set( key, value )
                }
            }
        }

        /* redirect */
        window.location.href = url.href
    }



    savePhotoUrl(r, k) {
        k = k.replace('Preview', '')
        this.renderData.map((e, i) => {
            if (e.id !== k)
                return null

            if ((e.type != null) && e.type.match(/photo|document/)) {
                this.renderData[i]['file'] = r.response.success.attachment_url[0]
            }
            return true
        })
    }



    async uploadPhotos( message ) {
        console.log('uploadPhotos() ')
        let t = /* await */ this.getToken()

        for (var k in this.imageRefs) {
            if(this.imageRefs.hasOwnProperty(k)){
                console.log('uploadPhotos() for (var k in this.imageRefs) {')

                let fileType = this.fileTypes[k] ? this.fileTypes[k] : 'noFileType'
                let fileName = 'file.' + fileType

                var formData = new FormDataFrom({
                    'qrid': this.pid,
                    'message': message || k ,
                    'token': t,
                    'filetype': fileType,
                    'file': [this.imageRefs[k], fileName],
                })

                let self = this
                let photo_options = {
                    method: "POST",
                    mode: "cors",
                    cache: "no-cache",
                    body: formData
                }

                let gct = await this.requestGCaptcha(this, photo_options)
                formData.append('gcaptchatoken', gct)
                photo_options.body = formData

                // each photo
                await fetch(
                    this.helper + '/photo',
                    photo_options
                ).then(response => response.json()
                ).then(data => {
                    if (data && data.response && data.response.success.attachment_url) {
                        // console.log('V %s', JSON.stringify(data))
                        self.savePhotoUrl(data, k)
                        
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    window.showError('Ups, irgendwas ist schief gelaufen. Bitte probiere es nochmal oder kontaktiere den Support.')
                    return error
                });
            }
        }

        return true
    }


    test_replace_percent_optional(){
        let str1 = JSON.stringify({"subject":"Betreff _LISTENNAME_","body":"CHECKOUT: %cdap00% (Tel. %cdap01%) hat sich aus der Liste ausgetragen"})
        return {
            test1: this.replace_percent_optional(str1)
        }
    }


    replace_percent(str = '' ){

        let replacedString = str
        str.split(' ').forEach( (word) => {

            let matched =  word.match(/%.*%/)
            if( matched ){

                let variableName = word.match(/%.*%/)[0]
                let elementID = variableName.substring(1,variableName.length-1)

                let val
                switch (elementID) {
                    case 'objectTitle':
                        val = this.getRenderDataElement('urlparams', 'title')
                        break;
                
                    case 'objectTime':
                        val = this.getRenderDataElement('urlparams', 'time')
                        break;
                
                    case 'displayTitle':
                        val = this.displayTitle
                        break;

                    case 'checkbox_test':
                        let val_cb = document.getElementById('checkbox_test').checked 
                        if (val_cb)
                            val = this.getRenderDataElement( null, 'name', elementID)
                            
                        break;

                    default:

                        val = this.getRenderDataElement( null, 'value', elementID)

                        break;
                }
                
                replacedString = replacedString.replace( variableName, val ? val : '')
            }
        })

        return replacedString
    }



    replace_percent_optional(str = '' ){

        var strOut = ''
        var strArr = str.split('<')
        for (let i = 0; i < strArr.length; i++) {
            var str_i = strArr[i]

            var isOptional = strArr[i].indexOf('>') >= 0 && strArr.length > 1
            let replacedString = str_i
            str_i.split(' ').forEach( (word) => {

                let matched =  word.match(/%.*%/)
                if( matched ){

                    let variableName = word.match(/%.*%/)[0]
                    let elementID = variableName.substring(1,variableName.length-1)

                        let val
                        switch (elementID) {
                            case 'objectTitle':
                                val = this.getRenderDataElement('urlparams', 'title')
                                break;
                        
                            case 'objectTime':
                                val = this.getRenderDataElement('urlparams', 'time')
                                break;

                            case 'checkbox_test':
                                // let val_cb = this.getRenderDataElement( null, 'value', elementID)
                                let val_cb = document.getElementById('checkbox_test').checked // this.getRenderDataElement( null, 'value', elementID)
                                console.log('val_cb', val_cb)
                                if (val_cb)
                                    val = this.getRenderDataElement( null, 'name', elementID)

                                console.log('checkbox_test val', val)
                                break;
                        
                            default:

                                val = this.getRenderDataElement( null, 'value', elementID)
                                break;
                        }
                    
                    // 
                    if( val ){
                        replacedString = replacedString.replace( variableName, val)
                        replacedString = isOptional ? replacedString.replace( '>', '') : replacedString
                    } else {
                        replacedString = replacedString.replace( variableName, '') 
                        replacedString = isOptional ? replacedString.substring(replacedString.indexOf('>')+1) : replacedString // TODO: '>' später ersetzen, nicht in if(matched)
                    }
                }
            })

            strOut += replacedString
        } // TODO: '>' hier ersetzen, nicht in if(matched)
        return strOut
    }


    async postJsonMessages2EDP(msg = {}, quick_msg_self_value, modus=false, isQuickmessage){
        
        if( quick_msg_self_value && this.disableMessagePost)
            return null

        let disablePost = modus==='disableTouch'
        if(!this.disableMessagePost && disablePost)
            this.disableMessagePost = true
        
        if(msg === {} || msg === null )
            return null


        //let callMonitor = modus==='callMonitor'
        // soll der call monitor im zweifel an default_destination gehen?
        if( msg && !msg.destination /*  && !callMonitor */ ){
            msg.destination = this.getRenderDataElement( null, 'value', 'default_destination')// || this.getRenderDataElement( null, 'value', 'preregistrationemail')
        }

        let dest_preregemail = ''
        let prereg_field = document.getElementById('preregistrationemail')
        if (prereg_field) {
            msg.destination = this.getRenderDataElement( null, 'value', 'preregistrationemail')
        }

        let absender_field = document.getElementById('absender')
	    if ( !SETUP && (/* msg.destination */ absender_field || prereg_field) ) {
            
            if (absender_field) {
                let popinput_field = document.getElementById('popinput')
                if (popinput_field) popinput_field.value = absender_field.value
            }

            let disablePopup = modus==='callMonitor'
            if(!disablePopup){
                let popup_result = await this.showPopup( msg.display_value || msg.destination ) // display_value bekommt vorrang
                if(!popup_result){
                    // degrayButton(id)
                    this.disableMessagePost = false 
                    return false //todo wartecursor bleibt an und formular geht nicht in grundzustand, ggf. einfach reload mit der url machen
                    // bei cookie=ignore wäre es ärgerlich, komplett zu refreshen (NN)
                }
            }
            // TODO: preregemail === prereg_field (NN06102020)

            //let absender = document.getElementById('absender')
            let preregemail = document.getElementById('preregistrationemail')
            if (prereg_field) {
                if (preregemail && !preregemail.value.trim())
                    preregemail.value = document.getElementById('popinputpreg').value // todo
            } /* else {
                if (absender && !absender.value.trim())
                    absender.value = document.getElementById('popinput').value // todo
            } */
            
            if( prereg_field && preregemail ) {
                dest_preregemail = preregemail.value
            }
        }

        if(quick_msg_self_value && isQuickmessage === 'isQuickmessage')
        {
            for( let i in msg) if(msg[i]) msg[i] = msg[i].replace('%value%', quick_msg_self_value)
        }
            
        let mbody = msg.body || ''
        let subject = msg.subject || ''
        let privacy = msg.privacy || ''
        let destination = msg.destination || ''
        

        mbody = mbody ? this.replace_percent_optional(mbody) : ''
        subject = subject ? this.replace_percent_optional(subject) : ''

        let all_destinations = this.replace_percent(destination).getAllDestinations()
        let prime_destinations = this.replace_percent(destination).getAllDestinations('<>')
        destination = prime_destinations ? prime_destinations : all_destinations // destination override bei <...>

        
        let callMonitor = modus==='callMonitor'
        if( callMonitor ){
            let deviceName = document.querySelector('#deviceName') 
            let t = /* await */ this.getToken()
            let data = {
                qrid : this.pid,
                msgid : this.message_parent_id,
                message : subject + ( subject ? ' ' : '') + mbody,
                token : t,
                privacy: privacy ? 1 : 0,
                destination: destination,
                sms: destination,
                delete_after: this.delete_after,
                protocol_permid: this.protocol_permid,
                deviceName: deviceName ? deviceName.value : 'Objekt ohne deviceName',
                mode: this.mode,
                type: this.type, 
                gcaptchatoken: this.gcaptchatoken,
                table: this.table
            }
            
            let options = {
                method: 'POST',
                mode: "cors",
                cache: "no-cache",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }

            data.gcaptchatoken = await this.requestGCaptcha(this, options)
            options.body = JSON.stringify(data)

            let direct_message = destination.includes('@') || destination.match(/\d+$/g) ? '/directmessage?z=1521' : '/message' //TODO das hier muss eher auf die existenz von monitor_destination und nicht auf das pattern gehen
            
            await fetch(this.helper + ('/message'), options) // AH 1810 damits auch ins EDP protokolliert wird - s. kommentar oben drüber anders konsturieren

            data.gcaptchatoken = await this.requestGCaptcha(this, options)
            options.body = JSON.stringify(data)

            return /* await  */ fetch(this.helper + direct_message, options)
        }else{

            return await this.postMessage( subject + ( subject ? ' ' : '') + mbody, privacy, destination, dest_preregemail)
        }
    }



    get_table_number( pm2e ){

        if( !pm2e )
            return null
        
        if( /* !pm2e.table ||  */pm2e.table === 'false' )
            return null

        return parseInt( window.getURLParameter('t'))
    }

//    async postMessage_vorregistrierung(m, p, dest){
// dazu siehe comment unten

    async postMessage(m, p, dest, preregemail){
        
        let t = /* await */ this.getToken()

        let msg_id = this.getCookie('message_parent_id_'+this.pid, 'postMessage()' )
	    if( !msg_id )
	    {   
            msg_id = this.getCookie('message_checkoutparent_id_' + this.pid)
            // checkout parent cookie nur 1x verwenden 
            this.setCookie('message_checkoutparent_id_' + this.pid, '', 0 ) 
            
            this.message_parent_id = msg_id ? msg_id.split(':')[1] : null
	    }

        let refuse = !this.questionnaireAccepted()
        let prefix = ''

        if( !SETUP && !WD ){
            if( !msg_id && PREREG ) prefix = 'PRE:'
            if( !msg_id && !PREREG ) prefix = 'IN:'
            if( msg_id && msg_id.includes('PRE:') ) prefix = 'IN:'
            if( msg_id && msg_id.includes('IN:') ) prefix = 'OUT:'

            if( EINLASSKONTROLLE && this.fromCookieIgnore ){
                prefix = 'AUTH:'
            }
	    }

        if( refuse && prefix !== 'AUTH:' && prefix !== 'OUT:'){ // refuse kann/darf nur bei IN:/PRE: auftauchen
            prefix = 'STOP:'
        }


        // clean message
        if (prefix !== '')
        {
            m = m.replace("IN:","")
            m = m.replace("OUT:","")
            m = m.replace("PRE:","")
            m = m.replace("STOP:","")
            m = m.replace("AUTH:","")
        }
	    if (prefix === 'AUTH:') {
		    m = m + '[' + this.protocol_permid+']'
	    }

        let monitor_destination = this.getRenderDataElement( null, 'value', 'monitor_destination')
        let webhook_destination = this.getRenderDataElement( null, 'value', 'webhook_destination')

        let deviceName = document.querySelector('#deviceName') 
        let data = {
            qrid : this.pid,
            msgid : this.message_parent_id,
            message : prefix + m,
            token : t,
            privacy: p ? 1 : 0,
            destination: dest,
            monitor_destination: monitor_destination,
            webhook_destination: webhook_destination,
            sms: dest,
            delete_after: this.delete_after,
            protocol_permid: this.protocol_permid,
            mode: this.mode,
            type: this.type,
            deviceName: deviceName ? deviceName.value : 'Objekt ohne deviceName',
            gcaptchatoken: this.gcaptchatoken,
            table: this.table
        }

        let options = {
            method: 'POST',
            mode: "cors",
            cache: "no-cache",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }


        data.gcaptchatoken = await this.requestGCaptcha(this, options)
        options.body = JSON.stringify(data)

                const self = this
        
                let has_image = false
                for (var k in this.imageRefs) {
                    if(this.imageRefs.hasOwnProperty(k)){
                        has_image = true
                    }
                }

                

                // edp logging und zwar immer dann, wenn die message am edp vorbei gehen würde und das erkennt man an der gesetzten destination
                // da fotos aber in den am edp vorbeigehenden messages (z.b. SMS) nicht drin sind, deswegen müssen auch photoso immer ins EDP
                if( dest || has_image ){

                    let data_for_logging = {
                        qrid : this.pid,
                        msgid : this.message_parent_id,
                        message : m + ' ('+dest+')',
                        deviceName: deviceName ? deviceName.value : 'Objekt ohne deviceName',
                        token : t,
                        privacy: p ? 1 : 0,
                        destination: dest,
                        monitor_destination: monitor_destination,
                        webhook_destination: webhook_destination,
                        sms: dest,
                        delete_after: this.delete_after,
                        protocol_permid: this.protocol_permid,
                        mode: this.mode,
                        type: this.type,
                        gcaptchatoken: this.gcaptchatoken,
                        table: this.table
                    }
        
                    console.log('this.imageRefs. %o', this.imageRefs)
                    console.log('has_image %o', has_image)

                    let uqwdphoto_file_url = ''
                    if( has_image ){
                        await this.uploadPhotos("see attached Image")
                        
                        uqwdphoto_file_url = this.getRenderDataElement( null, 'file', 'uqwd_photo')

                        console.log('uqwdphoto_file_url %o', uqwdphoto_file_url)
                        data.message += " [IMGID: "+this.pid+"/"+uqwdphoto_file_url+"]"

                        options.body = JSON.stringify(data)

                        console.log('options %o', options)
                    }else{
			    
                        let options_edp = {
                            method: 'POST',
                            mode: "cors",
                            cache: "no-cache",
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify(data_for_logging)
                        }

                        await fetch(this.helper + ('/message?from=has_no_image'), options_edp) // AH 1810 vorher wurde die oben ergänzte data.message nicht mitübertragen das soll aber nur hier drin sein - beim image ist es vermutlich noch fals, da hängt dann die dst immer mit drin
        // wenn table=true, dann nochmal dasselbe nur  mit data_for_logging qrid = this.pid+"_"+tischnummer (also ?t=12 ->7585z6_12)
        // msgid muss gelöscht werden (NN: und message-text?)

                    }
                }


                if( monitor_destination ){

                    let data_mon = {
                        qrid : this.pid,
                        msgid : this.message_parent_id,
                        message : data.message+' ('+dest+')', // AH 1810 s.o. wegen Veränderung bei vorhandener dest und 21.10. dest nagefügt, damits in der normalen message nicht mitkommt
                        token : t,
                        privacy: p ? 1 : 0,
                        destination: monitor_destination,
                        monitor_destination: monitor_destination,
                        webhook_destination: webhook_destination,
                        sms: monitor_destination,
                        delete_after: this.delete_after,
                        protocol_permid: this.protocol_permid,
                        mode: this.mode,
                        type: this.type,
                        deviceName: deviceName ? deviceName.value : 'Objekt ohne deviceName',
                        gcaptchatoken: this.gcaptchatoken,
                        table: this.table
                    }
            
                    let options_mon = {
                        method: 'POST',
                        mode: "cors",
                        cache: "no-cache",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                          },
                        body: JSON.stringify(data_mon)
                    }

                    data_mon.gcaptchatoken = await this.requestGCaptcha(this, options_mon)
                    options_mon.body = JSON.stringify(data_mon)

                    await fetch(this.helper + ('/directmessage?z=1730'), options_mon)
                }


        // if directmessages = true, dann dest auf value des elements setzen
        // wenn dann das  edp die ide zurückliefert, dann kriegt die directmessage einen anderen content
        // und die edpmessage enthält zusätzlich genau den cookiestring
        // nämlich https://web.corona-presenc.de/id?setcookiefrom=messageid  das liest dann denn cookiestring setzt ihn und sagt
        // (das kann gucken, ob man auf ios oder android ist und wenn nicht, gibt es einen warnhinweis)

        // vielen Dank, Sie sind jetzt auf diesem Gerät für %deviceName% vorregistriert. Wenn sie jetzt bei der veranstaltung ankommen und den QR-Code Scannen ist Ihr forular bereits ausgefüllt
        // WICHTIG: Bitte verwenden Sie das gleiche Gerät

        // Phase 2: Nur vorregistrierte werden reingelassen (wegen der besucherzählung) - da muss dann das standby konzept dazu, dass ab einer bestimmten uhrzeit die vorregistrierungen verfallen
        // sms kostest uns geld - email nicht, deswegen wollen wir eigentlich für die vorregistrierung ganz und gar auf email gehen
        // letztlich nur eine frage des email-textes, dass man das auf dem richtigen gerät aufmacht

        // bei der selbstregistrierungsvariante (vorregistreirung durch mich selbst)  können wir fragen: Sind Sie gerade auf dem Gerät mit dem Sie zur Veranstaltung gehen?
        // Wenn ja, brauchen wir gar nix schicken sondern setzen direkt das cookie.

        // beste idee: Wenn die Frage "Ist das das mobile Gerät" mit Nein beantworet wird (oder wir den desktop entdecken) dann reagieren wir genau wie hatsapp web und sagen
        // das ist nicht dein mobiles gerät bitte nimm das gerät scanne den qr-code und vollende den checkin

        // Wir braucehn also einen mode

        // prio 2: ?mode=preregister
        // Der Zeigt eine Landing-Page, die fragt "Bist du auf dem Mobilgerät" Ja /Nein
        // Bei Nein sagt Sie bitte den folgenden QR-Code mit dem Mobilgerät scannen
        // den qr code zeigt es auch an, wenn es nicht android/ios entdeckt. Du bist gerade nicht an Deinem Mobilgerät bitte scanne den folgenden QR-Code

        // prio 1: der qr code geht ganz normal in das normale mode=checklist mit evt. mode=checklist&elementid=value

        this.lastpid = this.getCookie('lastpid')

        this.lastpid_logoutmessage = this.getCookie( 'logoutmessage_'+this.lastpid, 'Appjs postMessage()' )
        this.lastpid_logoutparent_id = this.getCookie( 'message_parent_id_'+this.lastpid, 'Appjs postMessage()' )
        let is_confirm_btn = m.includes('#CALL')
        
        if (this.lastpid_logoutparent_id && this.lastpid !== this.pid && !is_confirm_btn ){
            let hop_data = {
                qrid : this.lastpid,
                msgid : this.lastpid_logoutparent_id,
                message : this.lastpid_logoutmessage,
                token : t,
                privacy: p ? 1 : 0,
                destination: "",
                sms: "",
                delete_after: this.delete_after,
                protocol_permid: null,
                mode: this.mode,
                type: this.type,
                gcaptchatoken: this.gcaptchatoken,
                table: this.table
            }
            
            let options_hop_logout = {
                method: 'POST',
                mode: "cors",
                cache: "no-cache",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(hop_data)
            }

            hop_data.gcaptchatoken = await this.requestGCaptcha(this, options)
            options_hop_logout.body = JSON.stringify(hop_data)

            console.log("Reply hopping")
            return await fetch(this.helper + '/messagereply' , options_hop_logout)
            .then(async result => {

                    self.setCookie('message_checkoutparent_id_' + self.lastpid, '', 0 )//checkout parent nur 1x verwenden 
                    self.setCookie('message_parent_id_' + self.lastpid, '', 0 )//checkout parent nur 1x verwenden 

                await result.json()
                .then( async jstr => { 
                    console.log('reply: %s', jstr) 
                })

            })
        }


        if( this.message_parent_id && !preregemail && !is_confirm_btn ){
            if( this.table && prefix === 'OUT:')
            {
                data.gcaptchatoken = await this.requestGCaptcha(this, options)
                options.body = JSON.stringify(data)
                
                await fetch(this.helper + '/message?table_out=true&from=1859', options)

                data.gcaptchatoken = await this.requestGCaptcha(this, options)
                options.body = JSON.stringify(data)

            }


            return await fetch(this.helper + '/messagereply' , options)
            .then(async result => {

                if (prefix !== 'OUT:') {

                    self.setCookie('message_parent_id_' + self.pid, prefix + self.message_parent_id, 180)
                    self.setCookie('message_checkoutparent_id_' + self.pid, prefix + self.message_parent_id, 180)
                } else{
                    self.setCookie('message_checkoutparent_id_' + self.pid, '', 0 )//checkout parent nur 1x verwenden 
                    self.setCookie('message_parent_id_' + self.pid, '', 0 )//checkout parent nur 1x verwenden 

                    // 
                    self.setCookie( 'logoutmessage_' + self.pid,'', 0)
                    self.setCookie( 'message_parent_id_' + self.pid, '', 0)
                    self.setCookie( 'login_' + self.pid,'', 0)
                }


                // kopie vom /message und /directmessage
                if ( CHECKLIST ) {
                    self.display_preview()
                }
            })
        }

         
        if ( preregemail )
            dest = preregemail

            
        data.gcaptchatoken = await this.requestGCaptcha(this, options)
        options.body = JSON.stringify(data)

        // cdap login etc
        return await fetch(this.helper + ( dest ? '/directmessage?z=2046' :'/message?from=2045'), options)
        .then(async result => {
            let message_post_success = await result.json().then(
                async jstr => {
                    try {
                        let j = this.convertToJsonIfString( jstr )
                        if( j.error )
                        {
                            console.log(j.error)
                            this.blueErrorPage.display = true
                            this.blueErrorPage.text = j.error
                            this.blueErrorPage.ecode = j.ecode
                            this.setCookie( 'logoutmessage_'+this.pid, '', 0)
                            this.setCookie( 'login_'+this.pid, '', 0)
                            window.hideLoader()
                            return this.forceUpdate()
                        }

                        if( !dest && !SETUP && Boolean(j.response && j.response.success) ){
                            if(!self.message_parent_id ){    
                                self.message_parent_id = j.response.success.parent_id[0]
                            }

                            if( PREREG )
                            {
                                self.setCookie('message_parent_id_'+self.pid, prefix+self.message_parent_id, 180)
                            } else
                            {
                                self.setCookie('message_checkoutparent_id_'+self.pid, prefix+self.message_parent_id, 180)
                            }
                        }

                        if( CHECKLIST && !Boolean(j.response && j.response.success) ){
                            console.log('FAILED TO GET MESSAGE_PARENT_ID')
                        }
                    } catch (error) {
                        console.log('ERROR message_parent_id_%s: %o \n%o', self.pid, error, jstr )
                    }

                    return true
                }
            )

            console.log('message_post_success %o', message_post_success)
            if( !message_post_success )
                return false

            // kopie im reply:
            if ( CHECKLIST ) {
                if (self.protocol_permid) {
                    let pid = '_'+self.protocol_permid
                    self.display_preview({ usecheckliste: pid })
                } else
                {
                    if( CDAP && self.questionnaireAccepted())
                    {
                        // t... cache... usecheckliste ?
                        self.display_preview({mode: 'delete', fromcookie: 'delete'})
                        
                        return 'skip_preview'
                    }else{
                        // t... cache... usecheckliste ?
                        self.display_preview()
                    }
                }
            }

            return true
        })
        .catch(error => {
            console.log('Error:', error)
            console.error('Error:', error)
            window.showError('Ups, irgendwas ist schief gelaufen. Bitte probiere es nochmal oder kontaktiere den Support.')
            return false
        });
    }


    convertToJsonIfString( json_or_string ){
        if( typeof json_or_string === 'string' ){
            try {
                return JSON.parse( json_or_string )
            } catch (error) {
                console.log(error.message)
                return false
            }
        } else {
            return json_or_string
        }
    }



    sort_array( arr ){
        let new_arr = []
        for (let i = 0; i < arr.length; i++) {
            const el = arr[i]
            if(el){
                if(el.props){
                    if(el.props.e){
                        new_arr[ el.props.e.selfIndex ] = el
                    }
                }
            }
        }
        return new_arr
    }


    check_conditions(){
        let rows_inputs = this.inputs.concat(this.rows).concat(this.signatures)
            rows_inputs = this.sort_array( rows_inputs )

        let rd = this.renderData
        for ( var i in rd ) {
            if( rd.hasOwnProperty(i) ){
                let e = rd[i]
    
                // condition list
                if( e.conditions && e.conditions.length ){
                    let condition_already_set = false
                    
                    let conds = e.conditions
                    for ( var x in conds ){
                        if( conds.hasOwnProperty(x) ){
                            let cond = conds[ x ]
    
                            // single condition
                            let val = this.getRenderDataElement(null, 'value', cond.on.id)
                            if( cond.on.values.includes( val )){
                                condition_already_set = true
                                this.renderData[ e.selfIndex ][ cond.set.attribute ] = cond.set.value
                            }else{
                                if( !condition_already_set ){
                                    this.renderData[ e.selfIndex ][ cond.set.attribute ] = cond.set.default
                                }
                            }

                            // set required
                            let row = rows_inputs[ e.selfIndex ]
                            if( cond.set.attribute === 'required' ){
                                let set = this.renderData[ e.selfIndex ].required
                                row.required = global.parseBool( set )
                                row.toggleRedBorder()
                            }

                            // check age
                            let valid_regex_match = row.inputPatternValidity()
                            if( valid_regex_match ){

                                let elem = this.renderData[ e.selfIndex ]
                                let self_id = elem.id
                                let is_self = self_id === cond.on.id
                                let is_cdap = self_id.includes('cdap_datum')
                                if( is_self || is_cdap ){
                                    if( !cond.on.age ){
                                        cond.on.age = '48h' // für 1tag test-gültigkeit: 48 statt 24, da keine uhrzeit abgefragt wird (und beim datum vom vortag immer 00:00 der timestamp ist)
                                    }
    
                                    if( !cond.set.age_expired ){
                                        cond.set.age_expired = 'Gültigkeit abgelaufen.'
                                    }
    
                                    if( !cond.set.age_future ){
                                        cond.set.age_future = 'Datum liegt in der Zukunft.'
                                    }
    
                                    let age_int
                                    let age_str = cond.on.age.match(/[0-9]*/)
                                    if( age_str && age_str[0] ){
                                        age_int = parseInt( age_str[0] )
                                    }
    
                                    let max_age
                                    if( cond.on.age.match(/h/) ){
                                        let hour = 60*60*1000
                                        max_age = age_int * hour
                                    }
    
                                    if( cond.on.age.match(/d/) ){
                                        let day = 24*60*60*1000
                                        max_age = age_int * day
    
                                    }
    
                                    if( cond.on.age.match(/y/) ){
                                        let year = 365*24*60*60*1000
                                        max_age = age_int * year
                                    }

                                    let val = elem.value
                                    if( val ){
                                        val = val.split('.')

                                        let user_date = new Date( val[2], val[1] - 1, val[0])
                                        let user_timestamp = user_date.getTime()
                                        let sys_timestamp = Date.now()
    
                                        let expired = (sys_timestamp - user_timestamp) > max_age
                                        let future = (sys_timestamp - user_timestamp) <= 0
    
                                        if( expired ){
                                            row.showInputNotification( cond.set.age_expired )
                                            this.renderData[ e.selfIndex ]['date_validity'] = false
                                            row.input.dataset['date_validity'] = 'false'
                                        }else if( future ){
                                            row.showInputNotification( cond.set.age_future )
                                            this.renderData[ e.selfIndex ]['date_validity'] = false
                                            row.input.dataset['date_validity'] = 'false'
                                        }else{
                                            row.hideInputNotification()
                                            this.renderData[ e.selfIndex ]['date_validity'] = true
                                            row.input.dataset['date_validity'] = 'true'
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }


    // in send () aufgerufen
    async inspectRequiredFields(activeSection) {
        this.inspectedRequiredFields = true

        let done = true
        let elem = null
        let undone_sections = {}

        let rows_inputs = this.inputs.concat(this.rows).concat(this.signatures)
            rows_inputs = this.sort_array( rows_inputs )


        let isolated_rows = []
        if( activeSection ){
            for ( var key in rows_inputs ) {
                if( rows_inputs.hasOwnProperty(key) ){
                    let k = key * 1  //str2int
                    if( rows_inputs[k].section.includes(activeSection) ){
                        isolated_rows.push(rows_inputs[k])
                    }
                }
            }
            rows_inputs = isolated_rows
        }

        let first_unfinished_element_id = undefined
        for (var key_ in rows_inputs) {
            if(rows_inputs.hasOwnProperty(key_)){

                let k = key_ * 1 // str2int
                let row = rows_inputs[k]
                if( row ){

                    let val = row.state.value || row.state.choice
                        val = val || val === 0 // 0 erlaubt, '' => false

                    let invalid = false
                    if( Boolean( row.inputPatternValidity ))
                        invalid = !row.inputPatternValidity()

                    let req = row.required
                    // 1. leeres required feld öffnen
                    if (done && req && (!val || invalid)) { 
                        done = false
                        elem = row.refs.thisElement

                        if( !first_unfinished_element_id ){
                            first_unfinished_element_id = row.props.e.id
                            this.first_unfinished_element_id = row.props.id
                        }
                    }

                    let wrong_date = false
                    if( row.input ){
                        wrong_date = row.input.dataset.date_validity === 'false'
                    }
                    
                    // alle required sections ohne value merken und rot markieren
                    if (req && (!val || invalid || wrong_date) ) {
                        undone_sections[row.section] = this.sections[row.section]
                        row.setState({ redBorder: true })
                    }
                }
            }
        }

        /*  scroll to first unfinished element */
        let first_unfinished_element = document.getElementById( first_unfinished_element_id )
        if( first_unfinished_element ){

            window.scrollTo({
                left: 0,
                top: first_unfinished_element.getBoundingClientRect().top + window.scrollY - 220,
                behavior: 'smooth'
            })
        }

        if(activeSection && !done)
            return false

        if(activeSection && done)
            return true
        

        // öffne required sektionen 
        if (!done && elem) {
            Object.keys(undone_sections).forEach(function (key) {
                if (this[key] && this[key].state.deg === 0)
                    this[key].toggleSection()
            }, undone_sections)

            elem.focus()

            return false
        } else
            return true
    }


    async save_intermediate() {
        this.HTMLtoJSON('intermediate')

        if (window.mag_webkit) {
            await this.savetoappIntermediate(this.renderData)
        }
    }


    unfinishedModal() {
        return this.ai ? false : !this.rows.every(function (row) {
            let o = row.state.isOpen
            let c = row.state.choice
            let s = row.modal.savedContent
            let m = row.modal.state.msg
            if ((!m | !s) && o && c === 0)
                return false
            else
                return true
        })
    }


    getOpenModal() {
        let mod
        this.rows.every(function (row) {
            mod = row.modal
            return !row.state.isOpen
        })
        return mod
    }


    grayOutButton(id) {
        try {
            this.submitButtonColor = document.getElementById(id).style.backgroundImage
            document.getElementById(id).style.backgroundImage = 'linear-gradient(to right, #b9b9b9 0%, #dadada 100%)'
        } catch (e) {}
    }


    degrayButton(id) {
        try {
            document.getElementById(id).style.backgroundImage = this.submitButtonColor
        } catch (e) {
            let buttons = document.getElementsByClassName('submit')
            let self = this

            // https://stackoverflow.com/q/3871547
            Array.prototype.forEach.call(buttons, function (btn) {
                btn.style.backgroundImage = self.submitButtonColor
            });
        }
    }

    async showPopup(destination){
        if( !destination )
            return false

        destination = destination.maskStringBetween('[]')

        let promise = new Promise((resolve, reject) => {
            document.getElementById('pophead').innerHTML = 'Senden bestätigen'

            let popcss = document.getElementById('popup').style
            let poppregcontact = document.getElementById('poppregcontact').style
            let popbody = document.getElementById('popbody').style
        
            poppregcontact.display = 'none';
            popbody.display = 'none';
            popcss.display = 'block'

            // let absender = document.getElementById('absender')
            let preregemail = document.getElementById('preregistrationemail')
            if (preregemail)
            {
                if( preregemail && !preregemail.value.trim() ){
                    // todo: cookie mit absender
                    poppregcontact.display = 'block'
                }else{
                    // todo [...] entfernen
                    popbody.display = 'block';
                    document.getElementById('popbody').innerHTML = 'Aktivierungs-Email an : <br/><br/>' + preregemail.value + '<br/><br/>'
                }
            } else{
                /* if( absender && !absender.value.trim() ){
                    // todo: cookie mit absender
                    document.getElementById('popcontact').style.display = 'block'
                }else{ */
                    // todo [...] entfernen
                    popbody.display = 'block';
                    // destination = "abc [123] <0172> xyz@bc.de, cde [123] <0172> xyz2@bc2.de";
		    if (destination !== '') {

                    document.getElementById('popbody').innerHTML = 'Nachricht an: ' + destination.replace(/\[.*?\]/,'').split(',').join(',<br/>') + '<br/>'
		    }
                    // derzeit geht nur ein [] eintrag weitere bleiben stehen
                //}
            }
            
            document.getElementById('popcancel').onclick = () => { popcss.display = 'none'; window.hideLoader(); this.degrayButton('submit'); resolve(false)}
            document.getElementById('popokay').onclick = () => { popcss.display = 'none'; resolve(true)}
        })
          
        let result = await promise; // wait until the promise resolves (*)

        let absender_field = document.getElementById('absender')
	    //console.log("absender_field 1: %o",absender_field.value)
	    let popinput_field = document.getElementById('popinput')
	    //console.log("popinput_field %o",popinput_field.value)

	    if (absender_field && popinput_field) 
        {
		    absender_field.value = popinput_field.value 
	    }

	    // console.log("absender_field 2: %o",absender_field.value)
        this.HTMLtoJSON()
          
        console.log(result); // "done!"
        return result
    }


    evaluate( evaluate, code_position )
    {
        if( !evaluate )
            return false

        let wrong_position = evaluate.position !== code_position
        if( wrong_position )
            return false

        if( evaluate.formula )
        {
            switch ( evaluate.formula )
            {
                case 'javascript formel':
                case 'reload':
                    // delete usecheckliste_dvid_<QRID>
                    this.setCookie('usecheckliste_dvid_' + this.pid, '', 0)

                    let params = ''
                    let refuse = !this.questionnaireAccepted()
                    if ( refuse ){

                        // "reloadparams:false" "display=preview&refuse=true"
                        params = evaluate[ 'reloadparams:false' ]

                    }else{
                        
                        // "reloadparams:true": "mode=checklist"
                        params = evaluate[ 'reloadparams:true' ]
                    }

                    let url = window.location.href.split('?')[0] + '?' + params
                    return window.location.href = url
                    
                    break;
            
                default:
                    break;
            }
        }

        return true
    }


    async send( quickmessage, id, activeSection, evaluate ) {
        if(/* quickmessage && */ this.disableSend)
            return null
        
        let requiredFieldsDone = await this.inspectRequiredFields(activeSection)
        if (!requiredFieldsDone){
            this.pflichtfelder_popup()
            return null
        }


        if(!this.disableSend)
            this.disableSend = true
        
        this.showLoader()
        this.grayOutButton(id || 'submit')
        this.HTMLtoJSON()

        let refuse = !this.questionnaireAccepted()


        if( this.evaluate( evaluate, EVAL_AHEAD_OF_COOKIES ))
            return true


        // login cookie setzen
        if(CDAP && CHECKLIST && !PREREG && !refuse){
            this.setCookie('login_'+this.pid, new Date().toLocaleString().replace(',', ' '), 3/24, 'afterMidnight' )
            this.setCookie('showLoginAgain_'+this.pid, 'true', 2 )

            if(!this.cookiesAllowed){
                // delete personal data
                // save logoutcookie
            }
        }

        let lastpid_hopping = this.lastpid = this.getCookie('lastpid')
        this.lastpid_logoutmessage = this.getCookie( 'logoutmessage_'+lastpid_hopping )
        this.lastpid_logoutparent_id = this.getCookie( 'message_parent_id_'+lastpid_hopping )

        if( refuse ){
            this.setCookie( 'logoutmessage_'+this.pid, '', 0)
            // this.setCookie( 'lastpid', '', 0)
        } else{
            this.setCookie( 'lastpid', this.pid, 7) 
        }


        if (window.mag_webkit && !window.location.href.includes('setup')) {
            // wenn es sich um eine checkliste handelt (pfad ist nicht setup) dann wird in der IOS-Version der spezielle checklistenpost verwendet, der das JSON parsed und ggf. comments/images hochlädt (bisher nur IOS)
            // in app nicht mehr notwendig (16-06-2020):
            // await this.savetoapp(this.pid, this.renderData)
        }


        if ( SETUP && !EDIT ){
            return await this.mwsCreateEDPObject()
        }
        else{

            // 1. display fotos
            // 2. upload only if changed
            await this.uploadPhotos()
            console.log('nach uploadPhotos() this.renderData %s', JSON.stringify(this.renderData))


            if( this.evaluate( evaluate, EVAL_AHEAD_OF_POSTMESSAGE ))
                return true
                
            // await fetch(this.helper + '/set?mode=update&pid=' + this.pid + '&data=' + encodeURI(JSON.stringify(this.renderData)) + '&permid=' + /* this.permid.CHILD */ this.permid.DOCUMENT + '&token=' + this.idtoken + '&type=' + this.edpPrefix /*(self.idtoken || self.apiToken) */ )
            //if(photosUploaded){
                //TODO: REFACTOR !!! parameter stezen und jsonToEDP nur ein mal aufrufen (NN)
                let fileMode = 'update' //this.permid.PROTOCOL ? 'update' : 'new'
                // console.log('fileMode %o', fileMode)
                if (CHECKLIST) { // TODO: UPDATE oder NEW (gibt es $protocol schon?)

                    let send_ok = true
                    if(quickmessage){
                        if( typeof quickmessage !== 'object')
                            quickmessage = {body: quickmessage}

                        send_ok = await this.postJsonMessages2EDP(quickmessage, '%value%', null, 'isQuickmessage')
                    } else {
                        // im quickmessage mode (wachendorff) kein neues $protocol
                        let perm = this.permid.PROTOCOL ? this.permid.PROTOCOL : this.permid.CHILD /* this.permid.DOCUMENT ??? */
                        // TODO patch des
                        // send_ok = await this.jsonToEdp(fileMode, perm, this.user, 'protocol')
                        /* let returned_permid = */ 
			    console.log("3107"); 
                        await this.jsonToEdp(fileMode, perm, this.user, 'protocol')

                        send_ok = await this.postJsonMessages2EDP(this.postmessage2edp, null, null, 'isNotQuickmessage')
                    }

                    /* if( send_ok === 'skip_preview' ){
                        return true
                    } */

                    if(this.directmessage){
                        send_ok = await this.postJsonMessages2EDP(this.directmessage)
                    }

                    if( send_ok === 'skip_preview' )
                        return true

                    if( !send_ok ){ // send_ok enthält meistens keinen brauchbaren wert, der auf diese weise ausgewertet werden kann (NN 20052021)
                        this.degrayButton(id || 'submit')
                        console.log('postJsonMessages2EDP canceled (by error or user)')
                        // return false -> auskommentiert, der redirect weiter unten sollte immer statfinden
                    }
                }

                // ------ posting done --- REDIRECTION ------

                if( this.evaluate( evaluate, EVAL_AHEAD_OF_REDIRECT ))
                    return true
                

                if ( WD ) {
                    let user = encodeURI(window.getURLParameter('user'))
                    if (!EDIT) 
                        _URL.WEB_WHATSDOWN.append({user: user, idtoken: this.idtoken}).refresh()// + '?user=' + user + "&idtoken=" + this.idtoken
                }
              

                if (!EDIT){
                    let proto_pid    = null
                    let message_pid  = this.get('msg_pid')
                    let after_PREREG = message_pid.includes('IN:')

                    if (PREREG || after_PREREG)
                        proto_pid = this.get('protocol_pid')

                    this.display_preview({usecheckliste: proto_pid })
		        }


                if ( EDIT ){

                    // SIEDE SCHNELLWURF
                    let perm = BI || WD ? this.permid.CHILD : this.permid.DOCUMENT
                    let prefix = WD ? 'protocol' : this.edpPrefix
			console.log("3161",prefix); 

			if (AI) prefix="aident"; 
			console.log("3164",prefix); 

                    //TODO patch des object namen auf das NEUE device name
                    
                    await this.jsonToEdp(fileMode, perm, this.user, prefix, this.renderData )

                    this.display_preview({ from: PREREG ? 'edit' : 'setup' })
                }
        }

            // TODO mwsCreateEDPObject muss Permid/DVID zurückgeben {id}, die dann diesem savetoserver mitgegeben wird, damit /set in server.js den post/documents/{id}/file aufrufen kann
            // TODO: wegen überholsituation auf dem server (set wird ausgeführt, bevor der create fertig ist) steht die permid.CHILD nicht fest und schlägt fehl    
            // await this.savetoserver(this.pid, this.renderData) 

            /* if (window.mag_webkit)
                window.WebViewClose() */

            /* if( this.displayPreview )
            // refresh to show WD-Setup-Foto-Upload
            this.forceUpdate() */

            // TODO: change render type (display data)
            /* if (!window.mag_webkit)
            window.location.reload() */
        //}
    }

    get( pstring ){
        switch (pstring) {
            case 'msg_pid':
                return this.getCookie('message_parent_id_' + this.pid)

            case 'protocol_pid':
                return this.protocol_permid ? '_' + this.protocol_permid : false
        
            default:
                return 'could not get() pstring value.'
        }
    }

    get_submit_url( default_href ){

        let q_accepted = this.questionnaireAccepted()
        if( q_accepted && this.submit_ok_url )
            return this.submit_ok_url

        if( q_accepted && this.submit_refuse_url )
            return this.submit_refuse_url

        if( default_href )
            return default_href

        return window.location.href
    }


    async savetoserver(pid, renderData) {
			 let edp_prefix=this.edpPrefix; 
			    if (edp_prefix == '' || edp_prefix == null)
		    {
			    if (types.AI) edp_prefix="aident"; 
	    		}
	    console.log("3226",edp_prefix); 
        await fetch(this.helper + '/set?pid=' + pid + '&data=' + encodeURI(JSON.stringify(renderData)) + '&permid=' + this.permid.CHILD + '&token=' + this.idtoken + '&type=' + edp_prefix)
    }


    async savetoapp(pid, renderData) {
        await window.writeUQRSjson(JSON.stringify(renderData), 'postandsafe')
    }


    async savetoappIntermediate(renderData) {
        await window.writeUQRSjson(JSON.stringify(renderData), 'safe')
    }

    getCookie(cname, caller) {
        if( !navigator.cookieEnabled )
        {
            window.showError('Cookies disabled.', 'removeOldErrors')
            return '' //'navigator.cookieEnabled: false'
        }

        var name = cname + "="
        try {
            var decodedCookie = decodeURIComponent(document.cookie)
        } catch (error) {
            console.log('getCookie() failed. input: %o - Error: %o', document.cookie, error)
            window.showError('getCookie() failed. input: '+ document.cookie + ' - Error: ' + error )
            window.showCookieDelete()
            return caller === 'timestamp' ? 0 : '' //'Could not decode URIComponent. (' + document.cookie + ')'
        }
        var ca = decodedCookie.split(';')
        
        //300920 AH
        if (this.fromCookieIgnore) 
        {
            // console.log('FROM_COOKIE_IGNORE getCookie %o %o %o', cname, caller, '')
            return caller === 'timestamp' ? 0 : ''
        }

        for(var i = 0; i <ca.length; i++) {
          var c = ca[i];

          if ( c.includes(cname + "=") && caller === 'timestamp' ) {
            let coo_arr = c.split(SEMICOLON_RX)
            let ts = coo_arr[ coo_arr.length-1 ].split('=')[1]
            return ts ? parseInt( ts ) : 0
          }

          while (c.charAt(0) === ' ') { // trim() !?
            c = c.substring(1);
          }

          // verfalls-zeit
          if (c.indexOf(COOKIE_CREATE_TIME) >= 0 ) {
            var t = c.split(COOKIE_CREATE_TIME)[1]
            c = c.split(COOKIE_CREATE_TIME)[0]
            let create_time = t.substring(1)

            var d = new Date()
            var now = d.getTime()
            if( (now - Number(create_time)) > COOKIE_MAX_AGE ){
                this.setCookie(cname, '', 0)
                return ''
            }
          }

          if (c.indexOf(name) === 0) {
            let cvalue =  c.substring(name.length, c.length).split(SEMICOLON_RX)[0]
            // console.log('getCookie %o %o %o', cname, caller, cvalue)
            return cvalue
          }
        }

        return caller === 'timestamp' ? 0 : ''
    }

    setCookie(cname, cvalue, exdays = 3, afterMidnight = false ) {
        if( !navigator.cookieEnabled )
        {
            window.showError('Cookies disabled.', 'removeOldErrors')
            return ''
        }

        // console.log('setCookie %o %o', cname, cvalue)
        if( this.delete_cookies === false )
            return ''

        if(this.fromCookieIgnore)
            return ''

        if( cvalue === null ||  cvalue === undefined || !cname )
            return false

        if( typeof cvalue !== 'string' )
            return false
        
        var d = new Date()
        var create_time = 'create_time=' + d.getTime()
        
        try {
            cvalue = encodeURIComponent(cvalue.replace(/;/g, SEMICOLON)) + SEMICOLON + create_time
        } catch (e) {
            window.showError( '[' + this.pid + '] setCookie-Error: ' + cname + '. Failed to replace values in ' + cvalue + '. DataType: ' + (typeof cvalue) )
            window.showCookieDelete()
        }

        if(afterMidnight)
            d.setHours(23,59,59,0)

        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires // + ";"; //path=/ // <= SAFARI logout problem, kein cookie-overwrite
    }


    readValueFromCookie(id, cookieName){
        if(!cookieName || !id)
            return ''

        try {
            let cookieValue = JSON.parse( this.getCookie(cookieName) )
            return cookieValue[id]
        } catch (err) {
            return ''
        }
    }

    saveInputsToCookie(){
        if(!this.cookie.name || !CHECKLIST)
            return false

        for( let key in this.cookie.value ){ 
            this.cookie.complete[key] = this.replace_percent_optional(this.cookie.value[key])
        }

        if(this.cookie.complete){
            if(this.cookiesAllowed){
                this.setCookie(this.cookie.name, JSON.stringify( this.cookie.complete ), 1000)
            }else{
                this.setCookie(this.cookie.name, '', 0)
            }

            if( this.postmessage2edp ){
                let {body, subject} = this.postmessage2edp // this.logoutmessage 
                if(body || subject){
                    this.setCookie( 'logoutmessage_'+this.pid, this.replace_percent_optional(JSON.stringify(this.postmessage2edp /* this.logoutmessage */)), 3/24, 'afterMidnight')
                }
            }
        }
    }

  
    inputKeyUpHandler(e) { 

        this.checklistFinished()
        // mark required INPUTS
        if (this.inspectedRequiredFields && e && e.target.tagName.match(/INPUT|SELECT/)) {
            let el = e.target
            let required = el.required || el.dataset.required
            //let wrong_date = el.dataset.date_validity === 'false'

            if( (!el.value /* || wrong_date */) && required ){
                el.style.border = '2px solid red'
            }else{
                el.style.border = ''
            }
            
        }
    }


    pflichtfelder_popup(){
        /* window.show_popup()
        let head = document.getElementById('show_more_head')
        let body = document.getElementById('show_more_body')

        head.innerHTML = 'Angaben unvollständig!'
        body.innerHTML = 'Bitte <b>alle</b> rot markierten Pflichtfelder bearbeiten.' */

        window.show_popup( 
            'Angaben unvollständig', 
            'Bitte <b>alle</b> rot markierten Pflichtfelder bearbeiten.', 
            {
                okay: {
                    name: 'Weiter', 
                    callback: function(){
                        console.log('verstanden')
                    }
                }
            }
        )
    }


    // speichern-button [on|off]
    checklistFinished() {
        setTimeout(function () { // auf state-update warten
            if (!this.initialSubmitState) {

                let val
                this.inputs.concat(this.rows).every((e) => {
                    val = e.state.value || e.state.choice

                    if (typeof val === 'string'){
                        if(e.props.type === 'select'){
                            val = val !== '0'
                        }else{
                            val = val.trim().length > 0
                        }
                    }else{
                        val = val || val === 0 // 0 erlaubt
                    }
                    return val
                })
                // speichern-btn aktivieren
                this.setState({ showSubmit: val })
            }
            // immer speichern
            return this.save_intermediate()
        }.bind(this), 10)
    }


    delete_cached_images_from_renderData(){
        if( this.cached_image_indices )
            for (let i = 0; i < this.cached_image_indices.length; i++) {
                let cii = this.cached_image_indices[i];
                if( cii )
                {
                    this.renderData[ cii ].data = 'removed'
                    console.log('removed cached image from renderData ', cii)
                }
            }
    }


    HTMLtoJSON(mode) {
        this.delete_cached_images_from_renderData()
        var imax = 0
        var urlParamsIndex = -1
        this.renderData.map((e, i) => {
            imax = i+1
            
            let val = e.value ? e.value.replace('"', '\\"') : e.value
            let id = e.id
            let type = e.type
            let elem = document.getElementById(id)

            if(Boolean(type && type.match(/urlparams/)))
                urlParamsIndex = i
       
            // return if value is set (item checked)
            if (elem && typeof elem.value !== 'undefined' && elem.value !== null) {
                val = String( elem.value )
            }

            // write ['value'] to renderData array
            if ((type != null) && type.match(/select|text|number|email|checkit|urlparams|datetime-local|quickmessage/)) {
                this.renderData[i]['value'] = val //todo:encodeURI

                let status = ''
                switch (val) {
                    case '0':
                        status = 'ok'
                        break;
                    case '1':
                        status = 'not ok'
                        break;
                    case '2':
                        status = 'unavailable'
                        break;
                    default:
                        status = val || 'unset'
                        break;
                }

                if (type != null && type.match(/checkit/)) {
                    try {

                        // text area 
                        let txt = this.rows[i].modal.state.msg
                        //txt = txt === '' || typeof txt === 'undefined' ? '' : ': ' + txt

                        this.renderData[i]['msg'] = mode === 'intermediate' ? txt : e.name + ' - ' + status + ': ' + txt
                        this.renderData[i]['datum'] = this.rows[i].modal.state.datum
                        if (this.images[i])
                            this.renderData[i]['userImage'] = this.images[i]

                    } catch (error) {
                        
                    }
                }

                //if (type != null && type.match(/checkit/)) console.log("this.renderData[i]['value'] %o", this.renderData[i]['value'])
                //if (type != null && type.match(/checkit/)) console.log("this.renderData %o", this.renderData)
            }

            

            return null
        })

        this.saveInputsToCookie()

        let searchParams = new URLSearchParams(window.location.search)
        let msie = window.navigator.userAgent.indexOf("MSIE ") > -1
        if ( !msie ) {
            imax = urlParamsIndex > -1 ? urlParamsIndex : imax
            this.renderData[imax] = {}

            this.renderData[imax]['type'] = 'urlparams'
            this.renderData[imax]['title'] = this.displayTitle
            this.renderData[imax]['time'] = new Date().toLocaleString()
            this.renderData[imax]['last_update'] = this.last_update
            this.renderData[imax]['pid'] = this.pid //zusätzlich zu qrid im query
            //todo userid aus idtoken lesen und speichern
            //todo useragent speichern

            // console.log(this.renderData)

            var self = this
            searchParams.forEach(function(value, key) {
                let k = key === 'type' ? 'typeUrl' : key // "type":"urlparams" schon belegt
                self.renderData[imax][k] = value
            })

            //timestamp checklistenjson:
            // last_update: aus dem edp
        }

        this.save_filled_renderData_to_localStorage()
        return null
    }

    save_filled_renderData_to_localStorage(){
        this.localStorage_setItem('filled_renderData_' + this.pid, JSON.stringify( this.renderData ))
    }

    localStorage_setItem( name, value ){
        window.localStorage_setItem( name, value )
    }

    localStorage_getItem( name ){
        return ''
        // return window.localStorage_getItem( name )
    }

    setImagePath(p, i) {
        this.images[i] = p

        this.save_intermediate()
    }

    // 27/7/2020: war auskommentiert
    toggledCollapse(){
        return true

        if( /* todo: force_message_text */ false ){
            
            if(this.unfinishedModal() ){
              this.getOpenModal().notes['noText'].show()
              return false
            }else{
              return true
            }
        }
    }

    validate() {
        this.validated = true
    }

    setValidated(b) {
        this.validated = b
        this.forceUpdate()
    }

    
    loading_animation_start(id){
                            
        let btn = document.getElementById( id )
        if( !btn )
            return false

        this.wait_for_completion = true
        this.showLoader( btn )

        btn.style.filter = 'saturate(0)'
    }

    loading_animation_stop(id){
                            
        let btn = document.getElementById(id)
        if( !btn )
            return false

        window.hideLoader()
        this.wait_for_completion = false

        btn.style.filter = 'saturate(1)'
    }


    setCookie_disable(){
        this.delete_cookies = false
    }


    setCookie_enable(){
        this.delete_cookies = true
    }


    create_confirm_button( e ){
        let app = this

        let css = { 
            backgroundColor:    'transparent', 
            color:              '#0090ff', 
            border:             '2px solid #0090ff',
            fontWeight:         '300'
        }

        return (
            <a id={ e.id } href={ e.href } target='_blank' rel="noreferrer" className='introButton button' style={ css } onClick={
                async () => {

                    // mehrfach-klicks unterbinden
                    if( app.wait_for_completion )
                        return false

                    // benachrichtigung
                    let regular = 'Einen Moment bitte. Ihre Anfrage wird ausgeführt.'
                    let time    = new Date().toLocaleTimeString('de-DE') + ' Uhr: '
                    let msg     = e.notification || e.name || regular

                    window.showError( time + msg )


                    app.loading_animation_start( e.id )
                    app.setCookie_disable()

                    await app.postJsonMessages2EDP( e )

                    app.setCookie_enable()
                    app.loading_animation_stop( e.id )

                    // auskommentiert, da ios richtiges a+href braucht
                    // url in neuem tab öffnen
                    /* if( e.href ){
                        let tab = window.open( e.href, '_blank')
                        if( tab )
                            tab.focus()
                    } */

                    return true
                }
                }> { e.name } <br /> 
            </a>
        )
    }


    create_confirm_buttons( page ){
        // buttons aus json/renderData
        let cbtn = this.confirmbuttons[ page ]

        if( !page || !cbtn )
            return []

        let created = []
        for (let i = 0; i < cbtn.length; i++)
        {
            created.push(
                this.create_confirm_button( cbtn[ i ] )
            )
        }

        return created
    }

    push_confirmbutton( e )
    {
        let page = e.render || window.LOGOUT

        if( !this.confirmbuttons[ page ] )
            this.confirmbuttons[ page ] = []

        this.confirmbuttons[ page ].push( e )
    }

    save_checkbox_value_to_renderdata( value ){
        if( !this.renderData ){
            return false
        }

        let rd = this.renderData
        let rd_len = rd.length
        let rd_last = rd[ rd_len - 1 ]

        let TYPE = 'cookie_agreement' // filter type in JSONtoHTML??
        let rd_ca = this.getRenderDataElement(TYPE)
        let has_ca = Boolean(rd_ca)

        let rd_last_is_checkbox = rd_last.type === TYPE
        if( /* rd_last_is_checkbox */ has_ca ){

            this.renderData[ /* rd_len - 1  */ rd_ca.selfIndex ].value = String( value )
        }else{

            this.renderData.push({
                type: TYPE,
                value: String( value )
            })
        }

    }


    checkServiceSelector( jElement ){
        if( !this.isServiceSelector ){
            if( jElement ){
                if( jElement.style ){
                    if( jElement.style.match(/service.*selector|selector|^ss$/i) ){
                        this.isServiceSelector = true
                        this.selectorHistory = []
                        this.selectorBackgroundImage = jElement.backgroundImage
                        return true
                    }
                }
            }
        }
        // else:
        return this.isServiceSelector
    }


    setActiveElementClassFromURL(){
        let hist = this.selectorHistory
        if( hist ){ // wenn history initialisiert
            if( hist.length === 0 ){ // .. und keine einträge hat

                let eclassurl =  window.getURLParameter( 'class' )
                if( eclassurl ){
                    this.setElementClass({ class: eclassurl })
                }
            }
        }
    }


    showServiceSelectorBackground(){
        if( this.isServiceSelector ){
            if( this.selectorBackgroundImage ){

                let img = this.selectorBackgroundImage

                if ( img.match( /^http/ ) ){
                    document.body.style.backgroundImage = `url('${this.selectorBackgroundImage}')`
                }else{
                    if( img !== 'none' ){
                        if ( img.match( /^public:/ ) ){
                            img = img.replace('public:','')
                            document.body.style.backgroundImage = `url('${ window.location.origin + '/' + img }')` 
                        }else{

                            document.body.style.backgroundImage = `url('${ require( img ) }')` 
                        }
                    }else{
                       document.body.style.backgroundImage = ''
                    }
                }
                
                // document.body.style.backgroundImage = `url('${this.selectorBackgroundImage}')`
                this.selectorBackgroundImage = false
            }
        }
    }


    createModifier( e, element){
        return <ModifierContainer app={this} e={e}>
            {element}
        </ModifierContainer>
    }


    JSONtoHTML(json) {
        if( json && !json[0] ){
            return window.showError('Missing JSON: Cannot create User Interface (JSONtoHTML).')
        }

        var activeSection = ''
        var IS_REAL_SECTION = false
        var collapseSection = false
        let default_destination = ''
        let call_monitor_destination = ''
        var re = new RegExp(' ', 'g');
        let checkit_enumerator = 0

        let startElementInJSON = false
        

        /* map */
        //let createdHTML = json.map((e, i) => {
        let createdHTML = []
        for( let i=0; i<json.length; i++){

            let create_modifier = true
            let e = json[i]

            if( typeof e !== 'object')
                return null

            let id = e.id
            let name = e.name
            let type = e.type
            let value = e.value ? e.value.replace('\\"', '"') : ''
            e.selfIndex = i

            this.isServiceSelector = this.checkServiceSelector(e)
            this.showServiceSelectorBackground()
            this.setActiveElementClassFromURL()
            

            
            if(e.delete_after)
                this.delete_after = e.delete_after
                
            if(e.image_startpage)
                this.image_startpage = <Image key={i} src={e.image_startpage}/> 


            // read_from_setup

            let allwaysOn = false /* this.overrideValidatedAccess !== null ? this.overrideValidatedAccess : */  // e.on === 'true'
            let required = global.parseBool(e.required)

            /* if( this.displayPreview && type !== 'header' )
              return null */

            let {CONTACT_DEFAULT, CONTACT_URGENT, CONTACT_DIRECT} = global
            let contact_ids_rgxp = new RegExp([CONTACT_DEFAULT, CONTACT_URGENT, CONTACT_DIRECT].join('|'), "g")
            let quickmsg = null
            let contact_route = ''
            
            if( CHECKLIST && e.id && json[i+1] ){ // todo: hasOwnProperty(...)
                    let CONTACT_FIELD_FOLLOWS = json[i+1].id ? json[i+1].id.match(contact_ids_rgxp) : false
                    let IS_CONTACT_FIELD = e.id.match(contact_ids_rgxp)
                    let QUICKMESSAGE_AFTER_INPUT = e.type==='quickmessage' && json[i-1].type.match(/^text$/) && json[i-1].line !== 'multi'
                    //let SECTION_FOLLOWS = json[i+1].type ? json[i+1].type.match(/section/) : false

                    //console.log('id %o IS_CONTACT_FIELD %o contact_ids_rgxp %o', e.id, IS_CONTACT_FIELD, contact_ids_rgxp )

                    // convert quickmessage to section
                    if(e.type==='quickmessage' && e.mode==='extended' && CONTACT_FIELD_FOLLOWS ){
                        this.extended_quickmessage = e.value
                        activeSection = e.id
                        IS_REAL_SECTION = false
                        e.collapse = 'true' 
                        e.render_as_button = true
                        e.name = name = value
                        type='section'
                    }

                    // reset quickmessage memory for contact fields
                    if(e.type==='quickmessage' && e.mode !=='extended' && !CONTACT_FIELD_FOLLOWS ){
                        this.extended_quickmessage = null
                    }

                    // render kontakt-input as quickmessage
                    if(IS_CONTACT_FIELD){
                        type = 'quickmessage'
                        quickmsg = this.extended_quickmessage
                        contact_route = value.getDestination('<>')
                        if( !contact_route ) contact_route = value.getDestination()
                        value = name
                    }

                 
                    //console.log('e.type.match(/^html$|^textbox$|^margin$|^socialmedia$|^breaksection$|^agb$/) %o (!e.type.match(/section/) %o !CONTACT_FIELD_FOLLOWS %o !IS_CONTACT_FIELD %o !IS_REAL_SECTION)', e.type.match(/^html$|^textbox$|^margin$|^socialmedia$|^breaksection$|^agb$/) , !e.type.match(/section/) , !CONTACT_FIELD_FOLLOWS , !IS_CONTACT_FIELD , !IS_REAL_SECTION)

                    // break active section
                    if( QUICKMESSAGE_AFTER_INPUT || e.type.match(/^html$|^textbox$|^margin$|^socialmedia$|^breaksection$|^agb$/) || (!e.type.match(/section/) && !CONTACT_FIELD_FOLLOWS && !IS_CONTACT_FIELD && !IS_REAL_SECTION) /* && !SECTION_FOLLOWS */){
                        
                            activeSection = ''
                            IS_REAL_SECTION = false
                    }
            }

            

            if( e.id && e.id.match(/hauptkontakt|default_destination/) ){
                default_destination = value

                if(value)
                    return null
            }

            if( e.id && e.id.match(/call_monitor_destination|monitor_destination/) ){
                call_monitor_destination = value

                if(value && !SETUP && !EDIT) // so kann man einen default value eintragen aber übertippen
                    return null
            }

            let uqrc_element = null
            type = FILTER_TYPES.includes(type) ? 'FILTER' : type

            switch (type) {

				case 'validatedAccess':
                    // validatedAccess wird in type0start gecheckt

                    /* this.rundgangStartClass = e.validatedAccess.match(/QR/) ? 'redBtn' : 'greenBtn'  */

                    break;
				case 'header': //NN-notiz: nicht gut, header refactorn
                    if( e.id_override )
                        this.headerid_override = e.id_override.toLocaleLowerCase()

                    this.headerid = this.headerid_override || id
                    this.updateUqrcType(id) // erstmal nur override der id im header
                    
			 let edp_prefix=e.edpPrefix; 
			    if (edp_prefix == '')
		    {
			    if (types.AI) edp_prefix="aident"; 
	    		}
                    this.edpPrefix = edp_prefix; 
                    if ( this.edpPrefix  ){
                        this.edpPrefix = this.edpPrefix.replace('%qrid%', this.pid)
                    }
                    this.InstanceName = name
                    this.ai = id === 'aident' || id === 'adok'
                    this.lang = e.lang === 'EN' ? 'EN' : 'DE'

                    /* if (id !== 'whatsdown')
                        document.querySelector('#root').style.marginTop = window.customCss.rootOffsetTop //'9vmax'
                    */
                    if (id === 'aident' ||
                        id === 'adok' ||
                        id === 'whatsdown')
                        this.validated = true

                    /* this.setBrowserPageTitle(id)
                    this.setFavicon(id) */
                    if( this.isServiceSelector ){

                        // immer service selector mit mode=checklist aufrufen
                        let url_mode_is_checklist = window.getURLParameter('mode') === 'checklist'
                        if( !url_mode_is_checklist ){
                            window.location.href.append({mode: 'checklist'}).refresh()
                        }

                        uqrc_element = <HeaderServiceSelector key={i} app={this} e={e}/>
                    }else{
                        uqrc_element = <Header name={name} id={id} key={i} pid={this.pid} isAIdent={this.ai} app={this} e={e}/>
                    }

                    // refuse reasons
                    if( e.reason ){
                        if( !this.reasons ){
                            this.reasons = {}
                        }

                        if( e.reason.group ){
                            if( !this.reasons[ e.reason.group ]){
                                this.reasons[ e.reason.group ] = e.reason
                            }
                        }
                    }

                    // success anweisungen
                    if( e.passed ){
                        if( !this.passed ){
                            this.passed = {}
                        }

                        if( e.passed.group ){
                            if( !this.passed[ e.passed.group ]){
                                this.passed[ e.passed.group ] = e.passed
                            }
                        }
                    }
                    


                	break;
				case 'margin':
                    let css = e.css || {}
                    uqrc_element = <div key={i} className='margin' style={css}></div>

                	break;
				case 'divider':
                    uqrc_element = <div key={i} className='divider'></div>


                	break;
                // FEATURE SETCOOKIE
				case 'setcookie':
                    create_modifier = false
                    try {
                        this.cookie.name = e.name
                        this.cookie.visible = e.visible !== 'false'
                        this.cookie.value = JSON.parse( e.value.replace(/'/g, "\"") )
                    } catch (err) {
                        this.cookie.name = null
                        this.cookie.visible = false
                        this.cookie.value = {}
                    }

                    if( e.src ) // nur zum testen von type:logo, ganzer if-block kann eigentlich weg, lasse ich zur sicherheit noch drin
                    {
                        e.src = this.helper + '/img?f=' + e.src + '&cache=' + window.getURLParameter('cache' )
                        uqrc_element = <Image key={i} e={e}/>
                    }else{

                        uqrc_element = null
                    }

                	break;
                // FEATURE SHOWTIME
				case 'datetime':
                    uqrc_element = <ShowTime app={this} key={i} e={e}/>                  

                	break;
                // FEATURE SHOWTIME
				case 'displayTitle':
			    
                    uqrc_element = <div className='displayTitle'>{this.displayTitle}</div>


                	break;
				case 'refuse_text':
                    this.refuse_text = value
                    this.refuse_align = e.align
                    this.refuse_next_button = e.next_button
                    this.post_refuse_text = e.post_refuse
                    uqrc_element = null


                	break;
                // FEATURE INTRODUCTION
				case 'introduction':
                    let intro = this.renderData ? e.post : e.text
                    let isAIdent = false

                    if (e.aident ) {
                        intro = e.aident
                        isAIdent = !this.type.DHC /* true, nur bei DHC nicht */
                    }

                    if( DHC ) // FOR DEBUGGING
                        intro += ` (${this.getRenderDataElement( 'header', 'name' )})`  

                    uqrc_element = <Introduction key={i} name={name} e={e} intro={intro} pid={this.pid} guide={e.guide} headerID={this.headerid} isAIdent={isAIdent} app={this}/>



                	break;
				case 'redbox':
				case 'textbox':
                    // nur bei eingabe anzeigen? => mode.VIEW bzw !(SETUP || EDIT || CHECKLIST)
                    // label / name
                    uqrc_element = /* this.renderSavedInstance  */VIEW ? null : <TextBox key={i} message={e.text} e={e} css={e.css} />

                	break;
				case 'wdintroduction':
                    let wdintro = this.renderData ? e.post : e.text

                    uqrc_element = <WDIntroduction key={i} name={name} intro={wdintro} pid={this.pid} />

                	break;
				case 'confirmbutton':

                    this.push_confirmbutton( e )
                    uqrc_element = null

                	break;
				case 'postdescription2edp':
                    this.postdescription2edp = e
                    uqrc_element = null

                	break;
				case 'postmessage2edp':
                    this.postmessage2edp = e
                    this.table = this.get_table_number( e )
                    uqrc_element = null

                	break;
				case 'directmessage':
                    this.directmessage = e
                    uqrc_element = null


                	break;
				case 'createsmsnotification':
                    // serverside only
                    uqrc_element = null


                	break;
				case 'logoutmessage':
                        this.logoutmessage = e

                    uqrc_element = null

                	break;
				case 'start':

                    if( EINLASSKONTROLLE && this.fromCookieIgnore ){
                        if (id === "start"){
                            
                            uqrc_element = null
                            break;
                        } 
                    }
                    // uqrc_element = null //this.validated = true
                    if (VIEW){
                        uqrc_element = this.validate()
                        break;
                    }

                    startElementInJSON = true

                    if ('validatedAccess' in e)
                        this.validatedAccess = e.validatedAccess.match(/QR/)

                    uqrc_element = <StartButton jsonData={e} parent={this} key={i} />

                	break;
				case 'getImage_':
                    uqrc_element = <div key={i} id='getImage' onClick={
                        () => {
                            //this.getQRCode_('2342315')
                        }
                    }>{'getImage'}</div>

            
                	break;
				case 'iconSelector':
                    uqrc_element = <IconSelector key={i} e={e}/>
            

                	break;
				case 'templateSelector':
                    uqrc_element = <TemplateSelector key={i} e={e} app={this}/>
            

                	break;
				case 'serviceSelector':
                    uqrc_element = <ServiceSelector key={i} e={e} app={this}/>


                	break;
				case 'submit':
                    let box = ''
                    let evaluate = e.evaluate
                    // this.save_checkbox_value_to_renderdata( this.cookiesAllowed )

                    this.submit_ok_url = e.ok_url
                    this.submit_refuse_url = e.refuse_url
                    this._redirect = e.redirect || {}
                    /* this.getCookie(lastpid)
                    if (lastpid) {
                        out an last pid posten allwaysOngetcookie logoutmessage_lastpid Holen 
                        posten die als reply an parendid_lastpid
                    } */
                    let saveText = this.lang.toLocaleLowerCase() === 'en' ? 'Save fields on my device, too' :'Felder für die zukünftige Verwendung auf meinem Gerät speichern' 
                    let setcookieInJson = this.getRenderDataElement('setcookie')
                    if (setcookieInJson && this.showCookieCheckbox && this.cookie.visible && !this.fromCookieIgnore){
                        box = <CheckBox 
                                    text     = { saveText }
                                    callback = {( checked )=> {

                                            this.cookiesAllowed = checked
                                            this.save_checkbox_value_to_renderdata( checked )
                                            this.setCookie('cookiesAllowed', checked ? 'true' : 'false', 1000)

                                            for (let key in this.cookieCheckboxRefs) {
                                                if(this.cookieCheckboxRefs.hasOwnProperty(key)){
                                                    this.cookieCheckboxRefs[key].setState({checked:checked})
                                                }
                                            }

                                            this.saveInputsToCookie()
                                    }}

                                    init        = { this.cookiesAllowed }
                                    collapsable = { e.collapsable       }
                                    section     = { activeSection       }
                                    collapse    = { collapseSection     }
                                    ref         = { c => { if(c) this.addCookieCheckboxRef(c) }} 
                                />
                    }


                    if( (EDIT || SETUP || CHECKLIST) && this.validated ){
                        uqrc_element = (<div key={i}>
                            {box}
                            <Submit
                                collapsable={e.collapsable}
                                section={activeSection}
                                collapse={collapseSection}
                                active={this.state.showSubmit}
                                name={name}
                                hid={this.headerid}
                                submit={() => this.send( null, id, this.submits[i].props.section, evaluate )}
                                app={this}
                                id={id}
                                ref={c => { if(c) this.submits[i] = c } } 
                                e={e}
                            />
                        </div>)

                        break;
                    }else{
                        uqrc_element = null
                        break;
                    }


                	break;
				case 'submit_intermediate':
                    uqrc_element = (
                        <div key={i} id="submit_intermediate" className='submit' onClick={() => this.save_intermediate()}>!Zwischenspeichern!
                        </div>
                    )


                	break;
				case 'html':
                    uqrc_element = <div key={i} className={/* activeSection + */ ' userHTML'} dangerouslySetInnerHTML={{ __html: e.html }} data-display={collapseSection ? 'hide' : 'show'} style={e.css} ></div>

                	break;
				case 'checkit': // console.log(' HTMLtoJSON() checklist')
                    checkit_enumerator += 1

                    if( EINLASSKONTROLLE && this.fromCookieIgnore ){
                        if (id.includes('frage')){
                            uqrc_element = null
                            break;
                        } 
                    }


                    if (!startElementInJSON)
                        this.validate()

                    this.firstElement = this.firstElement || i

                    const USE_CHECKLIST_CARDS = true // switch in json einbauen?
                    if( USE_CHECKLIST_CARDS ){
                        uqrc_element = <CheckListCard
                            disabled={!this.validated && !allwaysOn}
                            index={i}
                            key={i}
                            e={e}
                            parent={this}
                            section={activeSection}
                            collapse={collapseSection}
                            ref={c => this.rows[i] = c}
                            ai={this.ai}
                            enum={checkit_enumerator}
                        />

                        break;
                    }else{
                        uqrc_element = <CheckListItem
                            disabled={!this.validated && !allwaysOn}
                            index={i}
                            key={i}
                            e={e}
                            parent={this}
                            section={activeSection}
                            collapse={collapseSection}
                            ref={c => this.rows[i] = c}
                            ai={this.ai}
                        />
                        break;
                    }


                	break;
				case 'insert_messages':
                    console.log('insert_messages', this.messages)
                    uqrc_element = <LastMessages app={this} e={e}/>


                	break;
				case 'section':
			    if (json[i+1].type==='copy_from_setup' || json[i+1].type==='insert_documents' || json[i+1].type==='insert_messages')
			    {
				    json[i+2].collapse='false'
				    json[i+2].name="Störungsmeldung" // dieser name scheint völlig egal, weil man den sowieso nicht sieht... könnten also weg 19.02.2021
				    uqrc_element = null
                    break;
			    }

                    activeSection = name.replace(re, '_')
                    IS_REAL_SECTION = true
                    collapseSection = e.collapse === 'true' || parseInt(e.collapse) === 1

                    if (!startElementInJSON)
                        this.validate()

                    if (e.isHeader && e.isHeader === 'true')
                        this.headerElements.push(name)

                    uqrc_element = <Section key={i}
                        e={e}
                        app={this}
                        name={name}
                        disabled={!this.validated && !allwaysOn}
                        section={activeSection}
                        toggledCollapse={this.toggledCollapse}
                        ref={c => this.sections[name] = c} />


                	break;
				case 'photo':
                    if( !EDIT && !SETUP && !e.file ){
                        uqrc_element = null
                        break;
                    }                    

                    if( VIEW ){
                        if( e.file ){
                            uqrc_element = <PhotoDisplay key={i} e={e} app={this}/>
                        }else {
                            uqrc_element = null
                        }
                    }else{
                        uqrc_element = <PhotoAdd key={i} e={e} app={this}/>
                    }

                	break;
				case 'document':
                    let { filename } = e

                    if( !filename ){
                        filename = 'document.pdf' 
                    }

                    if( filename && !filename.includes('.pdf') ){
                        filename += '.pdf' 
                    }

                    let doc = e.file ? this.helper + '/pdf?f=' + e.file + '&n=' + filename + '&cache=' + window.getURLParameter('cache' ) : ''
                  
                  

                    console.log('(WD && CHECKLIST): %o && %o', WD, CHECKLIST )
                    uqrc_element = /* this.renderSavedInstance  */ VIEW || (WD && CHECKLIST)?
                        e.file ? <DocumentDisplay key={i} app={this} name={e.post || e.name} file={doc} section={activeSection}/> : null
                        :
                        <DocumentAdd e={e} key={i} app={this} setImageRef={this.setImageRef} resetImageRef={this.resetImageRef} file={doc} />


                	break;
				case 'files':

                    let permid = window.getURLParameter( 'folder' ) || this.folder.root
                    uqrc_element = <FileList key={i} app={ this } folder={ this.folder } permid={ permid } is_root={ true } e={e}/>

                	break;
				case 'socialmedia':
                    uqrc_element = <Socialmedia key={i}/>

                	break;
				case 'agb':
                    console.timeLog('this.headerid ', this.headerid)
                    if( this.headerid === 'adok' ){
                        uqrc_element = <AGBAdok e={e} key={i}/>
                        break;
                    }else{
                        uqrc_element = <AGB e={e} key={i}/>
                        break;
                    }

                	break;
				case 'insert_element':
                    uqrc_element = <InsertElement e={e} app={this} key={i}/>

                	break;
				case 'checkbox':
                    uqrc_element = <div className='checkbox_container'>
                        <input type="checkbox" className='checkbox' id={id} name={name}></input>
                        <label for={id}  >{name}</label>
                    </div>

                    // uqrc_element = <input type="checkbox" id={id} name={name}></input>
                    /* uqrc_element = <div onClick={()=> {window.checkboxMessage = name}}>
                        {name}
                    </div> */
                    
                	break;
				case 'signature':
                    
                    uqrc_element = <Signature key={i} app={this} e={e} ref={c => { if(c) this.signatures[i] = c }} />
                
                	break;
				case 'quickmessage':
                    
                    if(!e.body){
                        e.body = '%value%' //value || name
                    }
                    
                    uqrc_element = this.mode.SETUP ?
                        <Input
                            app={this}
                            e={e}
                            section={activeSection}
                            key={i}
                            idx={i}
                            id={id}
                            name={e.name}
                            placeholder={e.placeholder}
                            value={value}
                            type='text'
                            minus={true}
                            saveCurrent={this.HTMLtoJSON}
                            onKeyUp={this.inputKeyUpHandler}
                            required={true}
                            ref={c => { if(c) this.inputs[i] = c } } 
                        />
                        :
                        <Submit
                            key={i}
                            id={id}
                            active={true/* this.state.showSubmit */}
                            name={value}
                            section={activeSection}
                            collapse={collapseSection}
                            hid={this.headerid}
                            //submit={() => this.send ({body: value, privacy: e.privacy }, id)}
                            submit={ async () => {
                                
                                if(id.includes(global.CONTACT_DIRECT)){

                                    document.location.href="tel:"+contact_route

                                    let call_monitor_destination_rd = this.getRenderDataElement( null, 'value', 'call_monitor_destination')
                                    let call_monitor_destination_rd2 = this.getRenderDataElement( null, 'value', 'monitor_destination')

                                    //call_monitor_destination = '...'
                                    let dest = call_monitor_destination ? call_monitor_destination : call_monitor_destination_rd ? call_monitor_destination_rd : call_monitor_destination_rd2
                                    console.log('dest', dest)

                                    this.postJsonMessages2EDP({subject: 'Anruf-Protokollierung:', body: `Anruf bei ${contact_route} gestartet`, privacy: 'privacy', destination: dest }, null, 'callMonitor')

                                    return null
                                }

                                if(!this.disableMessagePost){
                                    let body = e.body
                                    /* if(e.checkboxes && window.checkboxMessage)
                                        body = window.checkboxMessage */

                                        /* if (!default_destination){
                                            default_destination = this.getRenderDataElement( null, 'value', 'default_destination')
                                        } */

                                    this.grayOutButton(id)
                                    this.showLoader()
                                    let send_ok = await this.postJsonMessages2EDP( { subject: e.subject, body: body, privacy: e.privacy, display_value: e.value, destination: contact_route || default_destination || this.getRenderDataElement( null, 'value', 'default_destination') }, quickmsg || value || name, 'disableTouch', 'isQuickmessage')
                                    if( !send_ok ){
                                        this.degrayButton(id || 'submit')
                                    }
                                }
                            }}
                            app={this}
                            e={e}
                            ref={c => { if(c) this.inputs[i] = c } } 
                        />

                	break;
				case 'image':
                    uqrc_element = <Image key={i} e={e} app={this}/>

                	break;
				case 'logo':
                    uqrc_element = <Image key={i} e={e} app={this}/>


                	break;
				case 'text':
                    if( id === "deviceName" || name === "deviceName" ){
                        create_modifier = false
                    }

                    if (id === "preregistraionemail" && window.getURLParameter('usecheckliste') !== '' ){
                        // wenn schon ausgefüllte und gespeicherte checklsite, dann kein email mehr schicken
                        uqrc_element = "" 
                        break;
                    }

                    if( EINLASSKONTROLLE && !this.fromCookieIgnore ){
                        if (id === "teilnehmerqrid") 
                        {
                            if( name === 'autogenerated'){
                                value = uuidv4()

                            }
                            // 130421: mit AH für dhc prereg auskommentiert
                            /* else{
                                retur_n ""
                            } */
                        }
                    }
                    if (id === "absender"){
                        document.getElementById('popcontact').style.display = 'block'
                        //retur_n "" 
			            ////ersetzt durch display:"invisible"
                    } 
                	// break;
				case 'number':
				case 'select':
				case 'datetime-local':
				case 'email':


                    if (!startElementInJSON)
                        this.validate()
                    // weitere input types?

                    if(!value && e.read_from_cookie && !this.fromCookieIgnore ){
                        value = this.readValueFromCookie(id, e.read_from_cookie)
                        this.renderData[i]['value'] = value
                    }

                    if(!value && e.read_from_url ){
                        let url_val = window.getURLParameter( e.read_from_url )
                        if( url_val )
                            value = url_val

                        this.renderData[i]['value'] = value
                    }
                    

                    uqrc_element = value || !this.mode.VIEW ?
                        <Input
                            app={this}
                            e={e}
                            multiline={e.line === 'multi'}
                            disabled={!this.validated}  // TODO: validation prozess für UQRC setup unpassend
                            options={e.options}
                            section={activeSection}
                            collapse={collapseSection}
                            placeholder={e.placeholder}
                            key={i}
                            idx={i}
                            id={id}
                            name={name}
                            value={value}
                            type={type}
                            saveCurrent={this.HTMLtoJSON}
                            onKeyUp={this.inputKeyUpHandler}
                            required={required}
                            ref={c => { if(c) this.inputs[i] = c } } />
                        :
                        null

                	break;
				case 'service':

                    uqrc_element = <IconButton onClick={ () => {this.navigateToSelectedService(e)}} icon={e.icon} title={e.title} text={e.text} next={e.next} app={this} e={e} key={i}/>

                    break;
				case 'home':

                    /* this.setHome(e)

                    uqrc_element = <Home app={this} e={e} key={i}/> */

                    break;
				case 'back':

                    uqrc_element = <Back app={this} e={e} key={i}/>

                    break;
				case 'wdmessage':

                    uqrc_element = <NewReport selected={true} intro={'XXXX'} root={null} />

                    break;
				case 'FILTER':
                    create_modifier = false
                    uqrc_element = null

                    break;
                default:
                    create_modifier = false
                    uqrc_element = <UnknownElement type={type} name={name} key={i}/>
            } // switch


            let elem_cls = window.getURLParameter('class')
            if( elem_cls ){ // nur wenn class=... gesetzt ist
                if( /* e.class === elem_cls ||  */e.elementClass === elem_cls ){
                    if( e.backgroundImage ){
                        this.selectorBackgroundImage = e.backgroundImage
                        this.showServiceSelectorBackground()
                    }
                }
            }

            // return this.filterByElementClass(uqrc_element, e)

            // filterByElementClass in construct-mode unwirksam
            if( this.mode.CONSTRUCT ){
                
                if( /* create_modifier */ true){
                    createdHTML.push( this.createModifier(e, uqrc_element) )
                }else{
                    createdHTML.push( uqrc_element )
                }
            }else{
                createdHTML.push( this.filterByElementClass(uqrc_element, e))
            }
        //}) // map
        }


        if(this.mode.SETUP){
            if( this.headerid === 'adok' ){
                createdHTML.push(<AGBAdok key={createdHTML.length + 1}/>)
            }else if( this.headerid === 'oma' ){
                // createdHTML.push(<AGBAdok key={createdHTML.length + 1}/>)
            }else{
                createdHTML.push(<AGB key={createdHTML.length + 1}/>)
            }
        }
        return createdHTML
    } // json to html



    setHome( e = {} ){

        let url = document.location.href
        let query = document.location.search
        let url_params = new URLSearchParams( query )

        // get forbidden params
        let reject_params = ['class'/* , 'folder' */]
        if( e.reject ){
            reject_params = reject_params.concat( e.reject )
        }

        // check if forbidden params in url
        let reject = false
        for (let i = 0; i < reject_params.length; i++) {
            const entry = reject_params[i]
            if( url_params.has( entry )){
                reject = true
            }
        }

        // set home url
        let is_home_url = !reject
        if( is_home_url ){
            let data = JSON.stringify({ home: e.home || url })
            
            let usecheckliste = url_params.get( 'usecheckliste' )
            if( usecheckliste ){
                localStorage.setItem( usecheckliste, data )
            }

            localStorage.setItem( this.pid, data )
        }
    }


    navigateToSelectedService(e){
        let no__nav = e.navigation === 'none'
        let url_nav = e.navigation === 'url'
        if( url_nav ){
            if( e.redirect ){
                window.location.href = e.redirect
            }else{
                let {urlParams} = e
                let allParams = Object.assign({}, { class: e.class }, urlParams)
                url_append( allParams ).refresh()
            }
        }else if( no__nav ){
            
            // no-op
        }else{
            
            this.setElementClass(e)
        }
    }


    setElementClass( element ){
        if( !this.selectorHistory ){
            this.selectorHistory = []
        }

        this.selectorHistory.push( element )
        this.forceUpdate()
    }


    popLastElementClassFromSelectorHistory(){
        let hist = this.selectorHistory
        if( hist && hist.length ){
            this.selectorHistory.pop()
            this.forceUpdate()
        }
    }


    filterByElementClass(uqrc_element, e){
        if( this.isServiceSelector ){ // style: service selector

            /* 
                die history ist dafür gedacht,
                einen single-page-selector mit mehrstufiger 
                service-auswahl zu implementieren
            */
            let sel_hist = this.selectorHistory
            if( sel_hist ){ // wenn history initialisiert
                let hist_len = sel_hist.length
                if( hist_len ){ // wenn einträge in history (also wurde bereits navigiert)
                    if( e.elementClass ){ 
                        if( e.elementClass.includes( sel_hist[ hist_len-1 ].class ) ){ // wenn history: zeige nur passende elementClass
                            return uqrc_element 
                        }else{
                            return null
                        }
                    }else{ /* 
                            wenn elementClass undefined oder leer,
                            filtere elemente (bis auf den header,
                            der soll per default immer angezeigt werden,
                            wenn er keine elementClass hat)
                         */
                        if( e.type === 'header' ){
                            return uqrc_element
                        }else{
                            return null 
                        }
                    }
                }else{ // wenn history noch frisch/leer (selector- hauptseite)
                    if( e.elementClass ){ // verstecke alle mit elementClass
                        if( e.type === 'header' ){ // ...bis auf header, der soll auf der service-selector-hauptseite sichbar bleiben
                            return uqrc_element
                        }else{
                            return null 
                        }
                    }else{
                        return uqrc_element
                    }
                }
            }else{ // wenn keine history ...
                if( e.elementClass ){   // ...verstecke alle mit elementClass...
                    return null
                }else{
                    return uqrc_element // ...zeige nur elemente ohne elementClass
                }
            }
        }else{ // style: normal, nicht service selector 
            return uqrc_element
        }
    }


    activateVerifiedBtn() {
        this.validated = true

        this.startButton.classList.toggle("redBtn")
        this.startButton.classList.toggle("greenBtn")
        this.forceUpdate()
    }


    async getQRCode(id) {

        let res = '000000'
        if (window.mag_webkit) {
            res = await window.OpenCamera(id, 'qrcode')
            res = decodeURI(res)
        }

        if (res.includes(this.pid) || !window.mag_webkit) // TEMP 13.9. auskommentiert
            this.activateVerifiedBtn()
        else {
            if (this.LocationNoteRef != null) {
                this.LocationNoteRef.show()
            } else
                log('LocationNoteRef undefined')
        }
    }


    async getSetupImage(id, mode) {

        //!isAndroid: klicked Foto aufnehmen ->  in: getSetupImage()'

        // TODO : 'imageData' is assigned a value but never used 
        // let imageData //= "https://vignette.wikia.nocookie.net/asterix/images/9/9f/Asterix%2C_Obelix_%26_Dogmatix.png/revision/latest?cb=20110324122229"

        if (window.mag_webkit) {
      //!isAndroid: klicked Foto aufnehmen ->  in: getSetupImage() ... window.mag_webkit = true '
      /* imageData =  */await window.OpenCamera(id, mode)

            /* imageData += '&srts=' + new Date().getTime() */
        }
    }


    set_error( e ){
        this.exists_error = e
    }



    render() {
        //throw new Error('ERROR THROWN')

        if( this.dont_render ) // AD
            return null

        if( this.blueErrorPage.display ){
            // return blu error page
            return <BlueErrorPage app={this} />
        }

        if( SETUP && this.jwt.expired() )
        {
            return <BlueErrorPage text='Mögliche Ursache: Um auf diese Seite zugreifen zu können, müssen Sie authentifiziert sein.' />
        }

        if( this.renderData && this.renderData.code === 404 )
        {
            return <BlueErrorPage text='Mögliche Ursache: Die angeforderte Datei konnte nicht geladen werden.' />
        }

        

        this.inputs.length = 0
        let renderOutput = ''
        if (this.renderData && this.renderData !== {}){
            if((NGROK_SERVER || LOCALDEV ) && !this.injectedDebugElements){
                this.injectedDebugElements = true
                /*           
                this.renderData.insert(
                    8,
                    [{        
                        "id": "documents_list",
                        "type": "files",
                        "name": "Dateien zum Objekt:",
                    }]
                )
                 */
            }

            renderOutput = this.JSONtoHTML(this.renderData)
            if( window.getTemplateName() !== SERVICE && !SETUP_SUCCESS && !AI && LOCALDEV ){ // im setup_succes wird diese warnung bereits leicht anders ausgegeben
                // 071121: nervt, muss weniger streng sein
                // window.showError( `Warning: Type Conflict: Url Type '${window.getTemplateName()}' diverges from Checklist Type '${SERVICE}'.` )
            }
        }
        
        

        if (!this.pid || !this.template)
            return <Error meta={META} app={this} />


        if (this.skipFirstRender)
            return this.skipFirstRender = false

        

        if(LOGGED_IN && !SETUP_SUCCESS)
	    {
		    if (this.lang.match(/EN/i)) 
            return <CdapLogoutEN app={this}/> // <div>LOGGED IN SINCE {this.getCookie('login')} - SHOW LOGOUT BUTTON </div>
		    else
            return <CdapLogout app={this}/> // <div>LOGGED IN SINCE {this.getCookie('login')} - SHOW LOGOUT BUTTON </div>
	    }

        let showMenu = MENU && (AI || VB || WD || CDAP || (DHC && !SETUP) ) && VIEW
        if (showMenu || INTRO ){
            if(WD)
                window.location.href.append({mode:'checklist'}).refresh() // TODO: OHNE REDIRECT!!
            else
                if ( (this.source !== 'edp' && AI) || !AI ) return <Start url={_URL} app={this} menu={showMenu} />
        }
        
        

        if (SETUP_SUCCESS)
	    {
		    if (this.lang.match(/EN/i)) 
            return <SetupSuccessEN url={_URL} meta={META} app={this} />
			    else
            return <SetupSuccess url={_URL} meta={META} app={this} />
	    }


        

        if (this.renderData) {
            return (
                <DocumentMeta {...META} >
                    <LocalDevIcon />

                    {renderOutput}
                    <NewProtocol app={this} />
                    <Edit url={_URL} app={this} />

                    {this.LocationNoteElem}
                    {this.NoChangeRights}
                </DocumentMeta>
            )

        }

        

        return null
    }
}

export default App

