import React, { Component } from 'react'
import Note from './Note'
import callIcon from './common/svg/call_green.svg'
import minusCircle from './svg/minus_circle.svg'

var server
const patterns = {
    email: "[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$",
    date: "(0[1-9]|[12][0-9]|3[01])\\.(0[1-9]|1[0-2])\\.\\d{4}",
    undefined: null // ".*"
}

class OldSchoolInput extends Component {
    constructor(props, context) {
        super(props, context)

        this.app = this.props.app
        this.input = null

        this.state = {
            value: this.props.value || '',
            redBorder: false
        }

        this.setNoteRef = (i, t) => {
            this.qrCodeNoteRef = t
        }

        this.handleChange = (e) => {
            this.props.app.renderData[this.props.idx].value = e.target.value
            this.props.app.check_conditions()
            this.setState({ value: e.target.value })
        }

        this.toggleRedBorder = () => {
            if( !this.required )
                return this.setState({ redBorder: false })

            let self = this

            setTimeout(function () {
                let empty = self.state.value === '' || ( self.props.type === 'select' && self.state.value === '0' )
                let inspected = self.app.inspectedRequiredFields
                let invalid = !self.inputPatternValidity()
                let wrong_date = self.props.e.date_validity === false

                let rb = ( empty || invalid || wrong_date ) && inspected
                self.setState({ redBorder: rb })
            }, 30)
        }

        this.onFocusHandler = this.onFocusHandler.bind(this)
        this.onKeyUpHandler = this.onKeyUpHandler.bind(this)
        this.inputPatternValidity = this.inputPatternValidity.bind(this)
        this.showInputNotification = this.showInputNotification.bind(this)
        this.hideInputNotification = this.hideInputNotification.bind(this)

        this.qrCodeNoteElem = <Note content='Bitte QR-Code scannen um Rundgang zu starten!' setNoteRef={this.setNoteRef} />
    }


    inputPatternValidity(){
        if( this.input ){
            let ret = this.input.validity.valid
            return ret // patternMissmatch || valueMissing ...
        }else{
            return true
        }    
    }

    showInputNotification( msg ){
        this.showInputNotificationMessage = msg
    }

    hideInputNotification(){
        this.showInputNotificationMessage = false
    }


    componentDidUpdate(){
        server = window.server

        if(this.app.renderData[this.props.idx].value && window.elementDeleted){

            if(!window.deletedElementTimeout){
                window.deletedElementTimeout = true
                setTimeout(() => {
                    window.elementDeleted = false
                }, 100)
            }
            
            if( this.state.value !== this.app.renderData[this.props.idx].value ){
                
                this.setState({value: this.app.renderData[this.props.idx].value})
            }
        }
    }



    componentDidMount() {
        this.required = this.props.required
        this.section = this.props.section

        if (this.props.id === 'deviceName')
            this.refs.devNameInput.focus()

        
        let self = this
        
        try {
            self.refs.thisElement.dataset.clientHeight = self.refs.thisElement.clientHeight
            self.refs.thisElement.dataset.display = self.props.collapse ? 'hide' : 'show'
        } catch (error) {
            let trySetData = setInterval(function(){

                if(self && self.refs && self.refs.thisElement ){
                    self.refs.thisElement.dataset.clientHeight = self.refs.thisElement.clientHeight
                    self.refs.thisElement.dataset.display = self.props.collapse ? 'hide' : 'show'
                    clearInterval(trySetData)
                }
            }, 250)
        }
    }


    onFocusHandler(e) {

        /* hide popup */
        window.close_popup()
        
        if( /* todo: force_message_text */ false ){

            if (this.app.unfinishedModal()) {
                e.preventDefault()
                e.target.blur()
                this.app.getOpenModal().notes['noText'].show()
                return
            }
            this.app.closeOthers(9999)
        }

        if (this.props.disabled) this.qrCodeNoteRef.show()
    }


    onKeyUpHandler(e) {

        if (e.keyCode === 13 && !this.props.multiline) {
            // blur
            e.target.blur()

            // safe parentSection & this.ID
            // let parentSection  = this.props.section // e.target.dataset.parentSection
            let currentInputID = e.target.id

            // check if next is in same section
            let inputList = {}
            try {
                let section = this.props.section ? this.props.section : 'default_section'
                inputList = document.querySelectorAll('.' + section + '>div>input')
            } catch (err) {
                console.log(err)
            }
            let focusNextInput = false
            let done = false

            Object.keys(inputList).forEach(function (key, index) {
                if (done)
                    return

                if (focusNextInput) {
                    this[key].focus()
                    done = true
                    return
                }

                if (this[key].id === currentInputID && index < inputList.length)
                    focusNextInput = true

            }, inputList)

        }

        this.app.checklistFinished()
        this.toggleRedBorder() // this.props.onKeyUp(e) // toggleRedBorder
    }


    delete(e){
        let { id } = e.target.dataset
        let del = null
        let del_count = 1

        this.app.renderData.map((e, i)=>{
            if( e.id === id ) del = i
            if( e.mode === 'extended' ){
                let next_1 = this.app.renderData[i+1]
                let next_2 = this.app.renderData[i+2]
                let next_3 = this.app.renderData[i+3]
                if(next_1 && next_1.id && next_1.id.includes(id)) del_count++
                if(next_2 && next_2.id && next_2.id.includes(id)) del_count++
                if(next_3 && next_3.id && next_3.id.includes(id)) del_count++
            }

            return null
        })

        this.app.renderData.splice(del, del_count)
        this.app.inputs.splice(del, del_count)
        global.insert_element_index -= del_count
        window.elementDeleted = true

        this.app.forceUpdate()
    }

    get_regex_pattern(){
        return patterns[ this.props.e.pattern ] || null
        /* let pattern_type = this.props.e.pattern
        if( pattern_type ){

            let pattern_string = patterns[ pattern_type ]
            if( pattern_string )
                return pattern_string
        }
        return null */ //'^$|.{0,}'
    }


    render() {

        this.required = this.props.required
        var re = new RegExp('_', 'g');

        let pattern = this.get_regex_pattern()
        let adok_input = ''
        if( this.app.headerid === 'adok')
            adok_input = 'adok_input'

        let redBorder = this.state.redBorder ? '.4rem solid red' : ''

        //  let value = this.props.value? this.props.value : null
        //let value = this.state.value? this.state.value : null
        let { type, id, name, multiline, required, disabled, options, e, minus } = this.props
        let { VIEW, SETUP, EDIT } = this.app.mode

        let autocomplete = e.autocomplete || 'false' //(parseBool(e.autocomplete) && !this.app.fromCookieIgnore).toString() // autoComplete needs string

        let HTML_SELECT    = type === 'select'
        let OPTION_SELECTED = this.state.value !== 'default' && this.state.value
        let DISPLAY_DISABLED = ((!SETUP && e.display === 'disabled') || disabled ) && OPTION_SELECTED
        let RENDER_INVISIBLE = !SETUP && e.display === 'invisible' && OPTION_SELECTED
        let RENDER_INVISIBLE_INPUT = e.display === 'invisible'
        // let RENDER_DISABLED_INPUT = e.display === 'disabled'

        let pholder = name + ( required && !VIEW && name ? '*' : '')

        let incss = { color: '#535353', borderBottom: 'none' }
        let lightGray = DISPLAY_DISABLED ? { color: '#aaa' } : {} 

        let input = ''
        if( VIEW ){ // not editable ui:
            let inputVariant = ''

            if( multiline ){
                //inputVariant = <textarea readOnly className='multiLineInput' id={id} value={this.state.value} disabled={ DISPLAY_DISABLED ? true : null}></textarea>
                let html_val = this.state.value.replace(/(?:\r\n|\r|\n)/g, '<br/>')
                inputVariant = <div id={id} className='OldSchoolInputReadOnly' style={incss} dangerouslySetInnerHTML={{ __html: html_val }} ></div>
            }else{
                inputVariant = <div id={id} className='OldSchoolInputReadOnly' style={incss} >
                                {
                                    id === 'emergency' || ( name && name.includes('Notfall')) ?
                                        <a href={'tel:' + this.state.value}>{this.state.value} <img src={callIcon} className='callIcon' alt='call' /></a>
                                        :
                                        this.state.value
                                }
                                </div>
            }

            input = <div>
                    <label htmlFor={id} className='OldSchoolInputLabel' data-service={this.app.type.SERVICE} >{pholder}: </label>
                    {
                        inputVariant
                    }
                </div>
        
        // EDIT / SETUP
        } else {
            let inputVariant = ''

            if( multiline ){
                inputVariant =  <textarea className='multiLineInput'
                                            id={id}
                                            ref={(e)=>this.input=e}
                                            value={this.state.value}
                                            onBlur={this.props.saveCurrent}
                                            onChange={this.handleChange}
                                            onKeyUp={this.onKeyUpHandler}
                                            onFocus={this.onFocusHandler}
                                            data-parent-section={this.props.section.replace(re, ' ')}
                                            required={this.props.required}
                                            autoComplete = {autocomplete}
                                            placeholder={this.props.placeholder}
                                            disabled={ DISPLAY_DISABLED ? true : null}
                                        >
                                </textarea>
            } else if ( HTML_SELECT ){

                let optionsList = options.map((e, i) => {
                    //let ov = e.optionvalue
                    let val = /* ov ? ov : */ e.id
                    return <option value={val} key={i}>{e.name}</option>
                })

                inputVariant = <div className='select-wrapper' style={{ /* transition: 'all 0.2s ease', */ border: redBorder, borderRadius: '.5rem' }}>
                    <select 
                        disabled={ DISPLAY_DISABLED ? true : null}
                        value={this.state.value}
                        name={name} 
                        id={/* name.replace(/\s/g, '_') */id}
                        ref={(e)=>this.input=e}
                        style={lightGray} 
                        data-required={required}
                        onChange={(e)=>{
                            this.app.checklistFinished()
                            this.toggleRedBorder()
                            this.handleChange(e)
                            this.app.forceUpdate()
                        }}
                        autoComplete = {autocomplete}
                    >
                    {optionsList}
                    </select>
                </div>

            } else {
                inputVariant =  <input type={type}
                                            id={id}
                                            style={{ 
                                                transition: 'all 0.2s ease', 
                                                border: redBorder,
                                                display: RENDER_INVISIBLE || RENDER_INVISIBLE_INPUT || !name ? 'none' : '-webkit-inline-box' ,
                                                width: '100%'// '-webkit-fill-available',
                                                //width: '100%'// 'fill-available'//'-webkit-fill-available'
                                            }}
                                            className={'OldSchoolInput ' + adok_input}
                                            pattern={ pattern }
                                            ref={(e)=>this.input=e}
                                            value={this.state.value}
                                            onBlur={this.props.saveCurrent}
                                            onChange={this.handleChange}
                                            onKeyUp={this.onKeyUpHandler}
                                            onFocus={this.onFocusHandler}
                                            data-parent-section={this.props.section.replace(re, ' ')}
                                            required={this.props.required}
                                            placeholder={this.props.placeholder}
                                            max="2030-06-10T00:00"
                                            autoComplete = {autocomplete}
                                            disabled={ DISPLAY_DISABLED ? true : null}
                                />
            }

            input = <div>
                        <label htmlFor={id} className='OldSchoolInputLabel' style={{ display: RENDER_INVISIBLE || RENDER_INVISIBLE_INPUT || !name ? 'none' : '-webkit-inline-box' }} >{pholder} </label>
                        <table style={{display: "block"}}>
                            <tbody style={{display: "block"}}>
                                <tr style={{display: "flex"}}>
                                    <td style={{/* paddingLeft: '1rem',  */width: minus ? '90%' : '100%'}}>{ inputVariant }</td>
                                    
                                    {
                                        minus ?
                                        <td className='input_td_minus' ><img className='minus' src={minusCircle} data-id={id} onClick={(e)=>this.delete(e)} alt='-'/></td>
                                        :
                                        null
                                    }
                                    
                                </tr>

                                {
                                    this.showInputNotificationMessage ?
                                    <tr>
                                        <td className='showInputNotificationMessage'>{this.showInputNotificationMessage}</td>
                                    </tr>
                                    :
                                    null
                                }

                            </tbody>
                        </table>
                        
                        { /* inputVariant  */}
                        {
                            /* minus ?
                                <img className='minus' src={minusCircle} alt='-'/>
                            :
                            '' */
                        }
                    </div>
        }

        if (this.props.id === 'deviceName') // WD Setup
            input = 
            <div>
                <label htmlFor={id} className='OldSchoolInputLabel' style={{ display: RENDER_INVISIBLE || RENDER_INVISIBLE_INPUT || !name ? 'none' : '-webkit-inline-box' }} >{pholder} </label>
                <input
                    type={type}
                    id={id}
                    style={{ display: RENDER_INVISIBLE || RENDER_INVISIBLE_INPUT || !name ? 'none' : '-webkit-inline-box'}}
                    placeholder={/* this.props.name */''}
                    className={'OldSchoolInput ' + adok_input}
                    pattern={ pattern }
                    ref='devNameInput'
                    onBlur={this.props.saveCurrent}
                    onKeyUp={this.props.onKeyUp}
                    onChange={this.handleChange}
                    autoComplete = {autocomplete}
                    value={this.state.value}
                />
            </div>

        let additionalClasses = this.props.e.classes ? this.props.e.classes : ''

        return (
            <div style={ Object.assign({ /* display: DISPLAY_DISABLED ? 0.25 : 1.0 */ }, this.props.e.css )} key={this.props.idx} className={this.props.section + ' OldSchoolInputContainer ' + additionalClasses } ref='thisElement'>
                <div style={{ opacity: DISPLAY_DISABLED ? 0.25 : 1.0 }} >

                    {input}

                </div>
                {this.qrCodeNoteElem}
            </div>
        )
    }
}

export default OldSchoolInput


