import React, { Component } from 'react'
import digicam from './svg/digicam.svg'
import cross from './svg/crossGrey.svg'
import { log, clog } from '../helpers/PrintHTMLDebug'

class PhotoAdd extends Component {
    constructor(props, context) {
        super(props, context)

        this.state = {
            photoAttached: false,
            zoom: false
        }

        this.createFileSource = ( file, host ) => {
            if( file ){
                if( file.indexOf('http') === 0 ){
                    return file
                }else{
                    return host + '/img?f=' + file + '&cache=' + window.getURLParameter('cache' )
                }
            }else{
                return ''
            }
        }

        this.app = this.props.app
        this.file = this.createFileSource( this.props.e.file, this.app.helper )
        this.photoPreviewID = this.props.e.id + 'Preview'
        this.photoInputID = this.props.e.id + 'InputFile'
        this.name = this.props.e.camtext || this.props.e.name
        this.acceptFileTypes = this.props.e.acceptFileTypes

        this.setImageRef = this.app.setImageRef
        this.resetImageRef = this.app.resetImageRef

        this.onPhotoChange = this.onPhotoChange.bind(this)
        this.removePhoto = this.removePhoto.bind(this)
    }


    componentDidMount() {
        /* this.photoPreviewID = "photoPreview" + this.props.e.id */
    }


    onPhotoChange(evt) {

        let self = this

        var tgt = evt.target || window.event.srcElement,
            files = tgt.files

        if (FileReader && files && files.length) {
            var fr = new FileReader()
            fr.onload = function () {
                try {
                    let img = document.getElementById(self.photoPreviewID)
                    img.src = fr.result
                    setTimeout(()=>{
                        img.scrollIntoView({block: "end", behavior: "smooth"})
                    }, 200)
                    self.fileName = files[0].name
                    self.setImageRef(self.photoPreviewID, files[0], files[0].type)
                    self.setPhotoAttachedFlag( true )
                } catch (error) {
                    console.log(error)
                }
            }

            fr.readAsDataURL(files[0])
        }
    }


    removePhoto() {
        try {
            document.getElementById(this.photoPreviewID).src = null 
            this.resetImageRef(this.photoPreviewID)
            this.setPhotoAttachedFlag( false )
        } catch (error) {
            console.log(error) 
        }
    }


    toggleZoom(){
        this.setState({zoom: !this.state.zoom}) 
    }


    setPhotoAttachedFlag( value ){
        this.setState({ photoAttached: value })
    }


    isPhotoAttached(){
        return this.state.photoAttached
    }


    validPhotoURL(){
        return this.file && !this.file.includes('=undefined') 
    }



    render() {
        // file = file === '' ? 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==' : file // transparent pixel
        let is_set = this.isPhotoAttached() || this.validPhotoURL()
        let display_css = { display: is_set ? 'block' : 'none' }
        let zoom_css = this.state.zoom ? { /* maxWidth: '78vw' */ } : {}
        
        return (
            <div className='PhotoAddRow' onClick={ ()=>{}/* () => this.app.closeOthers(9999) */ }> {/* closeOthers() schließt auch das card-element, in dem addPhoto ist */}
                <table className='PhotoAddTable' >
                    <tbody>
                        { this.isPhotoAttached() ? 
                            <tr>
                                <td className='PhotoAddNameTD'><span><b>Datei: </b>{this.fileName}</span></td>
                                <td className='PhotoAddIconTD'>
                                    {/* <img className='PhotoAddIcon' src={cross} alt='+' onClick={this.removePhoto} /> */}
                                    <div className='icomoon_general icomoon_trash' onClick={this.removePhoto}></div>
                                </td>
                            </tr>
                            :
                            <tr>
                                <td className='PhotoAddNameTD' onClick={ () => this.InputFile.click() } ><span>{this.name}</span></td>
                                <td className='PhotoAddIconTD'>
                                    <input type="file" name="filetoupload" id={this.photoInputID} className="inputfile" onChange={this.onPhotoChange} ref={(el) => this.InputFile = el} accept={ this.acceptFileTypes || ".png,.jpeg,.jpg,.gif,.bmp,.tiff"}/>
                                    <label htmlFor={this.photoInputID}>
                                        <img className='PhotoAddIcon' src={digicam} alt='+' />
                                    </label>
                                </td>
                            </tr>
                        }
                            <tr style={display_css} className='aidentImageDisplay' >
                                <td>
                                    <img src={this.file}  id={this.photoPreviewID} className='aidentImagePreview' alt='prev' style={zoom_css} onClick={ () => this.toggleZoom() }/>
                                </td>
                            </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}

export default PhotoAdd


