import React, { Component } from 'react'
import sendIcon from './svg/message-icon.svg'
import email_n from './svg/email_normal.svg'
import email_u from './svg/email_urgent.svg'
import phone from './svg/phone_call.svg'

class Submit extends Component {
    constructor(props, context) {
        super(props, context)

        this.color = ''
        this.icon = sendIcon

        let { id } = this.props
        if ( id )
        {
            let def = id.includes( global.CONTACT_DEFAULT )
            let urg = id.includes( global.CONTACT_URGENT )
            let dir = id.includes( global.CONTACT_DIRECT )

            if( def ) this.color = 'bluebtn' 
            if( urg ) this.color = 'redbtn' 
            if( dir ) this.color = 'greenbtn'

            if( def ) this.icon = email_n
            if( urg ) this.icon = email_u 
            if( dir ) this.icon = phone
        }
    }


    componentDidMount() {

        // let elm = document.getElementsByClassName('submit_btn_container')[0]
        // let fsize = document.defaultView.getComputedStyle( elm, null).getPropertyValue('height')
 
        let self = this
        
        try {
            // self.refs.thisElement.dataset.display = self.props.collapse ? 'hide' : 'show'
            self.refs.thisElement.dataset.required = self.props.e.required || false
        } catch (error) {
            let trySetData = setInterval(function(){

                if(self && self.refs && self.refs.thisElement ){
                    // self.refs.thisElement.dataset.display = self.props.collapse ? 'hide' : 'show'
                    self.refs.thisElement.dataset.required = self.props.e.required || false
                    clearInterval(trySetData)
                }
            }, 250)
        }
    }

    render() {
        let { section, active, submit, hid, id, name, e, app } = this.props
        let func = active ? submit : null
        let {WDF, DHC} = app.type
        
        let submit_class = WDF ? 'submit_wdf' : 'submit'
        let button_class = WDF ? 'button_wdf' : 'button'
        // let classname = 'button ' + submit_class + ' ' + this.color + ' ' + section + (active ? '' : ' off')
        let classname = [button_class, submit_class, this.color, section, active ? '' : 'off'].join(' ').trim()

        let bgc = 'linear-gradient(to right, #02aafe 0%, #177cfa 100%)'
        let bgc_adok = 'var(--ad_color_gradient)' // 'linear-gradient(to left, rgb(171 63 184) 0%, rgb(170 15 192) 100%)'
        let css = hid.match(/^aident$/) ? { backgroundColor: bgc} : {}
        css = hid.match(/^adok$/) ? { background: bgc_adok} : css
        css = hid.match(/^oma|oma$/) ? { background: 'var(--oma_color_gradient)'} : css

        let is_dhc = DHC || window.location.pathname.split('/').includes('dhc')
        if( is_dhc )
        {
            let bc = app.getRenderDataElement('header', 'button_color')
            css.background = bc || '#e00083' 
        }
        
        let e_type_text = e.type === 'text'
        if( e.type === 'text' ){
            css.textAlign = 'left'
        }

        css = e.type === 'quickmessage' || WDF ? { /* backgroundColor: bgc, marginLeft: '0', marginRight: '0', width: '100%', textAlign: 'center' */} : css
        let icon = <img src={this.icon} className='submitIcon' alt='send'/>


        return (
            <div className='submit_btn_container'>
                <div className={classname}
                    id={id || 'submit'}
                    ref='thisElement'
                    onClick={func}
                    style={css}>
                    { !e_type_text ? '' : icon }
                    <span className='submit_span' >
                        {name}
                    </span>
                    { e_type_text ? '' : icon }
                </div>
            </div>
            
        )
    }
}

export default Submit


